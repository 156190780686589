import { v4 as uuidv4 } from "uuid";
import { LancamentoAttributes } from "../../../../../../../../libs/types/models/sufl";
import moment from "../../../../../../utils/moment";
import numeral from "../../../../../../utils/numeral";
import { ErrorsLancs } from "./errors";

const checkNfeAlert = (
  lancamento: LancamentoAttributes,
  newErrors: ErrorsLancs[],
  pisID: number,
  inssID: number,
  issID: number,
  irID: number
) => {
  if (!lancamento.dt_vencimentoprimeiraparcela) {
    newErrors.push({
      key: uuidv4().toString(),
      title: "Data de Vencimento Ausente",
      message: (
        <div>
          <p>
            Para a realização do pagamento é necessário informar a data de
            vencimento.
          </p>
        </div>
      ),
      session: "notafiscal",
      variant: "danger",
    });
  } else if (
    moment(lancamento.dt_vencimentoprimeiraparcela, "YYYY-MM-DD").isSame(
      moment(),
      "dates"
    )
  ) {
    newErrors.push({
      key: uuidv4().toString(),
      title: "Data de Vencimento para HOJE",
      message: (
        <div>
          <p>
            O vencimento deste lançamento está para <strong>HOJE</strong>.
          </p>
        </div>
      ),
      session: "notafiscal",
      variant: "warning",
    });
  } else if (
    moment(lancamento.dt_vencimentoprimeiraparcela, "YYYY-MM-DD").isBefore(
      moment(),
      "dates"
    )
  ) {
    newErrors.push({
      key: uuidv4().toString(),
      title: "Pagamento VENCIDO",
      message: (
        <div>
          <p>
            Esta pagamento consta como <strong>VENCIDO</strong>.
          </p>
        </div>
      ),
      session: "notafiscal",
      variant: "warning",
    });
  }

  if (lancamento.nm_parcelas !== undefined && lancamento.nm_parcelas > 1) {
    newErrors.push({
      key: uuidv4().toString(),
      title: "Parcela(s) Presente(s)",
      message: (
        <div>
          <p>Este lançamento informa possuir parcelas. Por favor, verifique.</p>
        </div>
      ),
      session: "notafiscal",
      variant: "warning",
    });
  }

  return newErrors;
};

const checkFornecedorAlert = (
  lancamento: LancamentoAttributes,
  newErrors: ErrorsLancs[],
  pisID: number,
  inssID: number,
  issID: number,
  irID: number
) => {
  //FORMA NULL
  if (
    lancamento.id_forma_pag === null ||
    lancamento.id_forma_pag === undefined
  ) {
    newErrors.push({
      key: uuidv4().toString(),
      title: "Forma de Pagamento Ausente",
      message: (
        <div>
          <p>
            Para a realização de um lançamento, é necesário informar qual a
            forma de pagamento do mesmo.
          </p>
        </div>
      ),
      session: "fornecedor",
      variant: "danger",
    });
  } else {
    if (["12"].includes(lancamento.id_forma_pag.toFixed())) {
      if (
        !lancamento.LancamentoFavorecidoPixes ||
        lancamento.LancamentoFavorecidoPixes.length === 0
      ) {
        newErrors.push({
          key: uuidv4().toString(),
          title: "Dados do Pix Ausentes",
          message: (
            <div>
              <p>
                A forma de pagamento está informada como <strong>PIX</strong> e
                os dados desta modalidade não foram informados.
              </p>
              <p>
                Nesta situação, o sistema da Superlógica automaticamente
                utilizará a primeira chave cadastrada, <strong>SE HOVER</strong>
                . Caso contrário, é possível que tenha{" "}
                <strong>ERRO NA REMESSA para o banco</strong>.
              </p>
            </div>
          ),
          session: "fornecedor",
          variant: "warning",
        });
      } else {
        if (!lancamento.LancamentoFavorecidoPixes[0].fl_tipochave_pix) {
          newErrors.push({
            key: uuidv4().toString(),
            title: "Tipo de Chave Pix Ausente",
            message: (
              <div>
                <p>
                  Neste caso é necessário informar qual o{" "}
                  <strong>tipo da chave</strong> pix.
                </p>
              </div>
            ),
            session: "fornecedor",
            variant: "danger",
          });
        }

        if (!lancamento.LancamentoFavorecidoPixes[0].st_chave_pix) {
          newErrors.push({
            key: uuidv4().toString(),
            title: "Chave Pix Ausente",
            message: (
              <div>
                <p>
                  Neste caso é necessário informar qual a <strong>chave</strong>{" "}
                  do pix.
                </p>
              </div>
            ),
            session: "fornecedor",
            variant: "danger",
          });
        }
      }
    }
    //DADOS PAGTO - Dinheiro / Cheque
    else if (["1", "2"].includes(lancamento.id_forma_pag.toFixed())) {
      if (!lancamento.id_favorecido_con || !lancamento.st_nomerecebedor_fav) {
        newErrors.push({
          key: uuidv4().toString(),
          title: "Dados do Favorecido Ausentes",
          message: (
            <div>
              <p>
                A forma de pagamento está informada como{" "}
                <strong>Cheque/Dinheiro</strong> . Neste caso é recomendável que
                os dados do favorecido sejam preenchidos.
              </p>
            </div>
          ),
          session: "fornecedor",
          variant: "warning",
        });
      } else {
        if (!lancamento.id_favorecido_fav) {
          newErrors.push({
            key: uuidv4().toString(),
            title: "Dados de Pagamento Ausente",
            message: (
              <div>
                <p>
                  A forma de pagamento está informada como{" "}
                  <strong>Cheque/Dinheiro</strong> . Neste caso é recomendável
                  que os dados de pagamento do favorecido sejam preenchidos.
                </p>
              </div>
            ),
            session: "fornecedor",
            variant: "warning",
          });
        }
      }
    }
    //DADOS PAGTO - Transf/Doc
    else if (["8", "9"].includes(lancamento.id_forma_pag.toFixed())) {
      if (!lancamento.id_favorecido_con || !lancamento.st_nomerecebedor_fav) {
        newErrors.push({
          key: uuidv4().toString(),
          title: "Dados do Favorecido Ausentes",
          message: (
            <div>
              <p>
                A forma de pagamento está informada como{" "}
                <strong>Transferência Bancária / DOC / TED</strong>.
              </p>
              <p>
                {" "}
                Neste caso é necessário que os{" "}
                <strong>DADOS DO FAVORECIDO</strong> sejam preenchidos.
              </p>
            </div>
          ),
          session: "fornecedor",
          variant: "danger",
        });
      }

      if (!lancamento.id_favorecido_fav) {
        newErrors.push({
          key: uuidv4().toString(),
          title: "Dados de Pagamento Ausente",
          message: (
            <div>
              <p>
                A forma de pagamento está informada como{" "}
                <strong>Transferência Bancária / DOC / TED</strong>.
              </p>
              <p>
                {" "}
                Neste caso é necessário que os{" "}
                <strong>DADOS DE PAGAMENTO</strong> do favorecido sejam
                preenchidos.
              </p>
            </div>
          ),
          session: "fornecedor",
          variant: "danger",
        });
      }
    }
  }

  return newErrors;
};

const checkApropriacoesAlert = (
  lancamento: LancamentoAttributes,
  newErrors: ErrorsLancs[],
  pisID: number,
  inssID: number,
  issID: number,
  irID: number
) => {
  if (
    !lancamento.LancamentoApropriacaos ||
    lancamento.LancamentoApropriacaos.length === 0
  ) {
    newErrors.push({
      key: uuidv4().toString(),
      title: "Apropriação Ausente",
      message: (
        <div>
          <p>
            Para a realização de um lançamento, é necesário que o mesmo esteja
            relacionado a pelo menos uma naturza contábil.
          </p>
        </div>
      ),
      session: "apropriacoes",
      variant: "danger",
    });
  } else {
    var sum = 0;
    lancamento.LancamentoApropriacaos.map((item) => {
      const val = item.vl_valor_pdes ? item.vl_valor_pdes.toString() : "0";
      sum += parseFloat(val);
      return null;
    });

    if (sum != lancamento.vl_fl_nfe) {
      newErrors.push({
        key: uuidv4().toString(),
        title: "Totalização de Apropriações",
        message: (
          <div>
            <p>
              A soma das apropriações <strong>DEVE SER IGUAL</strong> ao valor
              da Nota Fiscal.
            </p>
            <p>
              Nota Fiscal:{" "}
              {numeral(
                lancamento.vl_fl_nfe.toString().replace(".", ",")
              ).format("$ #,##.00")}
            </p>
            <p>
              Apropriações:{" "}
              {numeral(sum.toString().replace(".", ",")).format("$ #,##.00")}
            </p>

            <p>
              Diferença:{" "}
              <strong>
                {numeral(
                  (lancamento.vl_fl_nfe - sum).toString().replace(".", ",")
                ).format("$ #,##.00")}
              </strong>
            </p>
            <p>Por favor, revise os lançamentos.</p>
          </div>
        ),
        session: "apropriacoes",
        variant: "danger",
      });
    }

    lancamento.LancamentoApropriacaos.map((item) => {
      if (!item.st_nomegruposaldo_gs) {
        const val = item.vl_valor_pdes
          ? numeral(item.vl_valor_pdes.toString().replace(".", ",")).format(
              "$ #,##0.00"
            )
          : "0";

        newErrors.push({
          key: uuidv4().toString(),
          title: "Grupo de Saldo Ausente",
          message: (
            <div>
              <p>
                O grupo de saldo da aproprição{" "}
                <strong>
                  {item.st_conta_cont}/{item.st_descricao_cont}
                </strong>{" "}
                no valor de <strong>{val}</strong>, não foi informado.
              </p>
            </div>
          ),
          session: "apropriacoes",
          variant: "danger",
        });
      }
    });
  }

  return newErrors;
};

const checkRetencoesAlert = (
  lancamento: LancamentoAttributes,
  newErrors: ErrorsLancs[],
  pisID: number,
  inssID: number,
  issID: number,
  irID: number
) => {
  if (lancamento.NFe) {
    //PIS
    if (lancamento.NFe.pis_pasep > 0) {
      if (
        !lancamento.LancamentoRetencaos ||
        lancamento.LancamentoRetencaos.length === 0
      ) {
        newErrors.push({
          key: uuidv4().toString(),
          title: "Retenção de PIS/COFINS/CSLL Ausente",
          message: (
            <div>
              <p>
                Nesta NF há informação de recolhimento de{" "}
                <strong>PIS/COFINS/CSLL</strong>. Por favor verique se realmente
                não haverá essa retenção.
              </p>
            </div>
          ),
          session: "retencoes",
          variant: "warning",
        });
      } else {
        const pisRetencao = lancamento.LancamentoRetencaos.find(
          (item) => item.id_rv2_imposto_des === pisID
        );

        if (!pisRetencao) {
          newErrors.push({
            key: uuidv4().toString(),
            title: "Retenção de PIS/COFINS/CSLL Ausente",
            message: (
              <div>
                <p>
                  Nesta NF há informação de recolhimento de{" "}
                  <strong>PIS/COFINS/CSLL</strong>. Por favor verique se
                  realmente não haverá essa retenção.
                </p>
              </div>
            ),
            session: "retencoes",
            variant: "warning",
          });
        } else {
          if (!lancamento.id_natrend_des) {
            newErrors.push({
              key: uuidv4().toString(),
              title: "Natureza de Rendimento",
              message: (
                <div>
                  <p>
                    Quando há recolhimento de <strong>PIS/COFINS/CSLL</strong>,
                    necessariamente precisa ser informado a{" "}
                    <strong>Natureza de Rendimento</strong>.
                  </p>
                </div>
              ),
              session: "retencoes",
              variant: "danger",
            });
          }
        }
      }
    } else {
      if (lancamento.LancamentoRetencaos !== null) {
        if (lancamento.LancamentoRetencaos !== undefined) {
          if (lancamento.LancamentoRetencaos.length > 0) {
            const pisRetencao = lancamento.LancamentoRetencaos.find(
              (item) => item.id_rv2_imposto_des === pisID
            );
            if (pisRetencao) {
              if (!lancamento.id_natrend_des) {
                newErrors.push({
                  key: uuidv4().toString(),
                  title: "Natureza de Rendimento",
                  message: (
                    <div>
                      <p>
                        Quando há recolhimento de{" "}
                        <strong>PIS/COFINS/CSLL</strong>, necessariamente
                        precisa ser informado a{" "}
                        <strong>Natureza de Rendimento</strong>.
                      </p>
                    </div>
                  ),
                  session: "retencoes",
                  variant: "danger",
                });
              }
            }
          }
        }
      }
    }

    //INSS
    if (lancamento.NFe.inss > 0) {
      if (
        !lancamento.LancamentoRetencaos ||
        lancamento.LancamentoRetencaos.length === 0
      ) {
        newErrors.push({
          key: uuidv4().toString(),
          title: "Retenção de INSS Ausente",
          message: (
            <div>
              <p>
                Nesta NF há informação de recolhimento de <strong>INSS</strong>.
                Por favor verique se realmente não haverá essa retenção.
              </p>
            </div>
          ),
          session: "retencoes",
          variant: "warning",
        });
      } else {
        const inssRetencao = lancamento.LancamentoRetencaos.find(
          (item) => item.id_rv2_imposto_des === inssID
        );

        if (!inssRetencao) {
          newErrors.push({
            key: uuidv4().toString(),
            title: "Retenção de INSS Ausente",
            message: (
              <div>
                <p>
                  Nesta NF há informação de recolhimento de{" "}
                  <strong>INSS</strong>. Por favor verique se realmente não
                  haverá essa retenção.
                </p>
              </div>
            ),
            session: "retencoes",
            variant: "warning",
          });
        } else {
          if (!lancamento.id_classificacaotributaria_des) {
            newErrors.push({
              key: uuidv4().toString(),
              title: "Classificação Tributária",
              message: (
                <div>
                  <p>
                    Quando há recolhimento de <strong>INSS</strong>,
                    necessariamente precisa ser informado a{" "}
                    <strong>Classificação Tributária</strong>.
                  </p>
                </div>
              ),
              session: "retencoes",
              variant: "danger",
            });
          }

          if (!lancamento.id_classservicoprestado_des) {
            newErrors.push({
              key: uuidv4().toString(),
              title: "Classificação do Serviço Prestado",
              message: (
                <div>
                  <p>
                    Quando há recolhimento de <strong>INSS</strong>,
                    necessariamente precisa ser informado a{" "}
                    <strong>Classificação do Serviço Prestado</strong>.
                  </p>
                </div>
              ),
              session: "retencoes",
              variant: "danger",
            });
          }
        }
      }

      newErrors.push({
        key: uuidv4().toString(),
        title: "INSS Presente",
        message: (
          <div>
            <p>
              Nesta NF há informação de recolhimento de <strong>INSS</strong>.
              Por favor verique se não há <strong>abatimentos</strong>.
            </p>
          </div>
        ),
        session: "retencoes",
        variant: "warning",
      });
    } else {
      if (lancamento.LancamentoRetencaos !== null) {
        if (lancamento.LancamentoRetencaos !== undefined) {
          if (lancamento.LancamentoRetencaos.length > 0) {
            const inssRetencao = lancamento.LancamentoRetencaos.find(
              (item) => item.id_rv2_imposto_des === inssID
            );
            if (inssRetencao) {
              if (!lancamento.id_classificacaotributaria_des) {
                newErrors.push({
                  key: uuidv4().toString(),
                  title: "Classificação Tributária",
                  message: (
                    <div>
                      <p>
                        Quando há recolhimento de <strong>INSS</strong>,
                        necessariamente precisa ser informado a{" "}
                        <strong>Classificação Tributária</strong>.
                      </p>
                    </div>
                  ),
                  session: "retencoes",
                  variant: "danger",
                });
              }

              if (!lancamento.id_classservicoprestado_des) {
                newErrors.push({
                  key: uuidv4().toString(),
                  title: "Classificação do Serviço Prestado",
                  message: (
                    <div>
                      <p>
                        Quando há recolhimento de <strong>INSS</strong>,
                        necessariamente precisa ser informado a{" "}
                        <strong>Classificação do Serviço Prestado</strong>.
                      </p>
                    </div>
                  ),
                  session: "retencoes",
                  variant: "danger",
                });
              }
            }
          }
        }
      }
    }

    //ISS
    if (lancamento.NFe.iss_retido === true) {
      if (
        !lancamento.LancamentoRetencaos ||
        lancamento.LancamentoRetencaos.length === 0
      ) {
        newErrors.push({
          key: uuidv4().toString(),
          title: "Retenção de ISS Ausente",
          message: (
            <div>
              <p>
                Nesta NF há informação de recolhimento de <strong>ISS</strong>.
                Por favor verique se realmente não haverá essa retenção.
              </p>
            </div>
          ),
          session: "retencoes",
          variant: "warning",
        });
      } else {
        const issRetencao = lancamento.LancamentoRetencaos.find(
          (item) => item.id_rv2_imposto_des === issID
        );

        if (!issRetencao) {
          newErrors.push({
            key: uuidv4().toString(),
            title: "Retenção de ISS Ausente",
            message: (
              <div>
                <p>
                  Nesta NF há informação de recolhimento de <strong>ISS</strong>
                  . Por favor verique se realmente não haverá essa retenção.
                </p>
              </div>
            ),
            session: "retencoes",
            variant: "warning",
          });
        }
      }
    }

    //IR
    if (lancamento.NFe.ir > 0) {
      if (
        !lancamento.LancamentoRetencaos ||
        lancamento.LancamentoRetencaos.length === 0
      ) {
        newErrors.push({
          key: uuidv4().toString(),
          title: "Retenção de IR Ausente",
          message: (
            <div>
              <p>
                Nesta NF há informação de recolhimento de <strong>IR</strong>.
                Por favor verique se realmente não haverá essa retenção.
              </p>
            </div>
          ),
          session: "retencoes",
          variant: "warning",
        });
      } else {
        const irRetencao = lancamento.LancamentoRetencaos.find(
          (item) => item.id_rv2_imposto_des === irID
        );

        if (!irRetencao) {
          newErrors.push({
            key: uuidv4().toString(),
            title: "Retenção de IR Ausente",
            message: (
              <div>
                <p>
                  Nesta NF há informação de recolhimento de <strong>IR</strong>.
                  Por favor verique se realmente não haverá essa retenção.
                </p>
              </div>
            ),
            session: "retencoes",
            variant: "warning",
          });
        }
      }
    }
  }

  return newErrors;
};

const checkLancamentoAlert = (
  lancamento: LancamentoAttributes,
  pisID: number,
  inssID: number,
  issID: number,
  irID: number
) => {
  var newErrors: ErrorsLancs[] = [];

  newErrors = checkNfeAlert(lancamento, newErrors, pisID, inssID, issID, irID);
  newErrors = checkFornecedorAlert(
    lancamento,
    newErrors,
    pisID,
    inssID,
    issID,
    irID
  );
  newErrors = checkApropriacoesAlert(
    lancamento,
    newErrors,
    pisID,
    inssID,
    issID,
    irID
  );
  newErrors = checkRetencoesAlert(
    lancamento,
    newErrors,
    pisID,
    inssID,
    issID,
    irID
  );

  return newErrors;
};

export default checkLancamentoAlert;
