import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { IAlertMessage } from "../../../../../../libs/types/messages";
import { ImpostosAttributes } from "../../../../../../libs/types/models/sulo";
import { IReturnHTTP } from "../../../../../../libs/types/returns";
import FGTSPixLancs from "../../../../components/lancamentos/fgts";
import { useAlertContext } from "../../../../context/alerts";
import useConfirm from "../../../../hooks/useConfirm";
import LancamentoAPI from "../../../../services/api/lancamentos";
import SuperlogicaAPI from "../../../../services/api/sulo";
import moment from "../../../../utils/moment";
import numeral from "../../../../utils/numeral";
import DefaultContentPrivate from "../../defaultContent";

export interface IFGTSArquivos {
  imposto?: ImpostosAttributes;
  referencia: string;
  files?: FileList;
}

const FGTSLancamentosPage = () => {
  const { addAlert, setShowAlert } = useAlertContext();

  const [errors, setErrors] = useState<IAlertMessage[]>([]);
  const [loading, setLoading] = useState(false);

  const [impostos, setImpostos] = useState<ImpostosAttributes[]>();
  const [data, setData] = useState<IFGTSArquivos>({
    imposto: undefined,
    files: undefined,
    referencia: moment().add(-1, "M").format("MM/YYYY"),
  });

  useEffect(() => {
    const loadImpostos = async () => {
      if (!impostos) {
        const result = await new SuperlogicaAPI().listImpostos();
        if (result.success) setImpostos(result.data);
      }
    };

    loadImpostos();
  }, []);

  const sendFile = async () => {
    //if (selectedFiles) await new LancamentoAPI().createFGTS(selectedFiles);

    if (data) {
      try {
        setLoading(true);

        const result: any = await new LancamentoAPI().createFGTSPix(data);

        console.log("result", result);

        if (Array.isArray(result)) {
          result.map((item: IReturnHTTP) => {
            if (!item.success) {
              addAlert({
                key: uuidv4().toString(),
                header: `Erro de Despesa (${item.data.nome_condominio})`,
                date: new Date(),
                message: item.message,
                variant: "danger",
              });
            } else {
              addAlert({
                key: uuidv4().toString(),
                header: `Despesa de FGTS Criada (ID ${item.data.id_despesa_des})`,
                date: new Date(),
                message: `Despesa criada na Superlógica para o ${
                  item.data.nome_condominio
                }, no valor de ${numeral(
                  item.data.valor.toString().replace(".", ",")
                ).format("$ #,##0.00")}, vencimento em ${
                  item.data.vencimento
                }. Chave PIX: ${item.data.chavePix}`,
                variant: "success",
              });
            }
            return null;
          });

          setData({
            ...data,
            files: undefined,
          });
        } else {
          addAlert({
            key: uuidv4().toString(),
            header: `Erro`,
            date: new Date(),
            message: result.message,
            variant: result.success ? "success" : "danger",
          });
        }
        setShowAlert(true);
      } finally {
        setLoading(false);
      }
    }
  };

  //console.log(data);

  const {
    handleShow: handleShowFGTS,
    ConfirmModalComponent: ConfirmModalArquivos,
  } = useConfirm({
    title: "Processar arquivos FGTS",
    message: `Você tem certeza que deseja incluir esses lançamentos de FGTS?`,
    onConfirm: sendFile,
  });

  return (
    <DefaultContentPrivate loading={loading} setLoading={setLoading}>
      {(navigate) => {
        return (
          <div>
            <h1>FGTS - Lançamento por Arquivos</h1>

            {impostos && (
              <FGTSPixLancs
                data={data}
                setData={setData}
                impostos={impostos}
                onSubmit={handleShowFGTS}
              />
            )}
            {ConfirmModalArquivos}
          </div>
        );
      }}
    </DefaultContentPrivate>
  );
};

export default FGTSLancamentosPage;
