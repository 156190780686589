import { IAlertMessage, IParamsConfirm } from "../../../libs/types/messages";
import StringFunctions from "../utils/functions/strings";

class GetParamsURL {
  params: string;
  constructor(params: string) {
    this.params = params;
  }

  getParamsMessage(): IAlertMessage | null {
    if (this.params.trim() === "") return null;

    const params = new URLSearchParams(this.params);

    const header = params.get("header");
    const variant = params.get("variant");
    const message = params.get("message");
    const key = new StringFunctions("").generateCode();

    return {
      key,
      header: header ? header : "Erro",
      message: message ? message : "Parâmetro(s) não lolizado(s).",
      variant: variant ? variant : "danger",
      date: new Date(),
    };
  }

  getParamsConfirm(): IParamsConfirm | null {
    if (this.params.trim() === "") return null;

    const params = new URLSearchParams(this.params);

    const name = params.get("name");
    const email = params.get("email");
    const code = params.get("code");

    if (!name || !email || !code) return null;

    return {
      name,
      email,
      code,
    };
  }
}

export default GetParamsURL;
