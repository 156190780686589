import React from "react";

interface IProps {
  index__item: number;
  item: File;
}

const FGTSPixLancsItem: React.FC<IProps> = ({ index__item, item }) => {
  return (
    <tr key={`row__forn__${index__item}`}>
      <td className="align-middle text-center">{index__item + 1}</td>

      <td className="align-middle text-center">{item.name}</td>
    </tr>
  );
};

export default FGTSPixLancsItem;
