import { FC, useEffect, useState } from "react";
import { Alert, Button, Container, Modal } from "react-bootstrap";
import { BiSolidError } from "react-icons/bi";
import {
  LancamentoApropriacaoAttributes,
  LancamentoAttributes,
  LancamentoRetencaoAttributes,
} from "../../../../../../../libs/types/models/sufl";
import {
  ClassificacaoTributariaAttributes,
  GrupoSaldosAttributes,
  ImpostosAttributes,
  NaturezaRendimentoAttributes,
  TributacaoServicoAttributes,
} from "../../../../../../../libs/types/models/sulo";
import SuperlogicaAPI from "../../../../../services/api/sulo";
import moment from "../../../../../utils/moment";
import { ILancamento } from "../main/lancamentos";
import FormFornecedor from "./formFornecedor";
import FormNotaFiscal from "./formNF";
import TableApropriacoes from "./tableApropriacoes";
import TableRetencoes from "./tableRetencoes";
import ModalFormTools from "./tools";
import checkLancamentoAlert from "./validations";
import { ErrorsLancs } from "./validations/errors";

interface IProps {
  showModalEdit: boolean;
  impostos: ImpostosAttributes[];
  natureza: NaturezaRendimentoAttributes[];
  classificacoes: ClassificacaoTributariaAttributes[];
  servico: TributacaoServicoAttributes[];
  data: ILancamento;
  onEditSubmit: (change: boolean, item?: ILancamento) => void;
  inssID: number;
  pisID: number;
  issID: number;
  irID: number;
}

const ModalFormEditLancs: FC<IProps> = ({
  showModalEdit,
  data,
  onEditSubmit,
  impostos,
  natureza,
  classificacoes,
  servico,
  inssID,
  pisID,
  issID,
  irID,
}) => {
  const [lancamento, setLancamento] = useState<LancamentoAttributes>();
  const [errors, setErrors] = useState<ErrorsLancs[]>([]);
  const [grupoSaldos, setGrupoSaldos] = useState<GrupoSaldosAttributes[]>([]);

  useEffect(() => {
    if (data.lancamento) {
      validations();
      setLancamento(data.lancamento);
      loadPlanoSaldos();
    }
  }, [data]);

  useEffect(() => {
    if (lancamento) validations();
  }, [lancamento]);

  const loadPlanoSaldos = async () => {
    const result = await new SuperlogicaAPI().listGrupoSaldos(
      data.lancamento.id_condominio_cond.toString()
    );
    if (result.success) setGrupoSaldos(result.data);
  };

  const handleSubmit = (change: boolean) => {
    const item: ILancamento | undefined = lancamento
      ? {
          checked: data.checked,
          lancamento,
          errors: data.errors,
        }
      : undefined;
    onEditSubmit(change, item);
  };

  const updateNfe = (item: LancamentoAttributes) => {
    if (lancamento) {
      const newLancamento = item;
      newLancamento.dt_vencimentoprimeiraparcela =
        item.dt_vencimentoprimeiraparcela &&
        moment(item.dt_vencimentoprimeiraparcela, "YYYY-MM-DD").isValid()
          ? moment(item.dt_vencimentoprimeiraparcela, "YYYY-MM-DD").toDate()
          : undefined;

      setLancamento(item);
      validations();
    }
  };

  const updateApropriacoes = (
    apropriacoes: LancamentoApropriacaoAttributes[]
  ) => {
    if (lancamento) {
      const updatedLancamentos: LancamentoAttributes = {
        ...lancamento,
        LancamentoApropriacaos: apropriacoes,
      };
      setLancamento(updatedLancamentos);
      validations();
    }
  };

  const updateLancamentoRetencao = (item: LancamentoAttributes) => {
    if (lancamento) {
      setLancamento(item);
      validations();
    }
  };

  const updateRetencoes = (retencoes: LancamentoRetencaoAttributes[]) => {
    if (lancamento) {
      var updatedLancamentos: LancamentoAttributes = {
        ...lancamento,
        LancamentoRetencaos: retencoes,
      };

      if (!checkClassificacao(updatedLancamentos)) {
        updatedLancamentos.id_classificacaotributaria_des = undefined;
        updatedLancamentos.id_classservicoprestado_des = undefined;
      }

      if (!checkNatureza(updatedLancamentos)) {
        updatedLancamentos.id_natrend_des = undefined;
      }

      setLancamento(updatedLancamentos);
      validations();
    }
  };

  const checkClassificacao = (lancamento: LancamentoAttributes): boolean => {
    if (
      lancamento.LancamentoRetencaos &&
      lancamento.LancamentoRetencaos.length > 0
    ) {
      const inss = lancamento.LancamentoRetencaos.find(
        (item) => item.id_rv2_imposto_des === inssID
      );

      if (inss) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  };

  const checkNatureza = (lancamento: LancamentoAttributes): boolean => {
    if (
      lancamento.LancamentoRetencaos &&
      lancamento.LancamentoRetencaos.length > 0
    ) {
      const pis = lancamento.LancamentoRetencaos.find(
        (item) => item.id_rv2_imposto_des === pisID
      );

      if (pis) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  };

  const validations = () => {
    if (lancamento) {
      const newErrors = checkLancamentoAlert(
        lancamento,
        pisID,
        inssID,
        issID,
        irID
      );
      setErrors(newErrors);
    }
  };

  return (
    <>
      {lancamento && (
        <Modal
          // {...props}
          show={showModalEdit}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          //onHide={() => handleSubmit(false)}
        >
          <Modal.Header
          //closeButton
          //closeLabel="Fechar"
          >
            <Modal.Title id="contained-modal-title-vcenter" as={"h3"}>
              Alteração de Lançamento{` - ID [${lancamento.id_fl_lancamento}]`}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {errors.length > 0 && (
              <Alert
                className="despesas__table__header__fixed"
                variant={
                  errors.filter((item) => item.variant === "danger").length >= 1
                    ? "danger"
                    : "warning"
                }
              >
                <div className="d-flex justify-content-between align-items-center">
                  <BiSolidError size={25} />
                  <div className="mx-2 text-center">
                    Este lançamento contém
                    <strong className="mx-1">
                      {errors.filter((item) => item.variant === "danger")
                        .length === 0
                        ? "nenhum erro"
                        : `${
                            errors.filter((item) => item.variant === "danger")
                              .length
                          } erro${
                            errors.filter((item) => item.variant === "danger")
                              .length > 1
                              ? "s"
                              : ""
                          }`}
                      {" e "}
                      {errors.filter((item) => item.variant === "warning")
                        .length === 0
                        ? "nenhum alerta"
                        : `${
                            errors.filter((item) => item.variant === "warning")
                              .length
                          } alerta${
                            errors.filter((item) => item.variant === "warning")
                              .length > 1
                              ? "s"
                              : ""
                          }`}
                      .
                    </strong>
                    É necessário corrigi-lo(s) para poder salvar - verifique as
                    sessões.
                  </div>
                  <BiSolidError size={25} />
                </div>
              </Alert>
            )}

            <FormNotaFiscal
              errors={errors}
              setErrors={setErrors}
              lancamento={lancamento}
              updateNfe={updateNfe}
            />

            <FormFornecedor
              errors={errors}
              setErrors={setErrors}
              lancamento={lancamento}
              updateNfe={updateNfe}
            />

            {lancamento.LancamentoApropriacaos && (
              <TableApropriacoes
                errors={errors}
                setErrors={setErrors}
                apropriacoes={lancamento.LancamentoApropriacaos}
                nfValue={lancamento.vl_fl_nfe}
                id_lancamento_fl={lancamento.id_fl_lancamento}
                grupoSaldos={grupoSaldos}
                updateApropriacoes={updateApropriacoes}
              />
            )}

            {lancamento && inssID && pisID && (
              <TableRetencoes
                errors={errors}
                setErrors={setErrors}
                lancamento={lancamento}
                id_lancamento_fl={lancamento.id_fl_lancamento}
                impostos={impostos}
                classificacoes={classificacoes}
                natureza={natureza}
                servico={servico}
                nfValue={lancamento.vl_fl_nfe}
                updateRetencoes={updateRetencoes}
                updateLancamentoRetencao={updateLancamentoRetencao}
                inssID={inssID}
                pisID={pisID}
                inss={lancamento.NFe ? lancamento.NFe.inss.toString() : "0"}
                iss={
                  lancamento.NFe ? lancamento.NFe.iss_devido.toString() : "0"
                }
                pcc={new ModalFormTools().getSumValue([
                  lancamento.NFe?.pis_pasep,
                  lancamento.NFe?.cofins,
                  lancamento.NFe?.csll,
                ])}
                ir={lancamento.NFe ? lancamento.NFe.ir.toString() : "0"}
              />
            )}
          </Modal.Body>

          <Modal.Footer>
            <Container fluid>
              <div className="d-flex justify-content-between">
                <Button variant="danger" onClick={() => handleSubmit(false)}>
                  Fechar
                </Button>

                <Button
                  variant={
                    errors.filter((item) => item.variant === "danger").length >
                    0
                      ? "secondary"
                      : "success"
                  }
                  onClick={() => handleSubmit(true)}
                  disabled={
                    errors.filter((item) => item.variant === "danger").length >
                    0
                  }
                >
                  Salvar
                </Button>
              </div>
            </Container>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ModalFormEditLancs;
