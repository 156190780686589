import React, { FC } from "react";
import { Alert } from "react-bootstrap";
import { tVariants } from "../../../../../../../../../libs/types/messages";

interface IProps {
  errors: ErrorsLancs[];
  setErrors: React.Dispatch<React.SetStateAction<ErrorsLancs[]>>;
  session: tSessions;
}

type tSessions = "apropriacoes" | "retencoes" | "notafiscal" | "fornecedor";

export interface ErrorsLancs {
  key: string;
  title: string;
  message: React.JSX.Element;
  variant: tVariants;
  session: tSessions;
}

const Errors: FC<IProps> = ({ errors, session, setErrors }) => {
  const updateErros = (key: string) => {
    const newErrors: ErrorsLancs[] = [];
    errors.map((item) => {
      if (item.key !== key) newErrors.push(item);
    });
    setErrors(newErrors);
  };

  return (
    <div>
      {errors
        .filter((item) => item.session === session)
        .map((item, index) => {
          return (
            <Alert
              key={`${item.session}__${index}`}
              variant={item.variant}
              dismissible={item.variant !== "danger"}
              onClose={() => updateErros(item.key)}
            >
              <Alert.Heading>{item.title}</Alert.Heading>
              <p>{item.message}</p>
            </Alert>
          );
        })}
    </div>
  );
};

export default Errors;
