import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { GruposCondominiosAttributes } from "../../../../../../libs/types/models/sulo";
import { IReturnHTTP } from "../../../../../../libs/types/returns";
import ReportFinanceiro, {
  IFinanceiroDespesas,
  IReportFinanceiro,
} from "../../../../components/reports/financeiro";
import { useAlertContext } from "../../../../context/alerts";
import useConfirm from "../../../../hooks/useConfirm";
import SuperlogicaAPI from "../../../../services/api/sulo";
import DefaultContentPrivate from "../../defaultContent";

export const REPORT_INIT: IReportFinanceiro = {
  id_condominios: [-1],
  id_fornecedores: [-1],
  id_grupos: [],
  id_formas: [0, 8, 9, 10, 12],
  status: 2,
  filter: 6,
  remessa: 2,
  dateFrom: new Date(),
  dateTo: new Date(),
};

const ReportFinanceiroPage = () => {
  const { addAlert, setShowAlert } = useAlertContext();
  const [loading, setLoading] = useState(false);
  const [dataReport, setDataReport] = useState<IReportFinanceiro>(REPORT_INIT);
  const [dataDespesas, setDataDespesas] = useState<IFinanceiroDespesas[]>();
  const [gruposCondominios, setGruposCondominios] = useState<
    GruposCondominiosAttributes[]
  >([]);

  useEffect(() => {
    loadGrupos();
  }, []);

  const loadGrupos = async () => {
    const result = await new SuperlogicaAPI().listGruposCondominios();
    if (result.success) setGruposCondominios(result.data);
  };

  const onSetLoading = (status: boolean) => {
    setLoading(status);
  };

  const submitRemessas = async () => {
    if (dataDespesas) {
      try {
        setLoading(true);
        const sendRemessas = dataDespesas
          .filter((item) => item.checked)
          .map((item) => {
            return {
              id_parcela_pdes: item.despesa.id_parcela_pdes,
              fl_remessastatus_pdes: 1,
              id_condominio_cond: item.despesa.id_condominio_cond,
              id_despesa_des: item.despesa.id_despesa_des,
            };
          });

        const result: any = await new SuperlogicaAPI().updateRemessas(
          sendRemessas
        );

        console.log("result", result);

        if (Array.isArray(result)) {
          const success_ids: string[] = [];
          result.map((item: IReturnHTTP) => {
            if (!item.success) {
              addAlert({
                key: uuidv4().toString(),
                header: `Erro de Despesa (ID ${item.data.id_despesa_des})`,
                date: new Date(),
                message: item.message,
                variant: "danger",
              });
            } else {
              addAlert({
                key: uuidv4().toString(),
                header: `Despesa Marcada (ID ${item.data.id_despesa_des})`,
                date: new Date(),
                message: `Despesa marcada para remessa na Superlógica com ID_DESPESA_DES: ${item.data.id_despesa_des}`,
                variant: "success",
              });
              success_ids.push(item.data.id_despesa_des.toString());
            }
            return null;
          });

          setDataReport(REPORT_INIT);
          setDataDespesas(undefined);
        } else {
          addAlert({
            key: uuidv4().toString(),
            header: `Erro`,
            date: new Date(),
            message: result.message,
            variant: result.success ? "success" : "danger",
          });
        }
        setShowAlert(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const {
    handleShow: handleShowRemessas,
    ConfirmModalComponent: ConfirmModalRemessas,
  } = useConfirm({
    title: "Marcar Remessas",
    message: `Você tem certeza que deseja marcar despesas selecionadas para remessa?`,
    onConfirm: submitRemessas,
  });

  return (
    <DefaultContentPrivate loading={loading} setLoading={setLoading}>
      {(navigate) => {
        return (
          <div>
            <h1>Relatório Financeiro</h1>

            <ReportFinanceiro
              gruposCondominios={gruposCondominios}
              dataReport={dataReport}
              setDataReport={setDataReport}
              dataDespesas={dataDespesas}
              setDataDespesas={setDataDespesas}
              onSetLoading={onSetLoading}
              submitRemessas={handleShowRemessas}
            />

            {ConfirmModalRemessas}
          </div>
        );
      }}
    </DefaultContentPrivate>
  );
};

export default ReportFinanceiroPage;
