import axios, { AxiosRequestConfig } from "axios";
import API from "..";
import { IReturnHTTP } from "../../../../../libs/types/returns";

class NfeAPI extends API {
  downloadFile = async (id?: number): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "get",
      url: `${this.base_url}/nfe/download/${id}`,
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
    };

    await axios(config)
      .then((response) => {
        this.result = { success: true, message: "", data: response.data };
        return this.result;
      })
      .catch((error) => {
        this.result = { success: true, message: "", data: undefined };

        try {
          this.result.message = error.response.data;
          return this.result.message;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result.message;
        }
      });

    return this.result;
  };
}

export default NfeAPI;
