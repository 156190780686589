import { cnpj } from "cpf-cnpj-validator";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { IAlertMessage } from "../../../../../../libs/types/messages";
import { LancamentoAttributes } from "../../../../../../libs/types/models/sufl";
import {
  ClassificacaoTributariaAttributes,
  ImpostosAttributes,
  NaturezaRendimentoAttributes,
  TributacaoServicoAttributes,
} from "../../../../../../libs/types/models/sulo";
import { IReturnHTTP } from "../../../../../../libs/types/returns";
import PreLancs from "../../../../components/lancamentos/pre";
import { IFornecedores } from "../../../../components/lancamentos/pre/components/main/fornecedores";
import { ILancamento } from "../../../../components/lancamentos/pre/components/main/lancamentos";
import checkLancamentoResumed from "../../../../components/lancamentos/pre/components/main/validations";
import LancsRealizadosModal from "../../../../components/reportsModals/lancamentosRealizados";
import { useAlertContext } from "../../../../context/alerts";
import useConfirm from "../../../../hooks/useConfirm";
import LancamentoAPI from "../../../../services/api/lancamentos";
import SuperlogicaAPI from "../../../../services/api/sulo";
import SortFunctions from "../../../../utils/functions/sorts";
import DefaultContentPrivate from "../../defaultContent";

const PreLancamentosPage = () => {
  const { addAlert, setShowAlert } = useAlertContext();

  const [errors, setErrors] = useState<IAlertMessage[]>([]);
  const [loading, setLoading] = useState(false);

  const [lancamentos, setLancamentos] = useState<ILancamento[] | null>(null);
  const [fornecedores, setFornecedores] = useState<IFornecedores[] | null>(
    null
  );
  const [impostos, setImpostos] = useState<ImpostosAttributes[] | null>(null);
  const [natureza, setNatureza] = useState<
    NaturezaRendimentoAttributes[] | null
  >(null);
  const [classificacoes, setClassificacoes] = useState<
    ClassificacaoTributariaAttributes[] | null
  >(null);
  const [servico, setServico] = useState<TributacaoServicoAttributes[] | null>(
    null
  );
  const [inssID, setInssID] = useState<number>();
  const [pisID, setPisID] = useState<number>();
  const [issID, setIssID] = useState<number>();
  const [irID, setIrID] = useState<number>();

  useEffect(() => {
    if (!natureza) getNatureza();
    if (!classificacoes) getClassificacoes();
    if (!servico) getServico();
    if (!impostos) getImpostos();
  }, []);

  useEffect(() => {
    if (pisID && inssID) {
      if (!lancamentos) getLancamentos();
    }
  }, [inssID, pisID]);

  useEffect(() => {
    //if (!fornecedores) getFornecedores();
    getFornecedores();
  }, [lancamentos]);

  const getNatureza = async () => {
    const result = await new SuperlogicaAPI().listNaturezaRendimento();
    if (result.success) {
      setNatureza(result.data);
    } else {
      setErrors([
        ...errors,
        {
          key: uuidv4().toString(),
          header: "Erro de Requisição",
          message: result.message,
          variant: "danger",
          date: new Date(),
        },
      ]);
    }
  };

  const getClassificacoes = async () => {
    const result = await new SuperlogicaAPI().listClassificacoesTributarias();
    if (result.success) {
      setClassificacoes(result.data);
    } else {
      setErrors([
        ...errors,
        {
          key: uuidv4().toString(),
          header: "Erro de Requisição",
          message: result.message,
          variant: "danger",
          date: new Date(),
        },
      ]);
    }
  };

  const getServico = async () => {
    const result = await new SuperlogicaAPI().listTributacaoServico();
    if (result.success) {
      setServico(result.data);
    } else {
      setErrors([
        ...errors,
        {
          key: uuidv4().toString(),
          header: "Erro de Requisição",
          message: result.message,
          variant: "danger",
          date: new Date(),
        },
      ]);
    }
  };

  const getImpostos = async () => {
    const result = await new SuperlogicaAPI().listImpostos();
    if (result.success) {
      setImpostos(result.data);
      const listImpostos: ImpostosAttributes[] = result.data;

      if (!inssID) {
        const INSS = listImpostos.find(
          (item) => item.st_rv2_nome_imp.trim() === "INSS - FORNECEDOR"
        );

        setInssID(INSS !== undefined ? INSS.id_rv2_cod_imp : -1);
      }

      if (!pisID) {
        const PCC = listImpostos.find(
          (item) => item.st_rv2_nome_imp.trim() === "PIS/COFINS/CSLL"
        );

        setPisID(PCC !== undefined ? PCC.id_rv2_cod_imp : -1);
      }

      if (!issID) {
        const ISS = listImpostos.find(
          (item) => item.st_rv2_nome_imp.trim() === "ISS"
        );

        setIssID(ISS !== undefined ? ISS.id_rv2_cod_imp : -1);
      }

      if (!irID) {
        const IR = listImpostos.find(
          (item) => item.st_rv2_nome_imp.trim() === "IR"
        );

        setIrID(IR !== undefined ? IR.id_rv2_cod_imp : -1);
      }
    } else {
      setErrors([
        ...errors,
        {
          key: uuidv4().toString(),
          header: "Erro de Requisição",
          message: result.message,
          variant: "danger",
          date: new Date(),
        },
      ]);
    }
  };

  const getLancamentos = async () => {
    if (pisID && inssID && issID && irID) {
      const result = await new LancamentoAPI().list();
      if (result.success) {
        const lancamentos: LancamentoAttributes[] = result.data;
        const newLancamentos: ILancamento[] = [];

        lancamentos.map((item) => {
          // const errorLanc = new VerificationLancamento(
          //   item,
          //   [],
          //   pisID,
          //   inssID,
          //   issID,
          //   irID
          // ).validacao();

          const errorLanc = checkLancamentoResumed(
            item,
            pisID,
            inssID,
            issID,
            irID
          );

          const newItem: LancamentoAttributes = { ...item };
          if (item.id_forma_pag === 0 && !item.id_favorecido_con) {
            newItem.id_favorecido_con = item.id_contato_con;
          }
          if (item.id_forma_pag === 0 && !item.st_nomerecebedor_fav) {
            newItem.st_nomerecebedor_fav = item.st_nome_con;
          }

          var valueParcela: any = undefined;

          if (item.nm_parcelas && item.nm_parcelas > 1) {
            valueParcela = item.vl_valor_pdes
              ? item.vl_valor_pdes
              : item.NFe && item.NFe.nfe
              ? item.NFe.nfe / item.nm_parcelas
              : 0;
          }

          newItem.vl_valor_pdes = valueParcela;

          newLancamentos.push({
            checked: false,
            // item.id_contato_con === null ||
            // errorLanc.filter((item) => item.type === "danger").length > 0
            //   ? false
            //   : true,
            errors: errorLanc,
            lancamento: { ...newItem },
            //lancamento: item,
          });
        });

        setLancamentos(newLancamentos);
      } else {
        setErrors([
          ...errors,
          {
            key: uuidv4().toString(),
            header: "Erro de Requisição",
            message: result.message,
            variant: "danger",
            date: new Date(),
          },
        ]);
      }
    }
  };

  const getFornecedores = () => {
    if (lancamentos) {
      var noneFornecedores = lancamentos.filter(
        (item) => item.lancamento.id_contato_con === null
      );

      var fornecedores: IFornecedores[] = [];

      noneFornecedores.map((item) => {
        if (item.lancamento.Condominio) {
          if (item.lancamento.NFe) {
            const index = fornecedores.findIndex(
              (f) =>
                f.nfe.documento_prestador ===
                item.lancamento.NFe?.documento_prestador
            );

            if (index >= 0) {
              fornecedores[index] = {
                checked: false,
                //checked: true,
                id_fl_lancamento: item.lancamento.id_fl_lancamento,
                nfe: fornecedores[index].nfe,
                conds: [
                  ...fornecedores[index].conds,
                  {
                    nome: item.lancamento.Condominio?.st_nome_cond,
                    nota: item.lancamento.st_documento_des,
                    valor: item.lancamento.vl_fl_nfe,
                    id: item.lancamento.id_condominio_cond,
                  },
                ],
              };
            } else {
              fornecedores.push({
                checked: false,
                //checked: true,
                id_fl_lancamento: item.lancamento.id_fl_lancamento,
                nfe: item.lancamento.NFe,
                conds: [
                  {
                    nome: item.lancamento.Condominio.st_nome_cond,
                    nota: item.lancamento.st_documento_des,
                    valor: item.lancamento.vl_fl_nfe,
                    id: item.lancamento.id_condominio_cond,
                  },
                ],
              });
            }
          }
        }
        return null;
      });

      fornecedores = fornecedores.sort((a, b) =>
        new SortFunctions(
          a.nfe.razao_prestador,
          b.nfe.razao_prestador
        ).sortStrAsc()
      );

      setFornecedores(fornecedores);
    }
  };

  const updateLancamentos = (items: ILancamento[]) => {
    setLancamentos(items);
  };

  const restoreItem = (newLancamento: LancamentoAttributes) => {
    //TODO rever erro
    if (lancamentos) {
      if (pisID && inssID && issID && irID) {
        const index = lancamentos.findIndex(
          (item) =>
            item.lancamento.id_fl_lancamento === newLancamento.id_fl_lancamento
        );

        const errorLanc = checkLancamentoResumed(
          newLancamento,
          pisID,
          inssID,
          issID,
          irID
        );

        if (index > -1) {
          var newLancamentos: ILancamento[] = [...lancamentos];
          newLancamentos[index].lancamento = { ...newLancamento };
          setLancamentos(newLancamentos);
        }
      }
    }
  };

  const loadBaseData = async () => {
    getLancamentos();
  };

  const submitFornecedores = async () => {
    if (fornecedores) {
      try {
        setLoading(true);

        const cnpjFornecedores = fornecedores
          .filter((item) => item.checked)
          .map((item) => {
            return {
              cnpj: item.nfe.documento_prestador,
              id_condominio: item.conds[0].id,
              inscricao_municipal: item.nfe.inscricao_municipal_prestador,
            };
          });

        console.log("submitFornecedores", cnpjFornecedores);

        const result = await new LancamentoAPI().createFornecedores(
          cnpjFornecedores
        );

        console.log("result Fornecedores", result);

        // TODO > para esses Alertas, talvez seja ideal trabalhar com o ID LANÇAMENTO/FORNECEDOR, pois assim, se houver atualização, usa o mesmo CARD ao invés de ficar cirando vários.

        if (Array.isArray(result)) {
          result.map((item: IReturnHTTP) => {
            if (!item.success) {
              addAlert({
                key: uuidv4().toString(),
                header: `Erro de Fornecedor (CNPJ ${cnpj.format(
                  item.data.st_cpf_con
                )})`,
                date: new Date(),
                message: item.message,
                variant: "danger",
              });
            } else {
              if (item.data.id_contato_con) {
                addAlert({
                  key: uuidv4().toString(),
                  header: `Fornecedor Criado (CNPJ ${cnpj.format(
                    item.data.st_cpf_con
                  )})`,
                  date: new Date(),
                  message: `Fornecedor ${item.data.st_nome_con} criado no Superlógica com ID_CONTATO_CON: ${item.data.id_contato_con} e atualizado neste sistema.`,
                  variant: "success",
                });
              }
            }
            return null;
          });
        } else {
          addAlert({
            key: uuidv4().toString(),
            header: `Erro`,
            date: new Date(),
            message: result.message,
            variant: result.success ? "success" : "danger",
          });
        }

        setShowAlert(true);
        loadBaseData();
      } finally {
        setLoading(false);
      }
    }
  };

  const submitLancamentos = async () => {
    if (lancamentos) {
      try {
        setLoading(true);
        const sendLancamentos = lancamentos
          .filter((item) => item.checked)
          .map((item) => {
            const {
              Fornecedor,
              NFe,
              Condominio,
              alertas,
              st_status_obs,
              ...rest
            } = item.lancamento;

            //return item.lancamento;
            return {
              ...rest,
              arquivo_pdf: NFe?.arquivo_pdf,
              nfe: NFe?.nfe,
              valor_nfe: NFe?.valor_nfe,
              st_label_cond: Condominio?.st_label_cond,
            };
          });

        console.log("submitLancamentos", sendLancamentos);

        const result: any = await new LancamentoAPI().createDespesas(
          sendLancamentos
        );

        console.log("result", result);

        if (Array.isArray(result)) {
          const success_ids: string[] = [];
          result.map((item: IReturnHTTP) => {
            if (!item.success) {
              addAlert({
                key: uuidv4().toString(),
                header: `Erro de Lançamento (ID ${item.data.id_fl_lancamento})`,
                date: new Date(),
                message: item.message,
                variant: "danger",
              });
            } else {
              addAlert({
                key: uuidv4().toString(),
                header: `Lançamento Criado (ID ${item.data.id_fl_lancamento})`,
                date: new Date(),
                message: `Despesa criada na Superlógica com ID_DESPESA_DES: ${item.data.id_despesa_des}`,
                variant: "success",
              });
              success_ids.push(item.data.id_fl_lancamento.toString());
            }
            return null;
          });

          const newLancamentos: ILancamento[] = [];
          lancamentos.map((item) => {
            if (
              !success_ids.includes(item.lancamento.id_fl_lancamento.toString())
            ) {
              newLancamentos.push(item);
            }
            return null;
          });

          setLancamentos(newLancamentos);
        } else {
          addAlert({
            key: uuidv4().toString(),
            header: `Erro`,
            date: new Date(),
            message: result.message,
            variant: result.success ? "success" : "danger",
          });
        }
        setShowAlert(true);

        //loadBaseData();
      } finally {
        setLoading(false);
      }
    }
  };

  const onSortLancamento = (lancamentos: ILancamento[]) => {
    setLancamentos(lancamentos);
  };

  const onSetLoading = (status: boolean) => {
    setLoading(status);
  };

  const {
    handleShow: handleShowLancamentos,
    ConfirmModalComponent: ConfirmModalLancamentos,
  } = useConfirm({
    title: "Processar Lançamentos",
    message: `Você tem certeza que deseja incluir esses lançamentos?`,
    onConfirm: submitLancamentos,
  });

  const {
    handleShow: handleShowFornecedores,
    ConfirmModalComponent: ConfirmModalFornecedores,
  } = useConfirm({
    title: "Processar Fornecedores",
    message: `Você tem certeza que deseja incluir esses fornecedores?`,
    onConfirm: submitFornecedores,
  });

  return (
    <DefaultContentPrivate loading={loading} setLoading={setLoading}>
      {(navigate) => {
        return (
          <div>
            {fornecedores &&
              impostos &&
              lancamentos &&
              classificacoes &&
              natureza &&
              servico &&
              inssID &&
              pisID &&
              issID &&
              irID && (
                <PreLancs
                  errors={errors}
                  fornecedores={fornecedores}
                  impostos={impostos}
                  classificacoes={classificacoes}
                  natureza={natureza}
                  lancamentos={lancamentos}
                  servico={servico}
                  setErrors={setErrors}
                  setFornecedores={setFornecedores}
                  setLancamentos={updateLancamentos}
                  inssID={inssID}
                  pisID={pisID}
                  issID={issID}
                  irID={irID}
                  restoreItem={restoreItem}
                  submitFornecedores={handleShowFornecedores}
                  submitLancamentos={handleShowLancamentos}
                  onSortLancamento={onSortLancamento}
                  onSetLoading={onSetLoading}
                />
              )}

            {ConfirmModalLancamentos}
            {ConfirmModalFornecedores}
            <LancsRealizadosModal />
          </div>
        );
      }}
    </DefaultContentPrivate>
  );
};

export default PreLancamentosPage;
