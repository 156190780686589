import { FC, useState } from "react";
import {
  MdDelete,
  MdDeleteOutline,
  MdEdit,
  MdOutlineEdit,
} from "react-icons/md";
import { LancamentoApropriacaoAttributes } from "../../../../../../../../../libs/types/models/sufl";
import { GrupoSaldosAttributes } from "../../../../../../../../../libs/types/models/sulo";
import numeral from "../../../../../../../utils/numeral";

interface IProps {
  item: LancamentoApropriacaoAttributes;
  id_lancamento_fl: number;
  nfValue: number;
  index: number;
  onEditApropriacao: (
    item: LancamentoApropriacaoAttributes,
    index: number
  ) => void;
  onDeleteApropriacao: (idnex: number) => void;
  grupo: GrupoSaldosAttributes | undefined;
}

const TableApropriacoesItem: FC<IProps> = ({
  item,
  onEditApropriacao,
  onDeleteApropriacao,
  nfValue,
  index,
  grupo,
}) => {
  const [editApropriacao, setEditApropriacao] = useState(false);
  const [deleteApropriacao, setDeleteApropriacao] = useState(false);

  const value = item.vl_valor_pdes ? item.vl_valor_pdes : 0;
  const formatedValue = numeral(value.toString().replace(".", ",")).format(
    "$ #,##0.00"
  );
  const percent = value > 0 ? value / nfValue : 0;
  const formatedPercent = numeral(percent.toString().replace(".", ",")).format(
    "0.00 %"
  );

  return (
    <tr>
      <td className="align-middle text-center">{index + 1}</td>

      <td className="align-middle text-center">
        <div
          className="button__icon"
          onMouseEnter={() => setEditApropriacao(true)}
          onMouseLeave={() => setEditApropriacao(false)}
        >
          {editApropriacao ? (
            <MdEdit
              size={18}
              title="Editar Apropriação"
              onClick={() => {
                onEditApropriacao(item, index);
              }}
            />
          ) : (
            <MdOutlineEdit size={18} />
          )}
        </div>
      </td>

      <td className="align-middle text-center">
        <div
          className="button__icon"
          onMouseEnter={() => setDeleteApropriacao(true)}
          onMouseLeave={() => setDeleteApropriacao(false)}
        >
          {deleteApropriacao ? (
            <MdDelete
              size={18}
              title="Excluir Apropriação"
              onClick={() => onDeleteApropriacao(index)}
            />
          ) : (
            <MdDeleteOutline size={18} />
          )}
        </div>
      </td>

      <td className="align-middle text-center">{item.st_conta_cont}</td>

      <td className="align-middle text-center">{item.st_descricao_cont}</td>

      <td className="align-middle text-center">{grupo?.label}</td>

      <td className="align-middle text-center">{formatedValue}</td>

      <td className="align-middle text-center">{formatedPercent}</td>
    </tr>
  );
};

export default TableApropriacoesItem;
