import axios, { AxiosRequestConfig } from "axios";
import API from "..";
import { IReturnHTTP } from "../../../../../libs/types/returns";

class UserAPI extends API {
  create = async (data: any): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${this.base_url}/user/create`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  login = async (data: any): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${this.base_url}/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };
}

export default UserAPI;
