import { Button, Container, Modal } from "react-bootstrap";

interface IProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
}

const ConfirmModal: React.FC<IProps> = ({
  show,
  onHide,
  onConfirm,
  title,
  message,
}) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="my-3">{message}</div>
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <div className="d-flex justify-content-between">
            <div>
              <Button variant="danger" onClick={onHide}>
                Cancelar
              </Button>
            </div>
            <div>
              <Button variant="success" onClick={onConfirm}>
                Confirmar
              </Button>
            </div>
          </div>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
