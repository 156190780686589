import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import LoggedMenu from "../components/loggedMenu";
import { useAuth } from "../context/auth";
import PrivatePage from "../pages/private/home";
import BeneficiosLancamentosPage from "../pages/private/lancamentos/beneficiosRb";
import CondomedLancamentosPage from "../pages/private/lancamentos/condomed";
import FGTSLancamentosPage from "../pages/private/lancamentos/fgts";
import PreLancamentos from "../pages/private/lancamentos/pre";
import ReportFinanceiroPage from "../pages/private/reports/financeiro";
import CreateUserPage from "../pages/public/create-user";
import HomePage from "../pages/public/home";
import StorageItems from "../storage";
import { isTokenValid } from "../utils/functions/auth";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route index path="/home" element={<HomePage />} />
          <Route path="/create-user" element={<CreateUserPage />} />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route element={<LoggedMenu />}>
            <Route path="/" element={<PrivatePage />} />
            <Route path="/lancamentos/pre" element={<PreLancamentos />} />
            <Route path="/lancamentos/fgts" element={<FGTSLancamentosPage />} />
            <Route
              path="/lancamentos/beneficios"
              element={<BeneficiosLancamentosPage />}
            />
            <Route
              path="/lancamentos/condomed"
              element={<CondomedLancamentosPage />}
            />
            <Route
              path="/relatorios/financeiro"
              element={<ReportFinanceiroPage />}
            />
            <Route path="/logout" element={<Logout />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const PublicRoutes = () => {
  return CheckAuth() ? <Navigate to="/" /> : <Outlet />;
};

const PrivateRoutes = () => {
  return CheckAuth() ? <Outlet /> : <Navigate to="/home" />;
};

const CheckAuth = () => {
  const { authToken } = useAuth();

  if (authToken) {
    if (isTokenValid(authToken.authToken)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const Logout = () => {
  const { setAuthToken } = useAuth();
  let location = useLocation();

  new StorageItems().clear();
  setAuthToken(null);

  return <Navigate to="/home" state={{ from: location }} replace />;
};

export default Router;
