import { FC, useState } from "react";

import { Accordion, Row } from "react-bootstrap";
import { MdAddCircle, MdAddCircleOutline } from "react-icons/md";
import {
  LancamentoAttributes,
  LancamentoRetencaoAttributes,
} from "../../../../../../../../libs/types/models/sufl";
import {
  ClassificacaoTributariaAttributes,
  ImpostosAttributes,
  NaturezaRendimentoAttributes,
  TributacaoServicoAttributes,
} from "../../../../../../../../libs/types/models/sulo";
import numeral from "../../../../../../utils/numeral";
import Errors, { ErrorsLancs } from "../validations/errors";
import FormLancamentoRetencoes from "./formLancamentoRetencao";
import FormNewRetencoes from "./formNew";
import TableretencoesItem from "./item";

interface IProps {
  lancamento: LancamentoAttributes;
  impostos: ImpostosAttributes[];
  natureza: NaturezaRendimentoAttributes[];
  classificacoes: ClassificacaoTributariaAttributes[];
  servico: TributacaoServicoAttributes[];
  id_lancamento_fl: number;
  nfValue: number;
  updateRetencoes: (item: LancamentoRetencaoAttributes[]) => void;
  updateLancamentoRetencao: (item: LancamentoAttributes) => void;
  errors: ErrorsLancs[];
  setErrors: React.Dispatch<React.SetStateAction<ErrorsLancs[]>>;
  inssID: number;
  pisID: number;
  pcc: string;
  inss: string;
  iss: string;
  ir: string;
}

const TableRetencoes: FC<IProps> = ({
  lancamento,
  impostos,
  natureza,
  classificacoes,
  servico,
  nfValue,
  id_lancamento_fl,
  updateRetencoes,
  updateLancamentoRetencao,
  errors,
  setErrors,
  inssID,
  pisID,
  pcc,
  inss,
  iss,
  ir,
}) => {
  const [addRetencao, setAddRetencao] = useState(false);

  const [showForm, setShowForm] = useState<{
    show: boolean;
    edit: boolean;
    vl_rv2_subempreitada_des: string;
    vl_rv2_material_des: string;
    vl_rv2_maodeobra_des: string;
    vl_rv2_valorretido_des: string;
    index?: number;
  }>({
    show: false,
    edit: false,
    vl_rv2_subempreitada_des: "",
    vl_rv2_material_des: "",
    vl_rv2_maodeobra_des: "",
    vl_rv2_valorretido_des: "",
  });

  const [newRetencao, setNewRetencao] = useState<{
    lancamento?: LancamentoRetencaoAttributes;
    imposto?: ImpostosAttributes;
  } | null>();

  const onAddRetencao = async () => {
    setShowForm({
      show: true,
      edit: false,
      vl_rv2_maodeobra_des: "",
      vl_rv2_material_des: "",
      vl_rv2_subempreitada_des: "",
      vl_rv2_valorretido_des: "",
    });
  };

  const onEditRetencao = (
    item: LancamentoRetencaoAttributes,
    index: number
  ) => {
    const vl_rv2_maodeobra_des = item.vl_rv2_maodeobra_des
      ? numeral(item.vl_rv2_maodeobra_des.toString().replace(".", ",")).format(
          "#,##0.00"
        )
      : "";

    const vl_rv2_material_des = item.vl_rv2_material_des
      ? numeral(item.vl_rv2_material_des.toString().replace(".", ",")).format(
          "#,##0.00"
        )
      : "";

    const vl_rv2_subempreitada_des = item.vl_rv2_subempreitada_des
      ? numeral(
          item.vl_rv2_subempreitada_des.toString().replace(".", ",")
        ).format("#,##0.00")
      : "";

    const vl_rv2_valorretido_des = item.vl_rv2_valorretido_des
      ? numeral(
          item.vl_rv2_valorretido_des.toString().replace(".", ",")
        ).format("#,##0.00")
      : "";

    const imposto = impostos.find(
      (i) => i.id_rv2_cod_imp === item.id_rv2_imposto_des
    );

    setNewRetencao({ lancamento: item, imposto });

    setShowForm({
      edit: true,
      show: true,
      vl_rv2_maodeobra_des: vl_rv2_maodeobra_des,
      vl_rv2_material_des: vl_rv2_material_des,
      vl_rv2_subempreitada_des: vl_rv2_subempreitada_des,
      vl_rv2_valorretido_des: vl_rv2_valorretido_des,
      index,
    });
  };

  const onDeleteRetencao = (index: number) => {
    if (lancamento && lancamento.LancamentoRetencaos) {
      const newRetencoes = lancamento.LancamentoRetencaos.filter(
        (_, i) => i !== index
      );
      updateRetencoes(newRetencoes);
      setNewRetencao({ imposto: undefined, lancamento: undefined });
      setShowForm({
        edit: false,
        show: false,
        vl_rv2_maodeobra_des: "",
        vl_rv2_material_des: "",
        vl_rv2_subempreitada_des: "",
        vl_rv2_valorretido_des: "",
        index: undefined,
      });
    }
  };

  const onUpdateNewRetencoes = (
    item:
      | {
          lancamento?: LancamentoRetencaoAttributes | undefined;
          imposto?: ImpostosAttributes;
        }
      | null
      | undefined
  ) => {
    if (item) {
      if (item.lancamento?.vl_rv2_valorretido_des) {
        setShowForm({
          ...showForm,
          vl_rv2_valorretido_des: numeral(
            item.lancamento.vl_rv2_valorretido_des.toString().replace(".", ",")
          ).format("#,##0.00"),
        });
      }
    }
    setNewRetencao(item);
  };

  const submitForm = (item?: LancamentoRetencaoAttributes, index?: number) => {
    if (lancamento && lancamento.LancamentoRetencaos) {
      if (showForm.edit) {
        if (item !== undefined && index !== undefined) {
          var newRetencoes = lancamento.LancamentoRetencaos;
          newRetencoes[index] = item;
          updateRetencoes(newRetencoes);
          setNewRetencao({ imposto: undefined, lancamento: undefined });
          setShowForm({
            edit: false,
            show: false,
            vl_rv2_maodeobra_des: "",
            vl_rv2_material_des: "",
            vl_rv2_subempreitada_des: "",
            vl_rv2_valorretido_des: "",
            index: undefined,
          });
        }
      } else {
        if (newRetencao && newRetencao.imposto && newRetencao.lancamento) {
          const newAprop: LancamentoRetencaoAttributes = {
            ...newRetencao.lancamento,
          };

          updateRetencoes([...lancamento.LancamentoRetencaos, newAprop]);
          setNewRetencao({
            lancamento: undefined,
            imposto: undefined,
          });
          setShowForm({
            edit: false,
            show: false,
            vl_rv2_maodeobra_des: "",
            vl_rv2_material_des: "",
            vl_rv2_subempreitada_des: "",
            vl_rv2_valorretido_des: "",
            index: undefined,
          });
        }
      }
    }
  };

  return (
    <Accordion defaultActiveKey="0" className="my-3">
      <Accordion.Item eventKey="1">
        <Accordion.Header as="h3">
          Retenções (Impostos)
          {errors.filter((item) => item.session === "retencoes").length > 0
            ? ` [${
                errors.filter((item) => item.session === "retencoes").length
              }]`
            : ""}
        </Accordion.Header>
        <Accordion.Body>
          {lancamento && lancamento.LancamentoRetencaos && inssID && pisID && (
            <>
              <Errors
                errors={errors}
                session="retencoes"
                setErrors={setErrors}
              />

              <FormLancamentoRetencoes
                inssID={inssID}
                pisID={pisID}
                lancamento={lancamento}
                classificaoes={classificacoes}
                natureza={natureza}
                servico={servico}
                updateLancamentoRetencao={updateLancamentoRetencao}
              />

              <Row>
                <table className="despesas__table">
                  <thead>
                    <tr>
                      <th className="text-center" colSpan={3}>
                        <div className="d-flex justify-content-around">
                          <div>#</div>
                          <div
                            className="button__icon"
                            onMouseEnter={() => setAddRetencao(true)}
                            onMouseLeave={() => setAddRetencao(false)}
                          >
                            {addRetencao ? (
                              <MdAddCircle
                                size={18}
                                title="Excluir Apropriação"
                                onClick={onAddRetencao}
                              />
                            ) : (
                              <MdAddCircleOutline size={18} />
                            )}
                          </div>
                        </div>
                      </th>
                      <th className="text-center">Imposto</th>
                      <th className="text-center">Alíquota</th>
                      <th className="text-center">Valor</th>
                      <th className="text-center">Data de Vencimento</th>
                      <th className="text-center">Valor Mão de Obra</th>
                      <th className="text-center">Valor Material</th>
                      <th className="text-center">Valor Subempreitada</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lancamento.LancamentoRetencaos.map((item, index) => {
                      return (
                        <TableretencoesItem
                          key={`aprop_${index}`}
                          item={item}
                          id_lancamento_fl={id_lancamento_fl}
                          nfValue={nfValue}
                          onEditRetencao={onEditRetencao}
                          onDeleteRetencao={onDeleteRetencao}
                          index={index}
                          impostos={impostos}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </Row>

              <Row>
                <FormNewRetencoes
                  newRetencao={newRetencao}
                  setNewRetencao={onUpdateNewRetencoes}
                  setShowForm={setShowForm}
                  showForm={showForm}
                  id_lancamento_fl={id_lancamento_fl}
                  submitForm={submitForm}
                  impostos={impostos}
                  data_nfe={lancamento.dt_despesa_des}
                  data_pagamento={lancamento.dt_vencimentoprimeiraparcela}
                  nfValue={nfValue}
                  retencoes={lancamento.LancamentoRetencaos}
                  inss={inss}
                  ir={ir}
                  iss={iss}
                  pcc={pcc}
                />
              </Row>
            </>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default TableRetencoes;
