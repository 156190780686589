import React, { useEffect, useState } from "react";
import { Container, Navbar } from "react-bootstrap";
import {
  Link,
  NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { IAlertMessage } from "../../../../../libs/types/messages";
import logo from "../../../assets/fl.png";
import Loading from "../../../components/loading";
import GetParamsURL from "../../../validations/params";

interface DefaultContentPublicProps {
  title: string;
  children: (
    navigate: NavigateFunction,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrors: React.Dispatch<React.SetStateAction<IAlertMessage[]>>
  ) => React.ReactNode;
}

const DefaultContentPublic: React.FC<DefaultContentPublicProps> = ({
  title,
  children,
}) => {
  const navigate = useNavigate();
  const params = useLocation().search;

  useEffect(() => {
    const alert = new GetParamsURL(params).getParamsMessage();
    if (alert) {
      setErrors([alert]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<IAlertMessage[]>([]);

  return (
    <div>
      <Navbar bg="light">
        <Container>
          <Link to="../home">
            <Navbar.Brand>
              <img
                src={logo}
                height="50"
                className="d-inline-block align-top"
                alt="Company Logo"
              />
            </Navbar.Brand>
          </Link>
          <Navbar.Text>
            <h1>{title}</h1>
          </Navbar.Text>
        </Container>
      </Navbar>

      {/* {errors && (
        <div className="d-flex justify-content-center align-items-center">
          <AlertMessage messages={errors} setMessages={(e) => setErrors(e)} />
        </div>
      )} */}

      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ marginTop: 200 }}
        >
          <Loading />
        </div>
      ) : (
        <Container>{children(navigate, setLoading, setErrors)}</Container>
      )}
    </div>
  );
};

export default DefaultContentPublic;
