import { Accordion, Button } from "react-bootstrap";
import { IFinanceiroDespesas } from "./financeiro";

interface IProps {
  children: React.ReactNode;
  data: IFinanceiroDespesas[];
  checkedAll: () => void;
  uncheckedAll: () => void;
  generateReport: () => void;
}

const ReportBaseResults: React.FC<IProps> = ({
  children,
  data,
  checkedAll,
  uncheckedAll,
  generateReport,
}) => {
  const CheckButtons = () => {
    return (
      <div className="p-2 flex-fill">
        <span
          className={
            data?.length === 0 ? `link__button__disabled` : `link__button`
          }
          onClick={checkedAll}
        >
          Marcar Todos
        </span>{" "}
        |{" "}
        <span
          className={
            data?.length === 0 ? `link__button__disabled` : `link__button`
          }
          onClick={uncheckedAll}
        >
          Desmarcar Todos
        </span>
      </div>
    );
  };

  return (
    <Accordion className="mt-5" defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Resultados [{data.length}]</Accordion.Header>
        <Accordion.Body>
          <CheckButtons />

          {children}

          <CheckButtons />

          <div className="d-flex justify-content-end mt-2">
            <Button onClick={generateReport}>Gerar Relatório</Button>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ReportBaseResults;
