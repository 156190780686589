import React from "react";
import { Form } from "react-bootstrap";
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
} from "react-icons/io";
import { IFinanceiroDespesas } from "..";
import moment from "../../../../utils/moment";
import numeral from "../../../../utils/numeral";

interface IProps {
  index__item: number;
  item: IFinanceiroDespesas;
  checkedChange: (lancamento: IFinanceiroDespesas) => void;
}

const arquivos = [
  { value: 0, label: "Doc. Fiscal", abr: "N" },
  { value: 1, label: "Doc. Pgto", abr: "P" },
  { value: 2, label: "Comprovante", abr: "C" },
  { value: 3, label: "Orçamento", abr: "O" },
];

const ReportFinanceiroItem: React.FC<IProps> = ({
  index__item,
  item,
  checkedChange,
}) => {
  const getForma = () => {
    if (item.payment.status === 1) {
      return (
        <div>
          <div>
            <IoMdCheckmarkCircleOutline size={20} color="green" />
          </div>
          <div>{item.payment.message}</div>
        </div>
      );
    } else if (item.payment.status === 2) {
      return (
        <div>
          <div>
            <IoMdCloseCircleOutline size={20} color="red" />
          </div>
          <div>{item.payment.message}</div>
        </div>
      );
    }
  };

  const getArquivos = () => {
    const urlBase = `https://admin105862.superlogica.net/clients/areadocolaborador/publico/downloadarquivo?id=|IDARQ|&hash=|HASHARQ|`;
    const arquivosElements: JSX.Element[] = [];

    if (item.despesa.arquivos) {
      item.despesa.arquivos.map((arq) => {
        if (arq.id_parcela_pdes) {
          if (arq.id_parcela_pdes.toString().trim() !== "") {
            if (
              arq.id_parcela_pdes.toString().trim() !==
              item.despesa.id_parcela_pdes.toString().trim()
            ) {
              return null;
            }
          }
        }

        const doc = arquivos.find(
          (i) => i.label === arq.st_nomeabreviadoetiqueta_eti
        );

        const link = urlBase
          .replace("|IDARQ|", arq.id_arquivo_arq.toString())
          .replace("|HASHARQ|", arq.st_hash_arq ? arq.st_hash_arq : "");
        arquivosElements.push(
          <a
            key={`${item.despesa.id_despesa_des}__${doc ? doc.abr : "x"}`}
            href={link}
            target="_blank"
            className="link__button"
            rel="noreferrer"
          >
            {doc ? doc.abr : "?"}
          </a>
        );
      });
    }

    if (arquivosElements.length > 0) {
      return (
        <div className="p-2 flex-fill">
          {arquivosElements.map((element, index) => (
            <div>{element}</div>
          ))}
        </div>
      );
    }
  };

  return (
    <tr key={`row__forn__${index__item}`}>
      <td className="align-middle text-center">{index__item + 1}</td>

      <td className="align-middle text-center">
        <Form.Check
          type="checkbox"
          checked={item.checked}
          onChange={() => checkedChange(item)}
        />
      </td>

      <td className="align-middle text-center">
        {item.despesa.id_despesa_des}
      </td>

      <td className="align-middle text-center">
        {item.despesa.id_parcela_pdes}
      </td>

      <td className="align-middle text-center">{getArquivos()}</td>

      <td className="align-middle text-center">
        {item.despesa.fl_liquidado_pdes.toString() === "0" ? "P" : "L"}
      </td>

      <td className="align-middle text-center">{item.remessa}</td>

      <td className="align-middle text-center">{item.payment.method}</td>

      <td className="align-middle text-center">{getForma()}</td>

      <td className="align-middle text-center">
        {moment(item.despesa.dt_vencimento_pdes, "MM/DD/YYYY").format(
          "DD/MM/YYYY"
        )}
      </td>

      <td className="align-middle text-center">
        {item.despesa.st_documento_des}
      </td>

      <td className="align-middle text-center">{item.grupos}</td>

      <td className="align-middle">{item.despesa.st_fantasia_cond}</td>

      <td className="align-middle">{item.despesa.st_fantasia_con}</td>

      <td className="align-middle">{item.despesa.st_nomerecebedor_fav}</td>

      <td className="align-middle text-end">
        {numeral(
          item.despesa.vl_valorbruto_pdes.toString().replace(".", ",")
        ).format("#,##0.00")}
      </td>

      <td className="align-middle text-end">
        {numeral(
          item.despesa.vl_valor_pdes.toString().replace(".", ",")
        ).format("#,##0.00")}
      </td>
    </tr>
  );
};

export default ReportFinanceiroItem;
