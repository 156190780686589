import React from "react";
import { Alert, Row } from "react-bootstrap";
import { IAlertMessage } from "../../../../libs/types/messages";
import SortFunctions from "../../utils/functions/sorts";
import moment from "../../utils/moment";

interface IProps {
  messages: IAlertMessage[];
  remove: (key: string) => void;
  hide: (key: string) => void;
}

export const Notifications: React.FC<IProps> = ({ messages, remove, hide }) => {
  return (
    <div>
      {messages
        .sort((a, b) => new SortFunctions(a, b).sortDateAsc())
        .map((item, index) => (
          <div key={item.key}>
            <Alert
              variant={item.variant}
              onClick={() =>
                item.variant === "danger" ? null : remove(item.key)
              }
              dismissible={item.variant === "danger" ? false : true}
            >
              <Alert.Heading as="h5">
                #{(index + 1).toString().padStart(3, "0")}: {item.header}
              </Alert.Heading>

              <Row className="text-right">
                <small>
                  <strong>
                    {moment(item.date).format("DD/MM/YY [às] HH:mm:ss")}
                  </strong>
                </small>
              </Row>

              <Row className="mt-2">
                <div>{item.message}</div>
              </Row>
            </Alert>
          </div>
        ))}
    </div>
  );
};

export default Notifications;
