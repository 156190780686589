import { Stack } from "react-bootstrap";
import {
  BarLoader,
  BounceLoader,
  ClipLoader,
  ClockLoader,
  DotLoader,
  FadeLoader,
  GridLoader,
  HashLoader,
  MoonLoader,
  PropagateLoader,
  PuffLoader,
  PulseLoader,
  ScaleLoader,
  SyncLoader,
} from "react-spinners";

type TypeLoader =
  | "BarLoader"
  | "BounceLoader"
  | "ClockLoader"
  | "DotLoader"
  | "HashLoader"
  | "MoonLoader"
  | "PropagateLoader"
  | "PuffLoader"
  | "GridLoader"
  | "ClipLoader"
  | "PulseLoader"
  | "SyncLoader"
  | "ScaleLoader"
  | "FadeLoader";
interface IProps {
  typeLoader?: TypeLoader;
  centeredOnComponent?: boolean;
  heightComponent?: number;
  color?: string;
  size?: number;
  title?: string;
}

const Loading: React.FC<IProps> = ({
  centeredOnComponent,
  heightComponent,
  typeLoader = "HashLoader",
  color = "#00baff",
  size,
  title = "Por favor, aguarde...",
}) => {
  const SIZE = size ? size : 60;
  var element = null;

  const getLoader = () => {
    switch (typeLoader) {
      case "ClipLoader":
        element = (
          <ClipLoader
            color={color}
            size={SIZE}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );
        break;

      case "BarLoader":
        element = (
          <BarLoader
            color={color}
            height={SIZE * 0.5}
            width={SIZE * 3}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );
        break;

      case "BounceLoader":
        element = (
          <BounceLoader
            color={color}
            size={SIZE}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );
        break;

      case "ClockLoader":
        element = (
          <ClockLoader
            color={color}
            size={SIZE}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );
        break;

      case "DotLoader":
        element = (
          <DotLoader
            color={color}
            size={SIZE}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );
        break;

      case "HashLoader":
        element = (
          <HashLoader
            color={color}
            size={SIZE}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );
        break;

      case "MoonLoader":
        element = (
          <MoonLoader
            color={color}
            size={SIZE}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );
        break;

      case "PropagateLoader":
        element = (
          <PropagateLoader
            color={color}
            size={SIZE}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );
        break;

      case "FadeLoader":
        element = (
          <FadeLoader
            color={color}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );
        break;

      case "GridLoader":
        element = (
          <GridLoader
            color={color}
            size={SIZE}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );
        break;

      case "PuffLoader":
        element = (
          <PuffLoader
            color={color}
            size={SIZE}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );
        break;

      case "PulseLoader":
        element = (
          <PulseLoader
            color={color}
            size={SIZE}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );
        break;

      case "ScaleLoader":
        element = (
          <ScaleLoader
            color={color}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );
        break;

      case "SyncLoader":
        element = (
          <SyncLoader
            color={color}
            size={SIZE}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );
        break;

      default:
        element = (
          <ClockLoader
            color={color}
            size={SIZE}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        );
        break;
    }

    return (
      <Stack gap={2} className="col-md-5 mx-auto">
        {element}
      </Stack>
    );
  };

  return (
    <div className="d-flex justify-content-center align-items-center my-5">
      <div
        style={{
          height: heightComponent ? heightComponent * 0.8 : undefined,
        }}
        className={`${
          centeredOnComponent ? "center__box__component" : "center__box"
        }`}
      >
        {getLoader()}
        <div className="text-center">{title}</div>
      </div>
    </div>
  );
};

export default Loading;
