import { jwtDecode } from "jwt-decode";

export const isTokenValid = (token?: string): boolean => {
  try {
    if (token) {
      const decodedToken: any = jwtDecode(token);
      const expirationDate: number = decodedToken.exp * 1000; // Convertendo para milissegundos
      const now: number = Date.now();

      return expirationDate > now;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
