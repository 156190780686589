import { FC, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { IAlertMessage } from "../../../../../libs/types/messages";
import { LancamentoAttributes } from "../../../../../libs/types/models/sufl";
import {
  ClassificacaoTributariaAttributes,
  ImpostosAttributes,
  NaturezaRendimentoAttributes,
  TributacaoServicoAttributes,
} from "../../../../../libs/types/models/sulo";
import LancamentoAPI from "../../../services/api/lancamentos";
import NfeAPI from "../../../services/api/nfe";
import ShowPDF from "../../pdf";
import Fornecedores, { IFornecedores } from "./components/main/fornecedores";
import Lancamentos, { ILancamento } from "./components/main/lancamentos";
import ModalChangeStatus from "./components/main/modalChangeStatus";
import checkLancamentoResumed from "./components/main/validations";
import ModalFormEditLancs from "./components/modalFormEditLancs";

interface IProps {
  lancamentos: ILancamento[];
  fornecedores: IFornecedores[];
  impostos: ImpostosAttributes[];
  natureza: NaturezaRendimentoAttributes[];
  classificacoes: ClassificacaoTributariaAttributes[];
  servico: TributacaoServicoAttributes[];
  errors: IAlertMessage[];
  setLancamentos: (item: ILancamento[]) => void;
  setFornecedores: React.Dispatch<React.SetStateAction<IFornecedores[] | null>>;
  setErrors: React.Dispatch<React.SetStateAction<IAlertMessage[]>>;
  inssID: number;
  pisID: number;
  issID: number;
  irID: number;
  restoreItem: (newLancamento: LancamentoAttributes) => void;
  submitLancamentos: () => void;
  submitFornecedores: () => void;
  onSortLancamento: (lancamentos: ILancamento[]) => void;
  onSetLoading?: (status: boolean) => void;
}

const PreLancs: FC<IProps> = ({
  lancamentos,
  fornecedores,
  impostos,
  natureza,
  classificacoes,
  servico,
  errors,
  setLancamentos,
  setFornecedores,
  setErrors,
  inssID,
  pisID,
  issID,
  irID,
  restoreItem,
  submitLancamentos,
  submitFornecedores,
  onSortLancamento,
  onSetLoading,
}) => {
  const [hideFornecedores, setHideFornecedores] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const [pdfFile, setPdfFile] = useState<{ file: any; name: string } | null>(
    null
  );

  const [changeStatusLancamento, setChangeStatusLancamento] = useState<{
    item: LancamentoAttributes[];
    new_status: number;
    obs: string;
  }>();

  const [editLancamento, setEditLancamento] = useState<ILancamento | null>(
    null
  );

  const checkedFornecedorChange = (index: number) => {
    if (fornecedores) {
      const newFornecedores = [...fornecedores];
      newFornecedores[index] = {
        ...newFornecedores[index],
        checked: !newFornecedores[index].checked,
      };
      setFornecedores(newFornecedores);
    }
  };

  const checkedAllFornecedores = () => {
    if (fornecedores) {
      const newFornecedores: IFornecedores[] = fornecedores.map((item) => ({
        ...item,
        checked: true,
      }));
      setFornecedores(newFornecedores);
    }
  };

  const uncheckedAllFornecedores = () => {
    if (fornecedores) {
      const newFornecedores: IFornecedores[] = fornecedores.map((item) => ({
        ...item,
        checked: false,
      }));
      setFornecedores(newFornecedores);
    }
  };

  const checkedChange = (lancamento: ILancamento) => {
    if (lancamentos) {
      const newLancamentos = [...lancamentos];
      const index = newLancamentos.findIndex(
        (item) =>
          item.lancamento.id_fl_lancamento ===
          lancamento.lancamento.id_fl_lancamento
      );
      newLancamentos[index] = {
        ...newLancamentos[index],
        checked: !newLancamentos[index].checked,
      };
      setLancamentos(newLancamentos);
    }
  };

  const checkedAllLancamentos = () => {
    if (lancamentos) {
      const newLancamentos: ILancamento[] = lancamentos.map((item) => ({
        ...item,
        checked:
          item.lancamento.id_contato_con === null ||
          item.errors.filter((i) => i.type === "danger").length > 0
            ? false
            : true,
      }));
      setLancamentos(newLancamentos);
    }
  };

  const uncheckedAllLancamentos = () => {
    if (lancamentos) {
      const newLancamentos: ILancamento[] = lancamentos.map((item) => ({
        ...item,
        checked: false,
      }));
      setLancamentos(newLancamentos);
    }
  };

  const onAttachClick = async (id?: number, name?: string) => {
    if (id && name) {
      const result = await new NfeAPI().downloadFile(id);

      if (result.success) {
        setPdfFile({ file: result.data, name });
      } else {
        setErrors([
          ...errors,
          {
            key: uuidv4().toString(),
            header: "NFe PDF",
            message: result.message,
            variant: "danger",
            date: new Date(),
          },
        ]);
      }
    } else {
      setErrors([
        ...errors,
        {
          key: uuidv4().toString(),
          header: "Download do PDF",
          message: "ID do documento não informado.",
          variant: "danger",
          date: new Date(),
        },
      ]);
    }
  };

  const onEditClick = async (item: ILancamento) => {
    setEditLancamento(item);
    setShowModalEdit(true);
  };

  const restoreLancmento = async (item: ILancamento) => {
    const result = await new LancamentoAPI().getLancamento(
      item.lancamento.id_fl_lancamento
    );
    if (result.success) {
      const newLancamento: LancamentoAttributes = result.data;
      console.log("newLancamento", newLancamento);
      restoreItem(newLancamento);
    }
  };

  const onEditSubmit = async (change: boolean, item?: ILancamento) => {
    if (lancamentos) {
      if (change) {
        if (item) {
          const index = lancamentos.findIndex(
            (i) =>
              i.lancamento.id_fl_lancamento === item.lancamento.id_fl_lancamento
          );

          const newLancamentos = lancamentos;
          const errorItem = checkLancamentoResumed(
            item.lancamento,
            pisID,
            inssID,
            issID,
            irID
          );

          const updateItem: ILancamento = { ...item, errors: errorItem };

          newLancamentos[index] = updateItem;
          setLancamentos(newLancamentos);
        }
      }
    }

    setShowModalEdit(false);
    setEditLancamento(null);
  };

  const onChangeStatus = async (item: ILancamento[]) => {
    setChangeStatusLancamento({
      item: item.map((i) => {
        return i.lancamento;
      }),
      new_status: item[0].lancamento.fl_fl_status,
      obs: "",
    });
  };

  const onSubmitChangeStatus = async (item: LancamentoAttributes[]) => {
    try {
      if (onSetLoading) onSetLoading(true);

      if (changeStatusLancamento && lancamentos) {
        const removeIds: number[] = [];

        const promises = item.map(async (i) => {
          if (i.fl_fl_status !== changeStatusLancamento.new_status) {
            const updateLancamento: LancamentoAttributes = {
              ...i,
              fl_fl_status: changeStatusLancamento.new_status,
              st_status_obs: changeStatusLancamento.obs,
            };

            const result = await new LancamentoAPI().updateStatus(
              updateLancamento
            );

            if (result.success) {
              removeIds.push(updateLancamento.id_fl_lancamento);
            } else {
              setErrors([
                ...errors,
                {
                  key: uuidv4().toString(),
                  header: "Erro de Requisição",
                  message: result.message,
                  variant: "danger",
                  date: new Date(),
                },
              ]);
            }
          }
        });

        await Promise.all(promises);

        const newLancamentos: ILancamento[] = lancamentos.filter(
          (item) => !removeIds.includes(item.lancamento.id_fl_lancamento)
        );

        setLancamentos(newLancamentos);
        setChangeStatusLancamento(undefined);
      }
    } finally {
      if (onSetLoading) onSetLoading(false);
    }
  };

  return (
    <div>
      <ModalChangeStatus
        changeStatusLancamento={changeStatusLancamento}
        onSubmitChangeStatus={onSubmitChangeStatus}
        setChangeStatusLancamento={setChangeStatusLancamento}
      />

      {pdfFile && <ShowPDF pdfFile={pdfFile} />}

      {editLancamento && impostos && (
        <ModalFormEditLancs
          classificacoes={classificacoes}
          natureza={natureza}
          servico={servico}
          showModalEdit={showModalEdit}
          data={editLancamento}
          onEditSubmit={(change, lancamento) => {
            onEditSubmit(change, lancamento);
          }}
          impostos={impostos}
          inssID={inssID}
          pisID={pisID}
          issID={issID}
          irID={irID}
        />
      )}

      <h1>Pré Lançamentos</h1>

      <Fornecedores
        fornecedores={fornecedores}
        checkedFornecedorChange={checkedFornecedorChange}
        checkedAllFornecedores={checkedAllFornecedores}
        uncheckedAllFornecedores={uncheckedAllFornecedores}
        onSubmit={submitFornecedores}
      />

      {lancamentos && (
        <Lancamentos
          impostos={impostos}
          lancamentos={lancamentos.filter(
            (item) => item.lancamento.fl_fl_status === 0
          )}
          checkedChange={checkedChange}
          checkedAllLancamentos={checkedAllLancamentos}
          uncheckedAllLancamentos={uncheckedAllLancamentos}
          onSubmit={submitLancamentos}
          hideFornecedores={hideFornecedores}
          hideUndefinedFornecedores={() =>
            setHideFornecedores(!hideFornecedores)
          }
          onAttachClick={(id, name) => onAttachClick(id, name)}
          onEditClick={onEditClick}
          onChangeStatus={onChangeStatus}
          restoreLancmento={restoreLancmento}
          onSortLancamento={onSortLancamento}
        />
      )}
    </div>
  );
};

export default PreLancs;
