import { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "./index.css";

//TODO melhorar

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface IProps {
  pdfFile: { file: any; name: string };
}

const controlButtons = [
  { index: 0, name: "Zoom In" },
  { index: 1, name: "Zoom Out" },
  { index: 2, name: "Rotacionar (+90º)" },
  { index: 3, name: "Rotacionar (-90º)" },
  { index: 4, name: "Download" },
];

const ShowPDF: FC<IProps> = ({ pdfFile }) => {
  const [show, setShow] = useState(false);
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const [activatedButton, setActivatedButton] = useState<number | undefined>();

  const fileName = pdfFile.name.split("\\").pop();

  const handleDownload = () => {
    // Lógica para iniciar o download do PDF
  };

  useEffect(() => {
    if (pdfFile !== null) {
      setShow(true);
      setScale(1);
      setRotate(0);
    }
  }, [pdfFile]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const handleClose = () => setShow(false);

  return (
    <Modal fullscreen show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{fileName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="d-flex justify-content-around">
            {controlButtons.map((btn) => {
              return (
                <Button
                  key={btn.index}
                  variant={
                    activatedButton === btn.index
                      ? "outline-secondary"
                      : "outline-primary"
                  }
                  onClick={() => setActivatedButton(btn.index)}
                >
                  {btn.name}
                </Button>
              );
            })}
          </div>

          <div>
            <button onClick={() => setScale(scale + 0.1)}>Zoom In</button>
            <button onClick={() => setScale(scale - 0.1)}>Zoom Out</button>
            <button onClick={() => setRotate(rotate === 360 ? 0 : rotate + 90)}>
              Rotate +90º
            </button>
            <button onClick={() => setRotate(rotate === 0 ? 360 : rotate - 90)}>
              Rotate -90º
            </button>
            <button onClick={handleDownload}>Download</button>
          </div>

          <div style={{ backgroundColor: "blue" }}>
            <Document
              className="teste"
              file={pdfFile.file}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                className="teste2"
                pageNumber={1}
                scale={scale}
                rotate={rotate}
                // height={100}
                onLoadSuccess={(inf) => {
                  console.log(inf);
                }}
              />
            </Document>
          </div>

          <p style={{ backgroundColor: "yellow" }}>
            Page {pageNumber} of {numPages}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShowPDF;
