import { FC } from "react";
import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import Select from "react-select";
import { LancamentoRetencaoAttributes } from "../../../../../../../../../libs/types/models/sufl";
import { ImpostosAttributes } from "../../../../../../../../../libs/types/models/sulo";
import ListFunctions from "../../../../../../../utils/functions/list";
import moment from "../../../../../../../utils/moment";
import numeral from "../../../../../../../utils/numeral";

interface IProps {
  showForm: {
    show: boolean;
    edit: boolean;
    vl_rv2_subempreitada_des: string;
    vl_rv2_material_des: string;
    vl_rv2_maodeobra_des: string;
    vl_rv2_valorretido_des: string;
    index?: number;
  };
  setShowForm: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      edit: boolean;
      vl_rv2_subempreitada_des: string;
      vl_rv2_material_des: string;
      vl_rv2_maodeobra_des: string;
      vl_rv2_valorretido_des: string;
      index?: number | undefined;
    }>
  >;
  newRetencao?: {
    lancamento?: LancamentoRetencaoAttributes;
    imposto?: ImpostosAttributes;
  } | null;
  setNewRetencao: (
    item:
      | {
          lancamento?: LancamentoRetencaoAttributes | undefined;
          imposto?: ImpostosAttributes;
        }
      | null
      | undefined
  ) => void;
  id_lancamento_fl: number;
  data_pagamento?: Date;
  data_nfe?: Date;
  submitForm: (item?: LancamentoRetencaoAttributes, index?: number) => void;
  impostos: ImpostosAttributes[];
  nfValue: number;
  retencoes: LancamentoRetencaoAttributes[] | undefined;
  pcc: string;
  inss: string;
  iss: string;
  ir: string;
}

const FormNewRetencoes: FC<IProps> = ({
  showForm,
  setShowForm,
  newRetencao,
  setNewRetencao,
  id_lancamento_fl,
  submitForm,
  impostos,
  data_pagamento,
  data_nfe,
  nfValue,
  retencoes,
  pcc,
  inss,
  iss,
  ir,
}) => {
  const updateImpsoto = (id?: string) => {
    const imposto = impostos.find(
      (item) => item.id_rv2_cod_imp.toString() === id
    );

    var dateIssue: Date | undefined = undefined;
    var impostoValue: number | undefined = undefined;

    if (imposto) {
      if (
        imposto.st_rv2_nome_imp.trim().toLowerCase().includes("iss") ||
        imposto.st_rv2_nome_imp.trim().toLowerCase().includes("inss")
      ) {
        if (data_nfe) {
          dateIssue = moment(data_nfe, "YYYY-MM-DD").add(1, "M").toDate();
        }
      } else if (imposto.st_rv2_nome_imp.trim().toLowerCase().includes("pis")) {
        if (data_pagamento) {
          dateIssue = moment(data_pagamento, "YYYY-MM-DD").add(1, "M").toDate();
        }
      }
      dateIssue = dateIssue
        ? moment(dateIssue, "YYYY-MM-DD")
            .date(imposto.nm_diavencimento_imp)
            .toDate()
        : undefined;

      if (imposto.st_rv2_nome_imp.trim().toLowerCase().includes("iss")) {
        impostoValue = parseFloat(iss.replace(",", "."));
      } else if (
        imposto.st_rv2_nome_imp.trim().toLowerCase().includes("inss")
      ) {
        impostoValue = parseFloat(inss.replace(",", "."));
      } else if (imposto.st_rv2_nome_imp.trim().toLowerCase().includes("pis")) {
        impostoValue = parseFloat(pcc.replace(",", "."));
      } else {
        impostoValue = (imposto.nm_rv2_aliquota_imp / 100) * nfValue;
      }
    }

    setNewRetencao({
      lancamento: {
        ...newRetencao?.lancamento,
        id_fl_lancamento: id_lancamento_fl,
        id_rv2_imposto_des: imposto?.id_rv2_cod_imp,
        dt_vencimento_pdes: dateIssue,
        fl_reterimposto_des: 1,
        vl_rv2_valorretido_des: impostoValue,
      },
      imposto,
    });
  };

  const cancelForm = () => {
    setNewRetencao({
      lancamento: undefined,
      imposto: undefined,
    });
    setShowForm({
      edit: false,
      show: false,
      vl_rv2_subempreitada_des: "",
      vl_rv2_material_des: "",
      vl_rv2_maodeobra_des: "",
      vl_rv2_valorretido_des: "",
    });
  };

  const submit = () => {
    if (showForm.edit) {
      submitForm(newRetencao?.lancamento, showForm.index);
    } else {
      submitForm();
    }
  };

  const getOptionsImpostos = () => {
    var optionImpostos: ImpostosAttributes[] = [];
    impostos.map((item) => {
      const contain = retencoes?.find(
        (i) => i.id_rv2_imposto_des === item.id_rv2_cod_imp
      );
      if (!contain) optionImpostos.push(item);
      return null;
    });

    return new ListFunctions().selectOption(optionImpostos, "id_rv2_cod_imp", [
      "st_rv2_nome_imp",
    ]);
  };

  return (
    <>
      {showForm.show && (
        <Container className="mt-4">
          <h5>{showForm.edit ? "Alteração de Retenção" : "Nova Retenção"}</h5>

          <Row className="mt-2">
            <Col>
              <Form.Label>
                Impsoto
                {newRetencao && newRetencao.imposto
                  ? ` (${numeral(
                      newRetencao.imposto.nm_rv2_aliquota_imp / 100
                    ).format("0.00 %")}):`
                  : ":"}
              </Form.Label>
              <Select
                placeholder="Selecione o Imposto"
                options={getOptionsImpostos()}
                onChange={(e) => {
                  updateImpsoto(e?.value);
                }}
                value={new ListFunctions().selectOption(
                  impostos.filter(
                    (item) =>
                      item.id_rv2_cod_imp ===
                      newRetencao?.lancamento?.id_rv2_imposto_des
                  ),
                  "id_rv2_cod_imp",
                  ["st_rv2_nome_imp"]
                )}
              />
            </Col>
          </Row>

          {newRetencao && newRetencao.imposto && (
            <>
              <Row className="mt-2">
                <Col>
                  <Form.Label>
                    Valor Retido - AVISAR SE DIF ABAIXO DA ALIQ:
                  </Form.Label>
                  <Form.Control
                    className="value__number"
                    type="text"
                    value={showForm.vl_rv2_valorretido_des}
                    onBlur={() => {
                      let val: any = showForm.vl_rv2_valorretido_des;
                      val = val.replace(".", "");
                      val = val.replace(",", ".");
                      val = parseFloat(val);

                      if (isNaN(val)) {
                        val = "0";
                      }

                      setNewRetencao({
                        ...newRetencao,
                        lancamento: {
                          ...newRetencao.lancamento,
                          id_fl_lancamento: id_lancamento_fl,
                          vl_rv2_valorretido_des: parseFloat(val),
                        },
                      });
                      setShowForm({
                        ...showForm,
                        vl_rv2_valorretido_des: numeral(val).format("#,##0.00"),
                      });
                    }}
                    onChange={(e) => {
                      setShowForm({
                        ...showForm,
                        vl_rv2_valorretido_des: e.target.value.replace(
                          /[^0-9,.]/g,
                          ""
                        ),
                      });
                    }}
                  />
                </Col>

                <Col>
                  <Form.Label>Data de Retenção:</Form.Label>
                  <Form.Control
                    className="value__date"
                    type="date"
                    value={
                      newRetencao.lancamento &&
                      newRetencao.lancamento.dt_vencimento_pdes
                        ? moment(
                            newRetencao.lancamento.dt_vencimento_pdes,
                            "YYYY-MM-DD"
                          ).format("YYYY-MM-DD")
                        : ""
                    }
                    onChange={(e) => {
                      setNewRetencao({
                        ...newRetencao,
                        lancamento: {
                          ...newRetencao.lancamento,
                          id_fl_lancamento: id_lancamento_fl,
                          dt_vencimento_pdes: moment(e.target.value).toDate(),
                        },
                      });
                    }}
                  />
                </Col>

                <Col>
                  <Form.Label>Reter do Valor Total da NF?</Form.Label>
                  <Form.Check
                    type="checkbox"
                    label={
                      newRetencao.lancamento &&
                      newRetencao.lancamento.fl_reterimposto_des
                        ? newRetencao.lancamento.fl_reterimposto_des === 1
                          ? "Sim"
                          : "Não"
                        : "Não"
                    }
                    onChange={(e) => {
                      const value =
                        newRetencao.lancamento &&
                        newRetencao.lancamento.fl_reterimposto_des
                          ? newRetencao.lancamento.fl_reterimposto_des === 1
                            ? 0
                            : 1
                          : 1;

                      setNewRetencao({
                        ...newRetencao,
                        lancamento: {
                          ...newRetencao.lancamento,
                          id_fl_lancamento: id_lancamento_fl,
                          fl_reterimposto_des: value,
                        },
                      });
                    }}
                    checked={
                      newRetencao.lancamento &&
                      newRetencao.lancamento.fl_reterimposto_des
                        ? newRetencao.lancamento.fl_reterimposto_des === 1
                          ? true
                          : false
                        : false
                    }
                  />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <Form.Label>Valor do Material:</Form.Label>
                  <Form.Control
                    className="value__number"
                    type="text"
                    value={showForm.vl_rv2_material_des}
                    onBlur={() => {
                      let val: any = showForm.vl_rv2_material_des;
                      val = val.replace(".", "");
                      val = val.replace(",", ".");
                      val = parseFloat(val);

                      if (isNaN(val)) {
                        val = "0";
                      }

                      setNewRetencao({
                        ...newRetencao,
                        lancamento: {
                          ...newRetencao.lancamento,
                          id_fl_lancamento: id_lancamento_fl,
                          vl_rv2_material_des: parseFloat(val),
                        },
                      });
                      setShowForm({
                        ...showForm,
                        vl_rv2_material_des: numeral(val).format("#,##0.00"),
                      });
                    }}
                    onChange={(e) => {
                      setShowForm({
                        ...showForm,
                        vl_rv2_material_des: e.target.value.replace(
                          /[^0-9,.]/g,
                          ""
                        ),
                      });
                    }}
                  />
                </Col>

                <Col>
                  <Form.Label>Valor da Mão de Obra:</Form.Label>
                  <Form.Control
                    className="value__number"
                    type="text"
                    value={showForm.vl_rv2_maodeobra_des}
                    onBlur={() => {
                      let val: any = showForm.vl_rv2_maodeobra_des;
                      val = val.replace(".", "");
                      val = val.replace(",", ".");
                      val = parseFloat(val);

                      if (isNaN(val)) {
                        val = "0";
                      }

                      setNewRetencao({
                        ...newRetencao,
                        lancamento: {
                          ...newRetencao.lancamento,
                          id_fl_lancamento: id_lancamento_fl,
                          vl_rv2_maodeobra_des: parseFloat(val),
                        },
                      });
                      setShowForm({
                        ...showForm,
                        vl_rv2_maodeobra_des: numeral(val).format("#,##0.00"),
                      });
                    }}
                    onChange={(e) => {
                      setShowForm({
                        ...showForm,
                        vl_rv2_maodeobra_des: e.target.value.replace(
                          /[^0-9,.]/g,
                          ""
                        ),
                      });
                    }}
                  />
                </Col>

                <Col>
                  <Form.Label>Valor da Subempreitada:</Form.Label>
                  <Form.Control
                    className="value__number"
                    type="text"
                    value={showForm.vl_rv2_subempreitada_des}
                    onBlur={() => {
                      let val: any = showForm.vl_rv2_subempreitada_des;
                      val = val.replace(".", "");
                      val = val.replace(",", ".");
                      val = parseFloat(val);

                      if (isNaN(val)) {
                        val = "0";
                      }

                      setNewRetencao({
                        ...newRetencao,
                        lancamento: {
                          ...newRetencao.lancamento,
                          id_fl_lancamento: id_lancamento_fl,
                          vl_rv2_subempreitada_des: parseFloat(val),
                        },
                      });
                      setShowForm({
                        ...showForm,
                        vl_rv2_subempreitada_des:
                          numeral(val).format("#,##0.00"),
                      });
                    }}
                    onChange={(e) => {
                      setShowForm({
                        ...showForm,
                        vl_rv2_subempreitada_des: e.target.value.replace(
                          /[^0-9,.]/g,
                          ""
                        ),
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <Form.Label>Complemento:</Form.Label>
                  <Form.Control
                    type="text"
                    value={newRetencao.lancamento?.st_complemento_pdes}
                    onChange={(e) => {
                      setNewRetencao({
                        ...newRetencao,
                        lancamento: {
                          ...newRetencao.lancamento,
                          id_fl_lancamento: id_lancamento_fl,
                          st_complemento_pdes: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col>
                  <Form.Label>Código de Barras:</Form.Label>
                  <Form.Control
                    type="text"
                    value={newRetencao.lancamento?.st_codigobarras_pdes}
                    onChange={(e) => {
                      setNewRetencao({
                        ...newRetencao,
                        lancamento: {
                          ...newRetencao.lancamento,
                          id_fl_lancamento: id_lancamento_fl,
                          st_codigobarras_pdes: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>
              </Row>
            </>
          )}

          <Row className="mt-2">
            <div className=" d-flex justify-content-end">
              <Stack direction="horizontal" gap={3} className="btn-group-sm">
                <Button variant="outline-secondary" onClick={cancelForm}>
                  Cancelar
                </Button>
                <Button variant="outline-primary" onClick={submit}>
                  {showForm.edit ? "Editar" : "Adicionar"}
                </Button>
              </Stack>
            </div>
          </Row>
        </Container>
      )}
    </>
  );
};

export default FormNewRetencoes;
