import UserAPI from "../../../services/api/user";

import { ILogin } from "../../../../../libs/types/users";
import FormComponent, { FieldForm, Links } from "../../../components/forms";
import { useAuth } from "../../../context/auth";
import GenerateError from "../../../validations/errors";
import DefaultContentPublic from "../defaultContent";

const fields: FieldForm[] = [
  {
    name: "usuario",
    label: "Usuário",
    type: "text",
    validations: { required: "Preencha o usuário" },
  },
  {
    name: "senha",
    label: "Senha",
    type: "password",
    validations: {
      required: "Informe sua senha",
    },
  },
];

const links: Links[] = [
  {
    name: "Cadastrar usuário",
    href: "/create-user",
  },
  {
    name: "Recuperar senha",
    href: "#",
  },
  {
    name: "Ativar usuário",
    href: "#",
  },
];

const HomePage = () => {
  const { setAuthToken } = useAuth();
  return (
    <DefaultContentPublic title="Login">
      {(navigate, setLoading, setErrors) => {
        const onSubmit = async (data: any) => {
          setLoading(true);

          try {
            const result = await new UserAPI().login(data);

            if (!result.success) {
              throw new GenerateError(result.message);
            }

            const auth: ILogin = result.data;
            setAuthToken(auth);
            navigate("/");
            return;
          } catch (error: any) {
            if (error instanceof GenerateError) {
              setErrors([error.returnAlert()]);
            } else {
              alert(error.message);
            }
          } finally {
            setLoading(false);
          }
        };

        return (
          <FormComponent
            fields={fields}
            links={links}
            onSubmit={onSubmit}
            buttonsEnd={true}
            variant="success"
            submitName="Entrar"
          />
        );
      }}
    </DefaultContentPublic>
  );
};

export default HomePage;
