import { Badge, Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import UseAnimations from "react-useanimations";
import alertCircle from "react-useanimations/lib/alertCircle";
import logo from "../../assets/fl.png";
import { useAlertContext } from "../../context/alerts";

const LoggedMenu = () => {
  const { showAlert, alerts, addAlert, removeAlert, setShowAlert } =
    useAlertContext();

  //console.log("alerts", alerts, showAlert);

  return (
    <div>
      <Navbar
        expand="lg"
        //bg="dark"
        //data-bs-theme="dark"
        //className="bg-body-tertiary"
        style={{ backgroundColor: "#BBBBBB" }}
      >
        <Container fluid>
          <Link to="/">
            <Navbar.Brand>
              <img
                src={logo}
                height="50"
                className="d-inline-block align-top"
                alt="Company Logo"
              />
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown title="Lançamentos" id="basic-nav-dropdown">
                <NavDropdown.Item>
                  <Link to="/lancamentos/pre">Pré-Lançamentos</Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/lancamentos/beneficios">Benefícios</Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/lancamentos/condomed">CondoMed</Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/lancamentos/fgts">FGTS</Link>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Relatórios" id="basic-nav-dropdown">
                <NavDropdown.Item>
                  <Link to="/relatorios/financeiro">Financeiro</Link>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <div className="d-flex align-items-center">
              {alerts.length > 0 && (
                <Badge
                  className="d-flex flex-row align-items-center me-5 button__item"
                  text="dark"
                  bg="warning"
                  onClick={() => {
                    setShowAlert(true);
                  }}
                >
                  {/* <Stack direction="horizontal" gap={2} className="mx-3"> */}
                  <UseAnimations
                    animation={alertCircle}
                    size={35}
                    strokeColor="black"
                  />

                  <Badge pill bg="danger" className="mx-1">
                    {alerts.filter((i) => i.variant === "danger").length}
                  </Badge>
                  <Badge pill bg="success" className="mx-1">
                    {alerts.filter((i) => i.variant !== "danger").length}
                  </Badge>
                  {/* </Stack> */}
                </Badge>
              )}

              <Nav.Link>
                <Link to="/logout">Sair</Link>
              </Nav.Link>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Outlet />
    </div>
  );
};

export default LoggedMenu;
