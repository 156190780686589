import boletos from "@mrmgomes/boleto-utils";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { IAlertMessage } from "../../../../../../libs/types/messages";
import {
  PlanoAttributes,
  PlanoCondominioAttributes,
} from "../../../../../../libs/types/models/sulo";
import { IReturnHTTP } from "../../../../../../libs/types/returns";
import CondomedLancs from "../../../../components/lancamentos/condomed";
import { useAlertContext } from "../../../../context/alerts";
import useConfirm from "../../../../hooks/useConfirm";
import LancamentoAPI from "../../../../services/api/lancamentos";
import SuperlogicaAPI from "../../../../services/api/sulo";
import moment from "../../../../utils/moment";
import numeral from "../../../../utils/numeral";
import DefaultContentPrivate from "../../defaultContent";

export interface ICondomedArquivos {
  planoCondominio?: PlanoCondominioAttributes;
  plano?: PlanoAttributes;
  referencia: string;
  files?: {
    file: File;
    type_file: string;
    new_file_name: string;
  }[];
}

export interface ICondomedDespesas {
  checked: boolean;
  errors?: string;
  ST_NOME_COND: string;
  ID_CONDOMINIO_COND: string;
  ST_NOME_CON: string;
  ID_CONTATO_CON: string;
  ST_NOMERECEBEDOR_FAV: string;
  ID_FAVORECIDO_CON: string;
  DT_VENCIMENTOPRIMEIRAPARCELA: string;
  ID_FORMA_PAG: string;
  DT_DESPESA_DES: string;
  ID_TIPO_DOC: string;
  ST_DOCUMENTO_DES: string;
  ST_SERIENOTA_DES: string;
  VL_VALOR_PDES: number;
  ST_CODIGOBARRAS_PDES: string;
  files: {
    FL_TIPO_ARQ: number;
    ID_ETIQUETA_ETI: number;
    name: string;
    file: string;
  }[];
  type: string;
  ST_CONTA_CONT: string;
  ST_DESCRICAO_CONT: string;
  ST_LABEL_COND: string;
}

const CondomedLancamentosPage = () => {
  const { addAlert, setShowAlert } = useAlertContext();

  const [errors, setErrors] = useState<IAlertMessage[]>([]);
  const [loading, setLoading] = useState(false);

  const [planosCondominio, setPlanosCondominio] = useState<
    PlanoCondominioAttributes[]
  >([]);
  const [planos, setPlanos] = useState<PlanoAttributes[]>([]);

  const [dataFiles, setDataFiles] = useState<ICondomedArquivos>({
    planoCondominio: undefined,
    files: undefined,
    referencia: moment().add(+1, "M").format("MM/YY"),
  });

  const [dataDespesas, setDataDespesas] = useState<ICondomedDespesas[]>();

  useEffect(() => {
    const loadPlanosCondominios = async () => {
      const result = await new SuperlogicaAPI().listPlanosCondominios();
      if (result.success) setPlanosCondominio(result.data);
    };

    loadPlanosCondominios();
  }, []);

  const loadPlanos = async (id?: string) => {
    const result = await new SuperlogicaAPI().listPlanos(id);
    if (result.success) {
      const planoCon = planosCondominio.find(
        (item) => item.id_planoconta_plc.toString() === id
      );
      setPlanos(result.data);
      setDataFiles({
        ...dataFiles,
        planoCondominio: planoCon,
      });
    }
  };

  const checkBoleto = (despesa: ICondomedDespesas) => {
    var errors: undefined | string = undefined;

    if (despesa.ST_CODIGOBARRAS_PDES.trim() === "") {
      errors = "Sem código de barras";
    } else {
      const result = boletos.validarBoleto(despesa.ST_CODIGOBARRAS_PDES.trim());

      if (result.sucesso) {
        const valBoleto = parseFloat(result.valor.toString());
        const valDespesa = parseFloat(despesa.VL_VALOR_PDES.toString());

        const dateBoleto = moment(result.vencimento).format("DD/MM/YYYY");
        const dateDespesa = moment(
          despesa.DT_VENCIMENTOPRIMEIRAPARCELA,
          "MM/DD/YYYY"
        ).format("DD/MM/YYYY");

        if (valBoleto !== valDespesa) {
          errors = `Valor do código de barras (${numeral(
            valBoleto.toString().replace(".", ",")
          ).format("#,##0.00")}) diferente do lançamento (${numeral(
            valDespesa.toString().replace(".", ",")
          ).format("#,##0.00")})`;
        }

        if (dateBoleto !== dateDespesa) {
          const dateError = `Vncto. do boleto (${dateBoleto}) diferente do vncto. do lançamento (${dateDespesa}).`;

          if (!errors) {
            errors = `${errors}. ${dateError}`;
          } else {
            errors = dateError;
          }
        }
      } else {
        errors = "Código de barras inválido";
      }
    }

    return errors;
  };

  const sendFiles = async () => {
    if (dataFiles.files) {
      try {
        setLoading(true);
        const result = await new LancamentoAPI().readFilesCondomed(dataFiles);

        const newData: ICondomedDespesas[] = [];
        if (result.success) {
          result.data.map((item: any) => {
            if (item.success) {
              var conta = "";
              var conta_completa = "";

              conta = dataFiles.plano ? dataFiles.plano.st_conta_cont : "";
              conta_completa = dataFiles.plano
                ? `${dataFiles.plano.st_conta_cont} ${dataFiles.plano.st_descricao_cont}`
                : "";

              newData.push({
                ...item.data,
                VL_VALOR_PDES: parseFloat(item.data.VL_VALOR_PDES),
                ST_CONTA_CONT: conta,
                ST_DESCRICAO_CONT: conta_completa,
                checked: true,
                errors: checkBoleto(item.data),
              });
            }
          });
        }
        setDataDespesas(newData);
      } finally {
        setLoading(false);
      }
    }
  };

  const createDespesas = async () => {
    if (dataDespesas) {
      try {
        setLoading(true);

        const result: any = await new LancamentoAPI().createCondomed(
          dataDespesas.filter((item) => item.checked)
        );

        if (Array.isArray(result)) {
          result.map((item: IReturnHTTP) => {
            if (!item.success) {
              addAlert({
                key: uuidv4().toString(),
                header: `Erro de Lançamento (${item.data.st_fantasia_cond})`,
                date: new Date(),
                message: item.message,
                variant: "danger",
              });
            } else {
              addAlert({
                key: uuidv4().toString(),
                header: `Lançamento Criado (${item.data.id_despesa_des})`,
                date: new Date(),
                message: `Despesa criada na Superlógica com ID ${
                  item.data.id_despesa_des
                } para o ${item.data.st_fantasia_cond}, valor de ${numeral(
                  parseFloat(item.data.vl_valor_pdes)
                    .toString()
                    .replace(".", ",")
                ).format("$ #,##0.00")}.`,
                variant: "success",
              });
            }
            return null;
          });

          setDataFiles({
            planoCondominio: undefined,
            files: undefined,
            referencia: moment().add(+1, "M").format("MM/YY"),
          });
          setDataDespesas(undefined);
        } else {
          addAlert({
            key: uuidv4().toString(),
            header: `Erro`,
            date: new Date(),
            message: result.message,
            variant: result.success ? "success" : "danger",
          });
        }
        setShowAlert(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const {
    handleShow: handleShowCondomed,
    ConfirmModalComponent: ConfirmModalArquivos,
  } = useConfirm({
    title: "Processar arquivos de Benefícios?",
    message: `Você tem certeza que deseja realizar a leitura desses documentos de Benefícios?`,
    onConfirm: sendFiles,
  });

  const {
    handleShow: handleShowCondomedDespesas,
    ConfirmModalComponent: ConfirmModalDespesas,
  } = useConfirm({
    title: "Processar despesas de Benefícios?",
    message: `Você tem certeza que deseja incluir esses lançamentos de Benefícios?`,
    onConfirm: createDespesas,
  });

  return (
    <DefaultContentPrivate loading={loading} setLoading={setLoading}>
      {(navigate) => {
        return (
          <div>
            <h1>Pacote de Serviços (Condomed) - Lançamentos por Arquivos</h1>

            {planosCondominio && (
              <CondomedLancs
                data={dataFiles}
                setData={setDataFiles}
                planosCondominio={planosCondominio}
                planos={planos}
                loadPlanos={loadPlanos}
                onSubmit={handleShowCondomed}
                dataDespesas={dataDespesas}
                setDataDespesas={setDataDespesas}
                onSubmitDespesas={handleShowCondomedDespesas}
              />
            )}
            {ConfirmModalArquivos}
            {ConfirmModalDespesas}
          </div>
        );
      }}
    </DefaultContentPrivate>
  );
};

export default CondomedLancamentosPage;
