import { FC, useState } from "react";

import { Accordion, Row } from "react-bootstrap";
import { MdAddCircle, MdAddCircleOutline } from "react-icons/md";
import { LancamentoApropriacaoAttributes } from "../../../../../../../../libs/types/models/sufl";
import {
  GrupoSaldosAttributes,
  PlanoAttributes,
  PlanoCondominioAttributes,
} from "../../../../../../../../libs/types/models/sulo";
import numeral from "../../../../../../utils/numeral";
import Errors, { ErrorsLancs } from "../validations/errors";
import FormNewApropriacoes from "./formNew";
import TableApropriacoesItem from "./item";

interface IProps {
  apropriacoes: LancamentoApropriacaoAttributes[];
  id_lancamento_fl: number;
  nfValue: number;
  updateApropriacoes: (item: LancamentoApropriacaoAttributes[]) => void;
  errors: ErrorsLancs[];
  setErrors: React.Dispatch<React.SetStateAction<ErrorsLancs[]>>;
  grupoSaldos: GrupoSaldosAttributes[];
}

const TableApropriacoes: FC<IProps> = ({
  apropriacoes,
  nfValue,
  id_lancamento_fl,
  updateApropriacoes,
  errors,
  setErrors,
  grupoSaldos,
}) => {
  const [addApropriacao, setAddApropriacao] = useState(false);

  const [showForm, setShowForm] = useState<{
    show: boolean;
    edit: boolean;
    value: string;
    index?: number;
  }>({
    show: false,
    edit: false,
    value: "",
  });

  const [newApropriacao, setNewApropriacao] = useState<{
    lancamento?: LancamentoApropriacaoAttributes;
    planoCondominio?: PlanoCondominioAttributes;
    plano?: PlanoAttributes;
  } | null>();

  const onAddApropriacao = async () => {
    setShowForm({
      show: true,
      edit: false,
      value: "",
    });
  };

  const onEditApropriacao = (
    item: LancamentoApropriacaoAttributes,
    index: number
  ) => {
    const value = item.vl_valor_pdes
      ? numeral(item.vl_valor_pdes.toString().replace(".", ",")).format(
          "#,##0.00"
        )
      : "";
    setNewApropriacao({
      plano: undefined,
      planoCondominio: undefined,
      lancamento: item,
    });
    setShowForm({ edit: true, show: true, value: value, index });
  };

  const onDeleteApropriacao = (index: number) => {
    const newApropriacoes = apropriacoes.filter((_, i) => i !== index);
    updateApropriacoes(newApropriacoes);
    setNewApropriacao({
      lancamento: undefined,
      plano: undefined,
      planoCondominio: newApropriacao?.planoCondominio,
    });
    setShowForm({ edit: false, show: false, value: "", index: undefined });
  };

  const submitForm = (
    item?: LancamentoApropriacaoAttributes,
    index?: number
  ) => {
    if (showForm.edit) {
      if (item !== undefined && index !== undefined) {
        var newApropriacoes = apropriacoes;
        newApropriacoes[index] = item;

        updateApropriacoes(newApropriacoes);
        setNewApropriacao({
          lancamento: undefined,
          plano: undefined,
          planoCondominio: newApropriacao?.planoCondominio,
        });
        setShowForm({ edit: false, show: false, value: "", index: undefined });
      }
    } else {
      if (newApropriacao && newApropriacao.plano && newApropriacao.lancamento) {
        const newAprop: LancamentoApropriacaoAttributes = {
          ...newApropriacao.lancamento,
          st_descricao_cont: newApropriacao.plano.st_descricao_cont,
          st_conta_cont: newApropriacao.plano.st_conta_cont,
          st_complemento_apro: newApropriacao.lancamento.st_complemento_apro,
          st_nomegruposaldo_gs: newApropriacao.lancamento.st_nomegruposaldo_gs,
        };

        updateApropriacoes([...apropriacoes, newAprop]);
        setNewApropriacao({
          lancamento: undefined,
          plano: undefined,
          planoCondominio: newApropriacao?.planoCondominio,
        });
        setShowForm({ edit: false, show: false, value: "", index: undefined });
      }
    }
  };

  return (
    <Accordion defaultActiveKey="0" className="my-3">
      <Accordion.Item eventKey="1">
        <Accordion.Header as="h3">
          Apropriações (Natureza da Despesa)
          {errors.filter((item) => item.session === "apropriacoes").length > 0
            ? ` [${
                errors.filter((item) => item.session === "apropriacoes").length
              }]`
            : ""}
        </Accordion.Header>
        <Accordion.Body>
          <Errors
            errors={errors}
            session="apropriacoes"
            setErrors={setErrors}
          />

          <Row>
            <table className="despesas__table">
              <thead>
                <tr>
                  <th className="text-center" colSpan={3}>
                    <div className="d-flex justify-content-around">
                      <div>#</div>
                      <div
                        className="button__icon"
                        onMouseEnter={() => setAddApropriacao(true)}
                        onMouseLeave={() => setAddApropriacao(false)}
                      >
                        {addApropriacao ? (
                          <MdAddCircle
                            size={18}
                            title="Excluir Apropriação"
                            onClick={onAddApropriacao}
                          />
                        ) : (
                          <MdAddCircleOutline size={18} />
                        )}
                      </div>
                    </div>
                  </th>
                  <th className="text-center">Conta</th>
                  <th className="text-center">Descrição</th>
                  <th className="text-center">Grupo</th>
                  <th className="text-center">Valor</th>
                  <th className="text-center">%</th>
                </tr>
              </thead>
              <tbody>
                {apropriacoes.map((item, index) => {
                  return (
                    <TableApropriacoesItem
                      key={`aprop_${index}`}
                      item={item}
                      id_lancamento_fl={id_lancamento_fl}
                      nfValue={nfValue}
                      onEditApropriacao={onEditApropriacao}
                      onDeleteApropriacao={onDeleteApropriacao}
                      index={index}
                      grupo={grupoSaldos.find(
                        (i) =>
                          i.label?.toUpperCase().trim() ===
                          item.st_nomegruposaldo_gs
                      )}
                    />
                  );
                })}
              </tbody>
            </table>
          </Row>

          <Row>
            <FormNewApropriacoes
              newApropriacao={newApropriacao}
              setNewApropriacao={setNewApropriacao}
              setShowForm={setShowForm}
              showForm={showForm}
              id_lancamento_fl={id_lancamento_fl}
              grupoSaldos={grupoSaldos}
              nfValue={nfValue}
              submitForm={submitForm}
            />
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default TableApropriacoes;
