import { Accordion, Button } from "react-bootstrap";
import { ICondomedArquivos } from "../../../pages/private/lancamentos/condomed";

interface IProps {
  children: React.ReactNode;
  title: string;
  onSubmit: () => void;
  onClean: () => void;
  closeAccordion?: string;
  data: ICondomedArquivos;
}

const CondomedBaseFilters: React.FC<IProps> = ({
  children,
  title,
  onSubmit,
  onClean,
  closeAccordion = "0",
  data,
}) => {
  const verifyButtonSend = () => {
    var result = true;

    if (data.files !== undefined && data.files.length > 0) {
      console.log("ver", data.files);

      if (data.files.filter((item) => item.type_file === "").length === 0) {
        result = false;
      }
    }

    if (!result) {
      const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
      if (data.referencia === "" || !regex.test(data.referencia)) {
        result = true;
      } else {
        result = false;
      }
    }

    return result;
  };

  return (
    <Accordion className="mt-5" defaultActiveKey="0">
      <Accordion.Item eventKey={closeAccordion}>
        <Accordion.Header>{title}</Accordion.Header>
        <Accordion.Body>
          {children}

          <div className="d-flex justify-content-between my-3">
            <Button variant="danger" onClick={onClean}>
              Limpar Dados
            </Button>

            <Button
              variant="success"
              onClick={onSubmit}
              disabled={verifyButtonSend()}
            >
              Enviar Arquivos
            </Button>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default CondomedBaseFilters;
