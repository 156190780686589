export interface Options {
  value: string;
  label: string;
  st_nome: string;
}

export default class ListFunctions {
  selectOption = (
    list: any[],
    valueName: string,
    labelsName: string[],
    customizedOptions: {
      value: string;
      label: string;
    }[] = []
  ) => {
    const newList: { value: string; label: string }[] = customizedOptions;

    list.map((item) => {
      var newLabel = "";

      labelsName.map((l, index) => {
        if (index === 0) {
          newLabel = item[l];
        } else {
          newLabel += " - " + item[l];
        }
        return null;
      });

      newList.push({
        value: item[valueName].toString(),
        label: newLabel,
      });
      return null;
    });

    return newList;
  };
}
