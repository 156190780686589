import React from "react";
import { Accordion, Button } from "react-bootstrap";

export const STATUS_LANCAMENTOS = [
  { value: 0, name: "Pendente" },
  { value: 1, name: "Aguardando Confirmação" },
  { value: 2, name: "Arquivar" },
  { value: 3, name: "Lançado" },
];

export const ORDER_LANCAMENTOS = [
  {
    value: 0,
    name: "ID de Lançamento",
    field: "id_fl_lancamento",
  },
  { value: 1, name: "Despesa", field: "id_despesa_des" },
  {
    value: 2,
    name: "Data de Lançamento",
    field: "dt_lancamento",
  },
  { value: 3, name: "NFe", field: "st_documento_des" },
  { value: 4, name: "Data de NFe", field: "dt_despesa_des" },
  { value: 5, name: "Valor da NFe", field: "vl_fl_nfe" },
  {
    value: 6,
    name: "Data de Vencimento",
    field: "dt_vencimentoprimeiraparcela",
  },
  {
    value: 7,
    name: "Tipo de Pagamento",
    field: "id_forma_pag",
  },
  { value: 8, name: "Condomínio", field: "" },
  { value: 9, name: "Fornecedor", field: "" },
];

export const FORMA_PAGAMENTO = [
  { value: 0, label: "Boleto" },
  { value: 1, label: "Cheque" },
  { value: 2, label: "Dinheiro" },
  { value: 3, label: "Cartão de crédito" },
  { value: 4, label: "Cartão de débito" },
  { value: 7, label: "Débito automático" },
  { value: 8, label: "Trans. bancária" },
  { value: 9, label: "Doc/Ted" },
  { value: 10, label: "Outros" },
  { value: 12, label: "Pix" },
  { value: 13, label: "DCTFWeb" },
];

export const CHAVES_PIX = [
  { value: 1, label: "CPF", isDisabled: false },
  { value: 2, label: "CNPJ", isDisabled: false },
  { value: 3, label: "Celular", isDisabled: false },
  { value: 4, label: "Email", isDisabled: false },
  { value: 5, label: "Aleatória", isDisabled: false },
  { value: 6, label: "QR Code", isDisabled: true },
  { value: 7, label: "Dados bancários", isDisabled: false },
];

interface IProps {
  children: React.ReactNode;
  checkedAll: () => void;
  uncheckedAll: () => void;
  onSubmit: () => void;
  data: any[] | null;
  title: string;
}

const BasePreLancs: React.FC<IProps> = ({
  children,
  checkedAll,
  uncheckedAll,
  onSubmit,
  data,
  title,
}) => {
  const CheckButtons = () => {
    return (
      <div className="d-flex my-2">
        <div className="p-2 flex-fill">
          <span
            className={
              data?.length === 0 ? `link__button__disabled` : `link__button`
            }
            onClick={checkedAll}
          >
            Marcar Todos
          </span>{" "}
          |{" "}
          <span
            className={
              data?.length === 0 ? `link__button__disabled` : `link__button`
            }
            onClick={uncheckedAll}
          >
            Desmarcar Todos
          </span>
        </div>
        <Button
          onClick={onSubmit}
          disabled={
            data
              ? data.length === 0
                ? true
                : data.filter((item) => item.checked).length === 0
                ? true
                : false
              : false
          }
        >
          Processar{" "}
          {data ? `(${data.filter((item) => item.checked).length})` : ""}
        </Button>
      </div>
    );
  };

  return (
    <div className="mt-5">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {title}
            {data && data.length > 0 ? ` (${data.length})` : ""}
          </Accordion.Header>
          <Accordion.Body>
            <CheckButtons />
            {children}
            <CheckButtons />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default BasePreLancs;
