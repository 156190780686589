import { IReturnHTTP } from "../../../../libs/types/returns";
import StorageItems from "../../storage";

const URL = process.env.REACT_APP_API_HOST;
const auth = new StorageItems().get("authToken");

class API {
  result: IReturnHTTP;
  base_url = URL;
  token;

  constructor() {
    this.result = { data: null, message: "", success: false };
    this.token = auth?.authToken;
  }
}

export default API;
