import { useState } from "react";
import ConfirmModal from "../components/confirmModal";

interface UseConfirmProps {
  title: string;
  message: string;
  onConfirm: () => void;
}

const useConfirm = ({ title, message, onConfirm }: UseConfirmProps) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);
  const handleConfirm = () => {
    onConfirm();
    handleHide();
  };

  const ConfirmModalComponent = (
    <ConfirmModal
      show={show}
      onHide={handleHide}
      onConfirm={handleConfirm}
      title={title}
      message={message}
    />
  );

  return { handleShow, ConfirmModalComponent };
};

export default useConfirm;
