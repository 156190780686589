import { useState } from "react";
import { MdCancel, MdOutlineCancel } from "react-icons/md";
import { LancamentoAttributes } from "../../../../../../../../../libs/types/models/sufl";
import numeral from "../../../../../../../utils/numeral";

interface IProps {
  item: LancamentoAttributes;
  onClick: (item: LancamentoAttributes) => void;
}

const ModalChangeStatusItem: React.FC<IProps> = ({ item, onClick }) => {
  const [removeItem, setRemoveItem] = useState(false);

  return (
    <li>
      <div
        className="button__icon align-items-center"
        onMouseEnter={() => setRemoveItem(true)}
        onMouseLeave={() => setRemoveItem(false)}
        onClick={() => onClick(item)}
      >
        {removeItem ? (
          <MdCancel className="mx-1" size={15} title="Alterar Situação" />
        ) : (
          <MdOutlineCancel className="mx-1" size={15} />
        )}

        {`${item.id_fl_lancamento.toString().padStart(5, "0")} - ${
          item.st_nome_con ? item.st_nome_con : "Sem Fornecedor"
        } - ${item.Condominio?.st_nome_cond} - NF: ${item.NFe?.nfe} - ${numeral(
          item.vl_fl_nfe.toString().replace(".", ",")
        ).format("$ #,##0.00")}`}
      </div>
    </li>
  );
};

export default ModalChangeStatusItem;
