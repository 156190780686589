import moment from "../moment";

export default class SortFunctions {
  a: any;
  b: any;

  constructor(a: any, b: any) {
    this.a = a;
    this.b = b;
  }

  // Helper method to handle null or undefined values
  handleNullValuesAsc = (a: any, b: any) => {
    if (a === null || a === undefined) return -1;
    if (b === null || b === undefined) return 1;
    return 0;
  };

  handleNullValuesDesc = (a: any, b: any) => {
    if (a === null || a === undefined) return 1;
    if (b === null || b === undefined) return -1;
    return 0;
  };

  sortStrAsc = () => {
    const nullCheck = this.handleNullValuesAsc(this.a, this.b);
    if (nullCheck !== 0) return nullCheck;
    return this.a.localeCompare(this.b);
  };

  sortStrDesc = () => {
    const nullCheck = this.handleNullValuesDesc(this.a, this.b);
    if (nullCheck !== 0) return nullCheck;
    return this.b.localeCompare(this.a);
  };

  sortNumberAsc = () => {
    const nullCheck = this.handleNullValuesAsc(this.a, this.b);
    if (nullCheck !== 0) return nullCheck;
    return this.a - this.b;
  };

  sortNumberDesc = () => {
    const nullCheck = this.handleNullValuesDesc(this.a, this.b);
    if (nullCheck !== 0) return nullCheck;
    return this.b - this.a;
  };

  sortDateAsc = (mask?: string) => {
    const nullCheck = this.handleNullValuesAsc(this.a, this.b);
    if (nullCheck !== 0) return nullCheck;
    const dateA = moment(this.a).isValid()
      ? moment(this.a, mask).toDate()
      : null;
    const dateB = moment(this.b).isValid()
      ? moment(this.b, mask).toDate()
      : null;
    return (dateA ? +dateA : 0) - (dateB ? +dateB : 0);
  };

  sortDateDesc = (mask?: string) => {
    const nullCheck = this.handleNullValuesDesc(this.a, this.b);
    if (nullCheck !== 0) return nullCheck;
    const dateA = moment(this.a).isValid()
      ? moment(this.a, mask).toDate()
      : null;
    const dateB = moment(this.b).isValid()
      ? moment(this.b, mask).toDate()
      : null;
    return (dateB ? +dateB : 0) - (dateA ? +dateA : 0);
  };
}
