import React from "react";
import { Form } from "react-bootstrap";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { FieldForm } from "..";
import moment from "../../../utils/moment";
import "../style.css";

const Input: React.FC<{
  field: FieldForm;
  control: any;
  register: UseFormRegister<FieldValues>;
  error?: any;
}> = ({ field, control, register, error }) => {
  return (
    <Form.Group>
      <Form.Label>{field.label}:</Form.Label>
      <Form.Control
        defaultValue={
          field.value
            ? field.type === "date"
              ? moment(field.value, "YYYY-MM-DD").format("YYYY-MM-DD")
              : field.value
            : ""
        }
        disabled={field.disabled}
        type={field.type}
        {...register(field.name, field.validations)}
      />
      <div className="d-flex flex-row-reverse">
        {error && <small className="alert-message">*{error}</small>}
      </div>
    </Form.Group>
  );
};

export default Input;
