import { Accordion, Button } from "react-bootstrap";

interface IProps {
  children: React.ReactNode;
  title: string;
  onSubmit: () => void;
  onClean: () => void;
  closeAccordion?: string;
}

const ReportBaseFilters: React.FC<IProps> = ({
  children,
  title,
  onSubmit,
  onClean,
  closeAccordion = "0",
}) => {
  return (
    <Accordion className="mt-5" defaultActiveKey="0">
      <Accordion.Item eventKey={closeAccordion}>
        <Accordion.Header>{title}</Accordion.Header>
        <Accordion.Body>
          {children}

          <div className="d-flex justify-content-between my-3">
            <Button variant="danger" onClick={onClean}>
              Limpar Filtros
            </Button>

            <Button variant="success" onClick={onSubmit}>
              Pesquisar Dados
            </Button>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ReportBaseFilters;
