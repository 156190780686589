import UserAPI from "../../../services/api/user";

import FormComponent, { FieldForm } from "../../../components/forms";
import GenerateError from "../../../validations/errors";
import DefaultContentPublic from "../defaultContent";

const fields: FieldForm[] = [
  {
    name: "nome",
    label: "Nome",
    type: "text",
    validations: { required: "Nome é obrigatório" },
  },
  {
    name: "email",
    label: "Email",
    type: "email",
    validations: {
      required: "Email é obrigatório",
      pattern: { value: /^\S+@\S+$/i, message: "Email inválido" },
    },
  },
  {
    name: "usuario",
    label: "Usuário",
    type: "text",
    validations: { required: "Usuário é obrigatório" },
  },
  {
    name: "senha",
    label: "Senha",
    type: "password",
    lg_col: 6,
    md_col: 6,
    validations: {
      required: "Senha é obrigatório",
      //validate: (value: any) =>
      //  value.length >= 8 || "A senha deve ter pelo menos 8 caracteres",
    },
  },
  {
    name: "confirma_senha",
    label: "Confirmação de Senha",
    type: "password",
    lg_col: 6,
    md_col: 6,
    validations: {
      required: "Confirmação de senha é obrigatória",
      //validate: (value: any) =>
      //  value.length >= 8 || "A senha deve ter pelo menos 8 caracteres",
    },
  },
];

const CreateUserPage = () => {
  return (
    <DefaultContentPublic title="Cadastro de Usuário">
      {(navigate, setLoading, setErrors) => {
        const onSubmit = async (data: any) => {
          setLoading(true);

          try {
            if (data.senha !== data.confirma_senha) {
              throw new GenerateError("Senhas não são iguais.");
            }

            const result = await new UserAPI().create(data);

            if (!result.success) {
              throw new GenerateError(result.message);
            }

            navigate(
              `/?header=${encodeURIComponent(
                `Usuário criado`
              )}&variant=${encodeURIComponent(
                `success`
              )}&message=${encodeURIComponent(
                `Foi enviado um e-mail de verificação para ${data.email}. Favor entrar no e-mail cadastrado para fazer a ativação da sua conta.`
              )}`
            );
          } catch (error: any) {
            if (error instanceof GenerateError) {
              setErrors([error.returnAlert()]);
            } else {
              alert(error.message);
            }
          } finally {
            setLoading(false);
          }
        };

        return (
          <FormComponent
            fields={fields}
            onSubmit={onSubmit}
            submitName="Cadastrar"
            resetButton={true}
            gobackButton={true}
          />
        );
      }}
    </DefaultContentPublic>
  );
};

export default CreateUserPage;
