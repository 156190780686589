export type IKeys = "authToken";

class StorageItems {
  get(key: IKeys) {
    const item = localStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    } else {
      return null;
    }
  }

  set(key: IKeys, data: any) {
    if (typeof window !== "undefined") {
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  remove(key: IKeys) {
    if (typeof window !== "undefined") {
      localStorage.removeItem(key);
    }
  }

  clear() {
    if (typeof window !== "undefined") {
      localStorage.clear();
    }
  }
}

export default StorageItems;
