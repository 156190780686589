import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.js";

import ReactDOM from "react-dom/client";
import { AlertProvider } from "./context/alerts";
import { AuthProvider } from "./context/auth";
import "./index.css";
import Router from "./routes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <AuthProvider>
    <AlertProvider>
      <Router />
    </AlertProvider>
  </AuthProvider>
);
