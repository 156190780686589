import { ChangeEvent, useRef } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { ImpostosAttributes } from "../../../../../libs/types/models/sulo";
import { IFGTSArquivos } from "../../../pages/private/lancamentos/fgts";
import ListFunctions from "../../../utils/functions/list";
import FGTSPixLancsItem from "./subitem";

interface IProps {
  impostos: ImpostosAttributes[];
  data: IFGTSArquivos;
  setData: React.Dispatch<React.SetStateAction<IFGTSArquivos>>;
  onSubmit: () => void;
}

const FGTSPixLancs: React.FC<IProps> = ({
  impostos,
  data,
  setData,
  onSubmit,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setData({ ...data, files: event.target.files });
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const getItems = () => {
    const elements: React.JSX.Element[] = [];

    if (data.files) {
      for (let index = 0; index < data.files.length; index++) {
        const element = data.files[index];
        elements.push(<FGTSPixLancsItem index__item={index} item={element} />);
      }
    }

    return elements;
  };

  return (
    <Container fluid className="my-3">
      <Row className="mt-3">
        <Col>
          <Form.Label>Impsoto</Form.Label>
          <Select
            placeholder="Selecione o Imposto"
            options={new ListFunctions().selectOption(
              impostos,
              "id_rv2_cod_imp",
              ["st_rv2_nome_imp", "st_rv2_codretencao_imp"]
            )}
            value={
              data && data.imposto && data.imposto.id_rv2_cod_imp
                ? new ListFunctions().selectOption(
                    impostos.filter(
                      (item) =>
                        item.id_rv2_cod_imp === data.imposto?.id_rv2_cod_imp
                    ),
                    "id_rv2_cod_imp",
                    ["st_rv2_nome_imp", "st_rv2_codretencao_imp"]
                  )
                : undefined
            }
            onChange={(e) => {
              if (e) {
                const imposto = impostos.find(
                  (i) => i.id_rv2_cod_imp.toString() === e.value
                );

                if (imposto)
                  setData({
                    ...data,
                    imposto,
                  });
              }
            }}
          />
        </Col>

        <Col>
          <Form.Label>Referência:</Form.Label>
          <Form.Control
            placeholder="MM/AAAA"
            value={data?.referencia}
            onChange={(e) =>
              setData({
                ...data,
                referencia: e.target.value.toUpperCase(),
              })
            }
          />
        </Col>
      </Row>

      <Form.Group controlId="formFile" style={{ display: "none" }}>
        <Form.Label>Default file input example</Form.Label>
        <Form.Control
          type="file"
          multiple
          ref={fileInputRef}
          onChange={handleFileChange}
        />
      </Form.Group>

      <Row className="mt-3">
        <div className="d-flex align-items-center justify-content-between my-2">
          <h3>Arquivos {data.files && `[${data.files.length}]`}</h3>

          <div>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={handleButtonClick}
            >
              Selecionar Arquivos
            </Button>
          </div>
        </div>

        {data.files && data.files.length > 0 && (
          <div>
            <table className="despesas__table">
              <thead>
                <tr>
                  <th className="align-middle text-center">#</th>
                  <th className="align-middle text-center">Arquivo</th>
                </tr>
              </thead>
              <tbody>{getItems()}</tbody>
            </table>
          </div>
        )}
      </Row>

      <div className="d-flex justify-content-end">
        <Button
          className="mt-3"
          variant="success"
          onClick={onSubmit}
          disabled={
            !data.files ||
            data.files.length === 0 ||
            !data.imposto ||
            data.referencia === ""
          }
        >
          Enviar
        </Button>
      </div>
    </Container>
  );
};

export default FGTSPixLancs;
