import { FC, useEffect, useRef, useState } from "react";
import { Badge, Form, Overlay, Popover } from "react-bootstrap";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { IoMdAttach, IoMdCloseCircleOutline } from "react-icons/io";
import {
  MdArrowCircleLeft,
  MdChangeCircle,
  MdEdit,
  MdOutlineArrowCircleLeft,
  MdOutlineChangeCircle,
  MdOutlineEdit,
} from "react-icons/md";
import { TbAlertOctagon, TbAlertTriangle, TbCircleCheck } from "react-icons/tb";
import { pdfjs } from "react-pdf";
import { ILancamento } from "..";
import { ImpostosAttributes } from "../../../../../../../../../libs/types/models/sulo";
import MaskFunctions from "../../../../../../../utils/functions/masks";
import moment from "../../../../../../../utils/moment";
import numeral from "../../../../../../../utils/numeral";
import { FORMA_PAGAMENTO } from "../../base";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface IProps {
  index__item: number;
  item: ILancamento;
  disabled: boolean;
  checkedChange: (lancamento: ILancamento) => void;
  onAttachClick: (id?: number, name?: string) => void;
  onChangeStatus: (item: ILancamento[]) => void;
  onEditClick: (item: ILancamento) => void;
  restoreLancmento: (item: ILancamento) => void;
  impostos: ImpostosAttributes[] | null;
}

const LancamentoItem: FC<IProps> = ({
  index__item,
  item,
  disabled,
  checkedChange,
  onAttachClick,
  onChangeStatus,
  onEditClick,
  restoreLancmento,
  impostos,
}) => {
  const target = useRef(null);
  const [show, setShow] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [restoreLancamento, setRestoreLancmento] = useState(false);

  useEffect(() => {
    if (item.errors.filter((i) => i.type === "danger").length > 0) {
      setHasErrors(true);
    } else {
      setHasErrors(false);
    }
  }, [item]);

  const value = item.lancamento.vl_fl_nfe
    ? numeral(item.lancamento.vl_fl_nfe.toString().replace(".", ",")).format(
        "0,0.00"
      )
    : 0;

  const getTips = () => {
    if (item.errors.length > 0) {
      return (
        <>
          <Overlay show={show} target={target.current} placement="right">
            {(props) => (
              <Popover className="table__tooltip" {...props}>
                <Popover.Header
                  as="h3"
                  className={
                    item.errors.filter((i) => i.type === "danger").length > 0
                      ? "table__tooltip__header__error"
                      : "table__tooltip__header__alert"
                  }
                >
                  {item.errors.filter((i) => i.type === "danger").length > 0
                    ? `Lançamento com Erro${
                        item.errors.filter((i) => i.type === "danger").length >
                        1
                          ? "s"
                          : ""
                      }`
                    : item.errors.filter((i) => i.type === "warning").length > 0
                    ? `Lançamento com Alerta${
                        item.errors.filter((i) => i.type === "warning").length >
                        1
                          ? "s"
                          : ""
                      }`
                    : ""}
                </Popover.Header>
                <Popover.Body className="table__tooltip__body">
                  {item.errors.filter((i) => i.type === "danger").length >
                    0 && (
                    <div>
                      <ul>
                        {item.errors
                          .filter((i) => i.type === "danger")
                          .map((error, index) => {
                            return (
                              <li key={`error__${index}`}>{error.message}</li>
                            );
                          })}
                      </ul>
                    </div>
                  )}

                  {item.errors.filter((i) => i.type === "danger").length > 0 &&
                    item.errors.filter((i) => i.type === "warning").length >
                      0 && <hr />}

                  {item.errors.filter((i) => i.type === "warning").length >
                    0 && (
                    <div>
                      {item.errors.filter((i) => i.type === "danger").length >
                        0 && (
                        <h6>
                          Alerta
                          {item.errors.filter((i) => i.type === "danger")
                            .length > 1
                            ? "s"
                            : ""}
                        </h6>
                      )}
                      <ul>
                        {item.errors
                          .filter((i) => i.type === "warning")
                          .map((error, index) => {
                            return (
                              <li key={`error__${index}`}>{error.message}</li>
                            );
                          })}
                      </ul>
                    </div>
                  )}
                </Popover.Body>
              </Popover>
            )}
          </Overlay>
          <div ref={target}>
            <div>
              {item.errors.filter((i) => i.type === "danger").length > 0 ? (
                <TbAlertTriangle
                  style={{ cursor: "help" }}
                  color={"red"}
                  size={20}
                  onMouseOver={() => setShow(true)}
                  onMouseLeave={() => setShow(false)}
                />
              ) : (
                <TbAlertOctagon
                  style={{ cursor: "help" }}
                  color={"#B51B75"}
                  size={20}
                  onMouseOver={() => setShow(true)}
                  onMouseLeave={() => setShow(false)}
                />
              )}
            </div>

            <div>
              <Badge pill bg="danger">
                {item.errors.filter((i) => i.type === "danger").length}
              </Badge>
            </div>
            <div>
              <Badge pill bg="warning" text="dark">
                {item.errors.filter((i) => i.type === "warning").length}
              </Badge>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div ref={target}>
          <TbCircleCheck
            color="green"
            size={20}
            onMouseOver={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
          />
        </div>
      );
    }
  };

  const getNone = () => {
    return (
      <div className="text-center">
        <IoMdCloseCircleOutline size={20} color="red" />
      </div>
    );
  };

  const getForma = () => {
    const forma = FORMA_PAGAMENTO.find(
      (forma) => forma.value === item.lancamento.id_forma_pag
    )?.label;

    return forma ? <span>{forma}</span> : <span>""</span>;
  };

  const getApropriacoes = () => {
    if (item.lancamento.LancamentoApropriacaos)
      if (item.lancamento.LancamentoApropriacaos.length > 0) {
        return item.lancamento.LancamentoApropriacaos.map((i, index) => {
          const valueApro = i.vl_valor_pdes
            ? numeral(i.vl_valor_pdes.toString().replace(".", ",")).format(
                "0,0.00"
              )
            : 0;

          const percent =
            typeof valueApro !== "number" && typeof value !== "number"
              ? numeral(parseFloat(valueApro) / parseFloat(value)).format(
                  "0.00%"
                )
              : 0;

          return (
            <div key={`apropriacao__${index}`}>
              <small>
                {`${i.st_conta_cont} [${i.st_descricao_cont}] - ${valueApro} [
          ${percent}]`}
              </small>
            </div>
          );
        });
      } else {
        return getNone();
      }
    else {
      return getNone();
    }
  };

  const getRetencoes = () => {
    if (impostos) {
      if (item.lancamento.LancamentoRetencaos)
        if (item.lancamento.LancamentoRetencaos.length > 0) {
          return item.lancamento.LancamentoRetencaos.map((i, index) => {
            const imposto = impostos.find(
              (c) => c.id_rv2_cod_imp === i.id_rv2_imposto_des
            );

            const percent =
              imposto && imposto.nm_rv2_aliquota_imp
                ? numeral(
                    (imposto.nm_rv2_aliquota_imp / 100)
                      .toString()
                      .replace(".", ",")
                  ).format("0.00%")
                : "";
            return (
              <div key={`imposto__${index}`}>
                <small>{`${imposto?.st_rv2_nome_imp} [${percent}]`}</small>
              </div>
            );
          });
        } else {
          return <AiOutlineExclamationCircle size={19} color="black" />;
        }
      else {
        return <AiOutlineExclamationCircle size={19} color="black" />;
      }
    }
  };

  return (
    <tr
      key={`row__forn__${index__item}`}
      className={
        disabled
          ? "disabled__line disabled__mouse"
          : hasErrors
          ? "alert__line"
          : ""
      }
    >
      <td className="align-middle text-center">{index__item + 1}</td>

      <td className="align-middle text-center">
        {item.lancamento.id_fl_lancamento}
      </td>

      <td
        className={`align-middle text-center ${
          hasErrors ? "disabled__mouse" : ""
        }`}
      >
        <Form.Check
          type="checkbox"
          disabled={disabled || hasErrors}
          checked={disabled || hasErrors ? false : item.checked}
          onChange={() => checkedChange(item)}
        />
      </td>

      <td className="align-middle text-center">{getTips()}</td>

      <td className="align-middle text-center">
        {item.lancamento.st_documento_des}
      </td>

      <td className="align-middle text-center">
        <IoMdAttach
          title="Visualiar Documento"
          className="button__icon"
          size={15}
          onClick={() =>
            onAttachClick(
              item.lancamento.NFe?.id_ndm_nfes,
              item.lancamento.NFe?.arquivo_pdf
            )
          }
        />
      </td>

      <td className="align-middle text-center">
        <div
          className="button__icon"
          onMouseEnter={() => setChangeStatus(true)}
          onMouseLeave={() => setChangeStatus(false)}
        >
          {changeStatus ? (
            <MdChangeCircle
              size={18}
              title="Alterar Situação"
              onClick={() => onChangeStatus([item])}
            />
          ) : (
            <MdOutlineChangeCircle size={18} />
          )}
        </div>
      </td>

      <td className="align-middle text-center">
        <div
          className="button__icon"
          onMouseEnter={() => setRestoreLancmento(true)}
          onMouseLeave={() => setRestoreLancmento(false)}
        >
          {restoreLancamento ? (
            <MdArrowCircleLeft
              size={18}
              title="Restaurar Lançamento"
              //onClick={() => restoreLancmento(item)}
            />
          ) : (
            <MdOutlineArrowCircleLeft size={18} />
          )}
        </div>
      </td>

      <td className="align-middle text-center">
        {!disabled && (
          <div
            className="button__icon"
            onMouseEnter={() => setEditItem(true)}
            onMouseLeave={() => setEditItem(false)}
          >
            {editItem ? (
              <MdEdit
                size={18}
                title="Editar Lançamento"
                onClick={() => onEditClick(item)}
              />
            ) : (
              <MdOutlineEdit size={18} />
            )}
          </div>
        )}
      </td>
      <td className="align-middle text-center">
        {moment(item.lancamento.dt_despesa_des, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        )}{" "}
      </td>
      <td className="align-middle text-center">
        {item.lancamento.dt_vencimentoprimeiraparcela
          ? moment(
              item.lancamento.dt_vencimentoprimeiraparcela,
              "YYYY-MM-DD"
            ).format("DD/MM/YYYY")
          : getNone()}
      </td>
      <td className="align-middle text-end">{value}</td>
      <td className="align-middle">
        {item.lancamento.st_nome_con &&
        item.lancamento.Fornecedor &&
        item.lancamento.Fornecedor.st_cpf_con
          ? `${
              item.lancamento.Fornecedor.st_nome_con
            } [${new MaskFunctions().cnpjMask(
              item.lancamento.Fornecedor.st_cpf_con
            )}]`
          : getNone()}
      </td>
      <td className="align-middle">
        {item.lancamento.Condominio
          ? item.lancamento.Condominio.st_nome_cond
          : getNone()}
      </td>
      <td className="align-middle text-center">{getForma()}</td>
      <td className="align-middle text-start">{getApropriacoes()}</td>
      <td className="align-middle text-center">{getRetencoes()}</td>
    </tr>
  );
};

export default LancamentoItem;
