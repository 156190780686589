import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  RiSortAlphabetAsc,
  RiSortAlphabetDesc,
  RiSortNumberAsc,
  RiSortNumberDesc,
} from "react-icons/ri";
import Select from "react-select";
import {
  PlanoAttributes,
  PlanoCondominioAttributes,
} from "../../../../../libs/types/models/sulo";
import {
  ICondomedArquivos,
  ICondomedDespesas,
} from "../../../pages/private/lancamentos/condomed";
import ListFunctions from "../../../utils/functions/list";
import SortFunctions from "../../../utils/functions/sorts";
import CondomedBaseFilters from "./baseFiles";
import CondomedBaseResults from "./baseResults";
import CondomedFilesItem from "./subitem/files";
import CondomedLancsItem from "./subitem/lancs";

interface IProps {
  planosCondominio: PlanoCondominioAttributes[];
  data: ICondomedArquivos;
  setData: React.Dispatch<React.SetStateAction<ICondomedArquivos>>;
  dataDespesas?: ICondomedDespesas[];
  setDataDespesas: React.Dispatch<
    React.SetStateAction<ICondomedDespesas[] | undefined>
  >;
  planos: PlanoAttributes[];
  loadPlanos: (id?: string) => void;
  onSubmit: () => void;
  onSubmitDespesas: () => void;
}

const iconSortSize = 20;

interface Options {
  value: string;
  label: string;
  st_nome: string;
}

interface IColumn {
  id: number;
  class: string;
  name: string;
  canOrder: boolean;
  orderAsc?: boolean;
  colSpan: number;
  orderType: string;
  name_column?: string;
  funcAsc?: (a: ICondomedDespesas, b: ICondomedDespesas) => any;
  funcDesc?: (a: ICondomedDespesas, b: ICondomedDespesas) => any;
}

const columns: IColumn[] = [
  {
    id: 0,
    class: "text-center",
    name: "#",
    canOrder: false,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "",
    name_column: undefined,
  },
  {
    id: 1,
    class: "text-center",
    name: "Incluir",
    canOrder: false,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "",
    name_column: undefined,
  },
  {
    id: 10,
    class: "text-center order__button",
    name: "Erro(s)",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "string",
    name_column: "errors",
    funcAsc: (a, b) => new SortFunctions(a.errors, b.errors).sortStrAsc(),
    funcDesc: (a, b) => new SortFunctions(a.errors, b.errors).sortStrDesc(),
  },

  {
    id: 2,
    class: "text-center order__button",
    name: "Benef.",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "string",
    name_column: "type",
    funcAsc: (a, b) => new SortFunctions(a.type, b.type).sortStrAsc(),
    funcDesc: (a, b) => new SortFunctions(a.type, b.type).sortStrDesc(),
  },
  {
    id: 3,
    class: "text-center order__button",
    name: "Valor",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "number",
    name_column: "VL_VALOR_PDES",
    funcAsc: (a, b) =>
      new SortFunctions(a.VL_VALOR_PDES, b.VL_VALOR_PDES).sortNumberAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(a.VL_VALOR_PDES, b.VL_VALOR_PDES).sortNumberDesc(),
  },
  {
    id: 4,
    class: "text-center order__button",
    name: "Dt. Vencto.",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "date",
    name_column: "DT_VENCIMENTOPRIMEIRAPARCELA",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.DT_VENCIMENTOPRIMEIRAPARCELA,
        b.DT_VENCIMENTOPRIMEIRAPARCELA
      ).sortDateAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.DT_VENCIMENTOPRIMEIRAPARCELA,
        b.DT_VENCIMENTOPRIMEIRAPARCELA
      ).sortDateDesc(),
  },
  {
    id: 5,
    class: "text-center order__button",
    name: "Dr. Despesa",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "number",
    name_column: "DT_DESPESA_DES",
    funcAsc: (a, b) =>
      new SortFunctions(a.DT_DESPESA_DES, b.DT_DESPESA_DES).sortDateAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(a.DT_DESPESA_DES, b.DT_DESPESA_DES).sortDateDesc(),
  },
  {
    id: 6,
    class: "text-center order__button",
    name: "NFe",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "number",
    name_column: "ST_DOCUMENTO_DES",
    funcAsc: (a, b) =>
      new SortFunctions(a.ST_DOCUMENTO_DES, b.ST_DOCUMENTO_DES).sortNumberAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.ST_DOCUMENTO_DES,
        b.ST_DOCUMENTO_DES
      ).sortNumberDesc(),
  },
  {
    id: 7,
    class: "text-center order__button",
    name: "Condomínio",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "string",
    name_column: "ST_NOME_COND",
    funcAsc: (a, b) =>
      new SortFunctions(a.ST_NOME_COND, b.ST_NOME_COND).sortStrAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(a.ST_NOME_COND, b.ST_NOME_COND).sortStrDesc(),
  },
  {
    id: 8,
    class: "text-center order__button",
    name: "Fornecedor",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "string",
    name_column: "ST_NOME_CON",
    funcAsc: (a, b) =>
      new SortFunctions(a.ST_NOME_CON, b.ST_NOME_CON).sortStrAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(a.ST_NOME_CON, b.ST_NOME_CON).sortStrDesc(),
  },
  {
    id: 9,
    class: "text-center order__button",
    name: "Código de Barras",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "string",
    name_column: "ST_CODIGOBARRAS_PDES",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.ST_CODIGOBARRAS_PDES,
        b.ST_CODIGOBARRAS_PDES
      ).sortStrAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.ST_CODIGOBARRAS_PDES,
        b.ST_CODIGOBARRAS_PDES
      ).sortStrDesc(),
  },
];

const CondomedLancs: React.FC<IProps> = ({
  planosCondominio,
  data,
  setData,
  onSubmit,
  onSubmitDespesas,
  loadPlanos,
  planos,
  dataDespesas,
  setDataDespesas,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [renderColumns, setRenderColumns] = useState<IColumn[]>(columns);

  console.log(data);

  useEffect(() => {
    if (dataDespesas) {
      const order = renderColumns.find((i) => i.orderAsc !== undefined);
      if (order) {
        let despesasSort: ICondomedDespesas[] = [...dataDespesas];
        despesasSort.sort((a, b) => {
          if (order.orderAsc) {
            return order.funcAsc ? order.funcAsc(a, b) : 0;
          } else {
            return order.funcDesc ? order.funcDesc(a, b) : 0;
          }
        });
        setDataDespesas(despesasSort);
      }
    }
  }, [renderColumns]);

  const orderColumns = (column: IColumn) => {
    const newOrder: IColumn[] = renderColumns.map((c) => {
      var order: IColumn = {
        ...c,
        orderAsc: undefined,
      };

      if (column.id === c.id) {
        if (c.orderAsc === undefined) {
          order.orderAsc = true;
        } else {
          order.orderAsc = !c.orderAsc;
        }
      }

      return order;
    });

    setRenderColumns(newOrder);
  };

  const getIconSort = (column: IColumn) => {
    if (column.canOrder) {
      if (column.orderType === "string") {
        if (column.orderAsc === true) {
          return (
            <div className="mx-1">
              <RiSortAlphabetAsc size={iconSortSize} />
            </div>
          );
        } else if (column.orderAsc === false) {
          return (
            <div className="mx-1">
              <RiSortAlphabetDesc size={iconSortSize} />
            </div>
          );
        }
      } else {
        if (column.orderAsc === true) {
          return (
            <div className="mx-1">
              <RiSortNumberAsc size={iconSortSize} />
            </div>
          );
        } else if (column.orderAsc === false) {
          return (
            <div className="mx-1">
              <RiSortNumberDesc size={iconSortSize} />
            </div>
          );
        }
      }
    }

    return null;
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    var files: {
      file: File;
      type_file: string;
      new_file_name: string;
    }[] = [];

    if (event.target.files) {
      for (let index = 0; index < event.target.files.length; index++) {
        files.push({
          file: event.target.files[index],
          type_file: "",
          new_file_name: "",
        });
      }
      setData({ ...data, files });
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onUpdateItem = (
    item: {
      file: File;
      type_file: string;
      new_file_name: string;
    },
    index: number
  ) => {
    if (data.files) {
      const items = data.files;
      var new_file_name = "";
      if (item.type_file !== "")
        new_file_name = `${item.type_file.toUpperCase()}.${item.file.name
          .split(".")
          .pop()}`;

      items[index] = { ...item, new_file_name };

      setData({ ...data, files: items });
    }
  };

  const checkedAll = () => {
    if (dataDespesas) {
      const despesas: ICondomedDespesas[] = dataDespesas.map(
        (item: ICondomedDespesas) => {
          return { ...item, checked: true };
        }
      );
      setDataDespesas(despesas);
    }
  };

  const uncheckedAll = () => {
    if (dataDespesas) {
      const despesas: ICondomedDespesas[] = dataDespesas.map(
        (item: ICondomedDespesas) => {
          return { ...item, checked: false };
        }
      );
      setDataDespesas(despesas);
    }
  };

  const checkedChange = (despesa: ICondomedDespesas) => {
    if (dataDespesas) {
      const newDespesas = [...dataDespesas];
      const index = newDespesas.findIndex(
        (item) =>
          item.type === despesa.type &&
          item.ID_CONDOMINIO_COND === despesa.ID_CONDOMINIO_COND &&
          item.ST_DOCUMENTO_DES === despesa.ST_DOCUMENTO_DES
      );
      newDespesas[index] = {
        ...newDespesas[index],
        checked: !newDespesas[index].checked,
      };
      setDataDespesas(newDespesas);
    }
  };

  return (
    <div className="mt-5">
      <CondomedBaseFilters
        onClean={() =>
          setData({
            ...data,
            files: undefined,
            plano: undefined,
            planoCondominio: undefined,
          })
        }
        onSubmit={onSubmit}
        title="Arquivos"
        data={data}
        closeAccordion={dataDespesas ? "1" : "0"}
      >
        <Row className="mt-2">
          <Col>
            <Form.Label>Referência:</Form.Label>
            <Form.Control
              placeholder="MM/AA"
              maxLength={5}
              value={data?.referencia}
              onChange={(e) =>
                setData({
                  ...data,
                  referencia: e.target.value.toUpperCase(),
                })
              }
            />
          </Col>

          <Col>
            <Form.Label>Plano Padrão:</Form.Label>
            <Select
              placeholder="Selecione o Plano Modelo"
              options={new ListFunctions().selectOption(
                planosCondominio,
                "id_planoconta_plc",
                ["st_nome_plc"]
              )}
              onChange={(e) => {
                loadPlanos(e?.value);
              }}
              value={new ListFunctions().selectOption(
                planosCondominio.filter(
                  (item) =>
                    item.id_planoconta_plc ===
                    data?.planoCondominio?.id_planoconta_plc
                ),
                "id_planoconta_plc",
                ["st_nome_plc"]
              )}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Form.Label>Apropriação:</Form.Label>
            <Select
              isDisabled={planos.length === 0}
              placeholder="Selecione a Apropriação..."
              options={new ListFunctions().selectOption(planos, "id_sl_plano", [
                "st_conta_cont",
                "st_descricao_cont",
              ])}
              onChange={(e) => {
                const plano = planos.find(
                  (item) => item.id_sl_plano.toString() === e?.value
                );

                setData({
                  ...data,
                  plano: plano,
                });
              }}
              value={new ListFunctions().selectOption(
                planos.filter(
                  (item) => item.id_sl_plano === data.plano?.id_sl_plano
                ),
                "id_sl_plano",
                ["st_conta_cont", "st_descricao_cont"]
              )}
            />
          </Col>
        </Row>

        <Form.Group controlId="formFile" style={{ display: "none" }}>
          <Form.Label>Files</Form.Label>
          <Form.Control
            type="file"
            multiple
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </Form.Group>

        <Row className="mt-3">
          <div className="d-flex align-items-center justify-content-between my-2">
            <h3>Arquivos {data.files && `[${data.files.length}]`}</h3>

            <div>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={handleButtonClick}
              >
                Selecionar Arquivos
              </Button>
            </div>
          </div>

          {data.files && data.files.length > 0 && (
            <div>
              <table className="despesas__table">
                <thead>
                  <tr>
                    <th className="align-middle text-center">#</th>
                    <th className="align-middle text-center">Arquivo</th>
                    <th className="align-middle text-center">
                      Tipo de Arquivo
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.files.map((item, index) => {
                    return (
                      <CondomedFilesItem
                        index__item={index}
                        item={item}
                        onUpdateItem={onUpdateItem}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </Row>
      </CondomedBaseFilters>

      {dataDespesas && (
        <CondomedBaseResults
          data={dataDespesas}
          checkedAll={checkedAll}
          uncheckedAll={uncheckedAll}
          onSubmit={onSubmitDespesas}
        >
          <table className="despesas__table">
            <thead>
              <tr>
                {renderColumns.map((column) => {
                  return (
                    <th
                      key={`column__lancs__${column.id.toString()}`}
                      className={column.class}
                      colSpan={column.colSpan}
                      onClick={() => {
                        if (column.canOrder) orderColumns(column);
                      }}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        {column.name}

                        {getIconSort(column)}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {dataDespesas.map((item, index) => {
                return (
                  <CondomedLancsItem
                    key={`despesa__item__${index}`}
                    index__item={index}
                    item={item}
                    checkedChange={checkedChange}
                  />
                );
              })}
            </tbody>
          </table>
        </CondomedBaseResults>
      )}
    </div>
  );
};

export default CondomedLancs;
