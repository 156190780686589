import SuperlogicaAPI from "../../../../../services/api/sulo";
import moment from "../../../../../utils/moment";
import numeral from "../../../../../utils/numeral";

class ModalFormTools {
  getValue = (value?: any) => {
    return value
      ? numeral(value.toString().replace(".", ",")).format("#,000.00")
      : "";
  };

  getSumValue = (value?: any[]) => {
    var total = 0;

    value?.map((v) => {
      if (v) {
        total += parseFloat(v);
      }
    });
    return numeral(total.toString().replace(".", ",")).format("#,000.00");
  };

  getPercent = (value?: any) => {
    return value
      ? numeral(value.toString().replace(".", ",")).format("0.00 %")
      : "";
  };

  getDate = (value?: any) => {
    return value ? moment(value, "YYYY-MM-DD").format("DD/MM/YYYY") : "";
  };

  // getPagamento = (
  //   list: { value: number; label: string }[],
  //   item: LancamentoAttributes
  // ) => {
  //   if (item.id_forma_pag) {
  //     const index = list.findIndex((i) => i.value === item.id_forma_pag);

  //     if (index > -1) {
  //       return list[index];
  //     } else {
  //       return undefined;
  //     }
  //   }
  //   return undefined;
  // };

  getPagamentoElement = async (
    list: { value: number; label: string }[],
    id?: number
  ) => {
    console.log("aqui");

    if (id) {
      const forma = list.find((item) => item.value === id);

      if (forma) {
        if (forma.label === "Pix") {
          const options = await new SuperlogicaAPI().listFavorecidoPix(id);
          console.log("options", options);
        }
      }
    } else {
      return null;
    }
    return null;
  };
}

export default ModalFormTools;
