import { v4 as uuidv4 } from "uuid";
import { tVariants } from "../../../../../../../../libs/types/messages";
import { LancamentoAttributes } from "../../../../../../../../libs/types/models/sufl";
import moment from "../../../../../../utils/moment";
import numeral from "../../../../../../utils/numeral";

export interface ResumedLancsErrors {
  key: string;
  message: string;
  type: tVariants;
  priority?: number;
}

const checkNfeResumed = (
  lancamento: LancamentoAttributes,
  newErrors: ResumedLancsErrors[],
  pisID: number,
  inssID: number,
  issID: number,
  irID: number
) => {
  if (!lancamento.dt_vencimentoprimeiraparcela) {
    newErrors.push({
      key: uuidv4().toString(),
      message: "Data de vencimento da NF/pagamento, não informada.",
      type: "danger",
      priority: 1,
    });
  } else if (
    moment(lancamento.dt_vencimentoprimeiraparcela, "YYYY-MM-DD").isSame(
      moment(),
      "dates"
    )
  ) {
    newErrors.push({
      key: uuidv4().toString(),
      message: "Atenção, DATA DE PAGAMENTO PARA HOJE.",
      type: "warning",
      priority: 1,
    });
  } else if (
    moment(lancamento.dt_vencimentoprimeiraparcela, "YYYY-MM-DD").isBefore(
      moment(),
      "dates"
    )
  ) {
    newErrors.push({
      key: uuidv4().toString(),
      message: "Data de pagamento já está vencida, por favor, verifique.",
      type: "warning",
      priority: 1,
    });
  }

  if (lancamento.nm_parcelas !== undefined && lancamento.nm_parcelas > 1) {
    newErrors.push({
      key: uuidv4().toString(),
      message:
        "Este lançamento informa possuir PARCELAS. Por favor, verifique.",
      type: "warning",
      priority: 1,
    });
  }

  return newErrors;
};

const checkFornecedorResumed = (
  lancamento: LancamentoAttributes,
  newErrors: ResumedLancsErrors[],
  pisID: number,
  inssID: number,
  issID: number,
  irID: number
) => {
  //FORNECEDOR NULL
  if (!lancamento.id_contato_con && !lancamento.Fornecedor) {
    newErrors.push({
      key: uuidv4().toString(),
      message: "Fornecedor não cadastrado na Superlógica.",
      type: "danger",
      priority: 1,
    });
  }

  //FORMA NULL
  if (
    lancamento.id_forma_pag === null ||
    lancamento.id_forma_pag === undefined
  ) {
    newErrors.push({
      key: uuidv4().toString(),
      message: "Forma de pagamento não selecionada.",
      type: "danger",
      priority: 1,
    });
  } else {
    if (["12"].includes(lancamento.id_forma_pag.toFixed())) {
      if (
        !lancamento.LancamentoFavorecidoPixes ||
        lancamento.LancamentoFavorecidoPixes.length === 0
      ) {
        newErrors.push({
          key: uuidv4().toString(),
          message: "Dados do pix ausentes.",
          type: "warning",
          priority: 1,
        });
      } else {
        if (!lancamento.LancamentoFavorecidoPixes[0].fl_tipochave_pix) {
          newErrors.push({
            key: uuidv4().toString(),
            message: "Tipo de chave do pix não informada.",
            type: "danger",
            priority: 2,
          });
        }

        if (!lancamento.LancamentoFavorecidoPixes[0].st_chave_pix) {
          newErrors.push({
            key: uuidv4().toString(),
            message: "Chave do pix não informada.",
            type: "danger",
            priority: 2,
          });
        }
      }
    }
    //DADOS PAGTO - Dinheiro / Cheque
    else if (["1", "2"].includes(lancamento.id_forma_pag.toFixed())) {
      if (!lancamento.id_favorecido_con || !lancamento.st_nomerecebedor_fav) {
        newErrors.push({
          key: uuidv4().toString(),
          message: "Dados do favorecido ausentes.",
          type: "warning",
          priority: 3,
        });
      } else {
        newErrors.push({
          key: uuidv4().toString(),
          message: "Dados de pagamento ausentes.",
          type: "warning",
          priority: 3,
        });
      }
    }
    //DADOS PAGTO - Transf/Doc
    else if (["8", "9"].includes(lancamento.id_forma_pag.toFixed())) {
      if (!lancamento.id_favorecido_con || !lancamento.st_nomerecebedor_fav) {
        newErrors.push({
          key: uuidv4().toString(),
          message: "Dados do favorecido ausentes.",
          type: "danger",
          priority: 2,
        });
      }

      if (!lancamento.id_favorecido_fav) {
        newErrors.push({
          key: uuidv4().toString(),
          message: "Dados de pagamento ausentes.",
          type: "danger",
          priority: 2,
        });
      }
    }
  }

  return newErrors;
};

const checkApropriacoesResumed = (
  lancamento: LancamentoAttributes,
  newErrors: ResumedLancsErrors[],
  pisID: number,
  inssID: number,
  issID: number,
  irID: number
) => {
  if (
    !lancamento.LancamentoApropriacaos ||
    lancamento.LancamentoApropriacaos.length === 0
  ) {
    newErrors.push({
      key: uuidv4().toString(),
      message: "Apropriação não informada.",
      type: "danger",
      priority: 2,
    });
  } else {
    var sum = 0;
    lancamento.LancamentoApropriacaos.map((item) => {
      const val = item.vl_valor_pdes ? item.vl_valor_pdes.toString() : "0";
      sum += parseFloat(val);
      return null;
    });

    if (sum != lancamento.vl_fl_nfe) {
      newErrors.push({
        key: uuidv4().toString(),
        message: "Totalização da(s) apropriação(ões) divergente(s).",
        type: "danger",
        priority: 2,
      });
    }

    lancamento.LancamentoApropriacaos.map((item) => {
      if (!item.st_nomegruposaldo_gs) {
        const val = item.vl_valor_pdes
          ? numeral(item.vl_valor_pdes.toString().replace(".", ",")).format(
              "$ #,##0.00"
            )
          : "0";

        newErrors.push({
          key: uuidv4().toString(),
          message: `O Grupo de Saldo da apropriação ${item.st_descricao_cont} no valor de ${val} não informado na(s) apropriação(ões).`,
          type: "danger",
          priority: 2,
        });
      }
    });
  }

  return newErrors;
};

const checkRetencoesResumed = (
  lancamento: LancamentoAttributes,
  newErrors: ResumedLancsErrors[],
  pisID: number,
  inssID: number,
  issID: number,
  irID: number
) => {
  if (lancamento.NFe) {
    //PIS
    if (lancamento.NFe.pis_pasep > 0) {
      if (
        !lancamento.LancamentoRetencaos ||
        lancamento.LancamentoRetencaos.length === 0
      ) {
        newErrors.push({
          key: uuidv4().toString(),
          message:
            "Retenção de PIS/COFINS/CSLL ausente, porém há informação de retenção na NF.",
          type: "warning",
          priority: 1,
        });
      } else {
        const pisRetencao = lancamento.LancamentoRetencaos.find(
          (item) => item.id_rv2_imposto_des === pisID
        );
        if (!pisRetencao) {
          newErrors.push({
            key: uuidv4().toString(),
            message:
              "Retenção de PIS/COFINS/CSLL ausente, porém há informação de retenção na NF.",
            type: "warning",
            priority: 1,
          });
        } else {
          if (!lancamento.id_natrend_des) {
            newErrors.push({
              key: uuidv4().toString(),
              message: "Natureza de rendimento não informada.",
              type: "danger",
              priority: 2,
            });
          }
        }
      }
    } else {
      if (lancamento.LancamentoRetencaos !== null) {
        if (lancamento.LancamentoRetencaos !== undefined) {
          if (lancamento.LancamentoRetencaos.length > 0) {
            const pisRetencao = lancamento.LancamentoRetencaos.find(
              (item) => item.id_rv2_imposto_des === pisID
            );
            if (pisRetencao) {
              if (!lancamento.id_natrend_des) {
                newErrors.push({
                  key: uuidv4().toString(),
                  message: "Natureza de rendimento não informada.",
                  type: "danger",
                  priority: 2,
                });
              }
            }
          }
        }
      }
    }

    //INSS
    if (lancamento.NFe.inss > 0) {
      if (
        !lancamento.LancamentoRetencaos ||
        lancamento.LancamentoRetencaos.length === 0
      ) {
        newErrors.push({
          key: uuidv4().toString(),
          message:
            "Retenção de INSS ausente, porém há informação de retenção na NF.",
          type: "warning",
          priority: 1,
        });
      } else {
        const inssRetencao = lancamento.LancamentoRetencaos.find(
          (item) => item.id_rv2_imposto_des === inssID
        );

        if (!inssRetencao) {
          newErrors.push({
            key: uuidv4().toString(),
            message:
              "Retenção de INSS ausente, porém há informação de retenção na NF.",
            type: "warning",
            priority: 1,
          });
        } else {
          if (!lancamento.id_classificacaotributaria_des) {
            newErrors.push({
              key: uuidv4().toString(),
              message: "Classificação Tributária não informada.",
              type: "danger",
              priority: 2,
            });
          }

          if (!lancamento.id_classservicoprestado_des) {
            newErrors.push({
              key: uuidv4().toString(),
              message: "Classificação do Serviço Prestado não informado.",
              type: "danger",
              priority: 2,
            });
          }
        }
      }

      newErrors.push({
        key: uuidv4().toString(),
        message:
          "Lançamento possui INSS, por favor confirir para certificar que não há abatimentos.",
        type: "warning",
        priority: 1,
      });
    } else {
      if (lancamento.LancamentoRetencaos !== null) {
        if (lancamento.LancamentoRetencaos !== undefined) {
          if (lancamento.LancamentoRetencaos.length > 0) {
            const inssRetencao = lancamento.LancamentoRetencaos.find(
              (item) => item.id_rv2_imposto_des === inssID
            );
            if (inssRetencao) {
              console.log(
                "lancamento.id_classificacaotributaria_des",
                lancamento.id_classificacaotributaria_des
              );
              if (!lancamento.id_classificacaotributaria_des) {
                newErrors.push({
                  key: uuidv4().toString(),
                  message: "Classificação Tributária não informada.",
                  type: "danger",
                  priority: 2,
                });
              }

              if (!lancamento.id_classservicoprestado_des) {
                newErrors.push({
                  key: uuidv4().toString(),
                  message: "Classificação do Serviço Prestado não informado.",
                  type: "danger",
                  priority: 2,
                });
              }
            }
          }
        }
      }
    }

    //ISS
    if (lancamento.NFe.iss_retido === true) {
      if (
        !lancamento.LancamentoRetencaos ||
        lancamento.LancamentoRetencaos.length === 0
      ) {
        newErrors.push({
          key: uuidv4().toString(),
          message:
            "Retenção de ISS ausente, porém há informação de retenção na NF.",
          type: "warning",
          priority: 1,
        });
      } else {
        const issRetencao = lancamento.LancamentoRetencaos.find(
          (item) => item.id_rv2_imposto_des === issID
        );

        if (!issRetencao) {
          newErrors.push({
            key: uuidv4().toString(),
            message:
              "Retenção de ISS ausente, porém há informação de retenção na NF.",
            type: "warning",
            priority: 1,
          });
        }
      }
    }

    //IR
    if (lancamento.NFe.ir > 0) {
      if (
        !lancamento.LancamentoRetencaos ||
        lancamento.LancamentoRetencaos.length === 0
      ) {
        newErrors.push({
          key: uuidv4().toString(),
          message:
            "Retenção de IR ausente, porém há informação de retenção na NF.",
          type: "warning",
          priority: 1,
        });
      } else {
        const irRetencao = lancamento.LancamentoRetencaos.find(
          (item) => item.id_rv2_imposto_des === irID
        );

        if (!irRetencao) {
          newErrors.push({
            key: uuidv4().toString(),
            message:
              "Retenção de IR ausente, porém há informação de retenção na NF.",
            type: "warning",
            priority: 1,
          });
        }
      }
    }
  }

  return newErrors;
};

const checkLancamentoResumed = (
  lancamento: LancamentoAttributes,
  pisID: number,
  inssID: number,
  issID: number,
  irID: number
) => {
  var newErrors: ResumedLancsErrors[] = [];

  newErrors = checkNfeResumed(
    lancamento,
    newErrors,
    pisID,
    inssID,
    issID,
    irID
  );
  newErrors = checkFornecedorResumed(
    lancamento,
    newErrors,
    pisID,
    inssID,
    issID,
    irID
  );
  newErrors = checkApropriacoesResumed(
    lancamento,
    newErrors,
    pisID,
    inssID,
    issID,
    irID
  );
  newErrors = checkRetencoesResumed(
    lancamento,
    newErrors,
    pisID,
    inssID,
    issID,
    irID
  );

  return newErrors;
};

export default checkLancamentoResumed;
