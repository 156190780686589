import axios, { AxiosRequestConfig } from "axios";
import API from "..";
import { IReturnHTTP } from "../../../../../libs/types/returns";
import {
  IBeneficiosRBArquivos,
  IBeneficiosRBDespesas,
} from "../../../pages/private/lancamentos/beneficiosRb";
import {
  ICondomedArquivos,
  ICondomedDespesas,
} from "../../../pages/private/lancamentos/condomed";
import { IFGTSArquivos } from "../../../pages/private/lancamentos/fgts";

class LancamentoAPI extends API {
  create = async (data: any): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${this.base_url}/user/create`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  list = async (): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${this.base_url}/lancamentos/list`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  getLancamento = async (id?: any): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${this.base_url}/lancamentos/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  updateStatus = async (data: any): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${this.base_url}/lancamentos/update/status`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
      data,
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  createFornecedores = async (data: any): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${this.base_url}/lancamentos/create/fornecedores`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
      data,
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  createDespesas = async (data: any): Promise<any> => {
    var config: AxiosRequestConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${this.base_url}/lancamentos/create/despesas`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
      data,
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  createFGTSPix = async (data: IFGTSArquivos) => {
    const formData = new FormData();

    if (data.files) {
      for (let index = 0; index < data.files.length; index++) {
        formData.append("files", data.files[index]);
      }

      formData.append(
        "data",
        JSON.stringify({ imposto: data.imposto, referente: data.referencia })
      );
    }

    var config: AxiosRequestConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${this.base_url}/lancamentos/create/fgts`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
      data: formData,
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  readFilesBeneficiosRB = async (data: IBeneficiosRBArquivos) => {
    const formData = new FormData();

    const rules: { prefix: string; type: string }[] = [];

    if (data.files) {
      data.files.map((item) => {
        if (
          rules.filter(
            (i) =>
              i.type.toLocaleLowerCase() ===
              item.type_benefit.toLocaleLowerCase()
          ).length === 0
        ) {
          rules.push({
            prefix: `_${item.type_benefit.toLocaleLowerCase()}_`,
            type: item.type_benefit.toUpperCase(),
          });
        }

        if (item.new_file_name !== "") {
          const newFile = new File([item.file], item.new_file_name, {
            type: item.file.type,
          });
          formData.append("files", newFile);
        } else {
          formData.append("files", item.file);
        }
      });
    }

    formData.append(
      "data",
      JSON.stringify({ ref: data.referencia.replace("/", ""), rules })
    );

    var config: AxiosRequestConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${this.base_url}/lancamentos/read/beneficios/rb`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
      data: formData,
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  createBeneficiosRB = async (data: IBeneficiosRBDespesas[]) => {
    var config: AxiosRequestConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${this.base_url}/lancamentos/create/beneficios/rb`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
      data,
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  readFilesCondomed = async (data: ICondomedArquivos) => {
    const formData = new FormData();

    if (data.files) {
      data.files.map((item) => {
        if (item.new_file_name !== "") {
          const newFile = new File([item.file], item.new_file_name, {
            type: item.file.type,
          });
          formData.append("files", newFile);
        } else {
          formData.append("files", item.file);
        }
      });
    }

    formData.append(
      "data",
      JSON.stringify({ ref: data.referencia.replace("/", "") })
    );

    var config: AxiosRequestConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${this.base_url}/lancamentos/read/servicos/condomed`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
      data: formData,
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  createCondomed = async (data: ICondomedDespesas[]) => {
    var config: AxiosRequestConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${this.base_url}/lancamentos/create/servicos/condomed`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
      data,
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };
}

export default LancamentoAPI;
