import { FC, useEffect, useState } from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { LancamentoAttributes } from "../../../../../../../../libs/types/models/sufl";
import ListFunctions from "../../../../../../utils/functions/list";
import MaskFunctions from "../../../../../../utils/functions/masks";
import { FORMA_PAGAMENTO } from "../../main/base";
import Errors, { ErrorsLancs } from "../validations/errors";
import PagamentoBancario from "./formas/bancario";
import PagamentoPix from "./formas/pix";

interface IProps {
  lancamento: LancamentoAttributes;
  updateNfe: (item: LancamentoAttributes) => void;
  errors: ErrorsLancs[];
  setErrors: React.Dispatch<React.SetStateAction<ErrorsLancs[]>>;
}

const FormFornecedor: FC<IProps> = ({
  lancamento,
  updateNfe,
  errors,
  setErrors,
}) => {
  const [typePagamento, setTypePagamento] = useState<string>("");

  useEffect(() => {
    getTypePagamento();
  }, [lancamento.id_forma_pag]);

  const updateFormaPagamento = (id?: string) => {
    const forma = FORMA_PAGAMENTO.find((item) => item.value.toString() === id);

    if (forma) {
      var newLancamento: LancamentoAttributes = lancamento;
      newLancamento.id_forma_pag = forma.value;
      newLancamento.id_favorecido_con = undefined;
      newLancamento.id_favorecido_fav = undefined;
      newLancamento.dados_pagamentos = undefined;
      newLancamento.st_nomerecebedor_fav = undefined;
      newLancamento.id_favorecido_fav = undefined;

      if (["3", "4", "7", "10", "13"].includes(forma.value.toString())) {
        newLancamento.id_favorecido_con = lancamento.id_contato_con;
        newLancamento.st_nomerecebedor_fav = lancamento.st_nome_con;
      }

      updateNfe(newLancamento);
    }
  };

  const getTypePagamento = () => {
    if (lancamento.id_forma_pag) {
      const forma = FORMA_PAGAMENTO.find(
        (item) => item.value === lancamento.id_forma_pag
      );
      if (forma) setTypePagamento(forma.value.toString());
    }
  };

  return (
    <Accordion defaultActiveKey="0" className="my-3">
      <Accordion.Item eventKey="1">
        <Accordion.Header as="h3">
          Fornecedor
          {errors.filter((item) => item.session === "fornecedor").length > 0
            ? ` [${
                errors.filter((item) => item.session === "fornecedor").length
              }]`
            : ""}
        </Accordion.Header>
        <Accordion.Body>
          <Errors errors={errors} session="fornecedor" setErrors={setErrors} />

          <Row className="mt-2">
            <Col>
              <Form.Group>
                <Form.Label>Fornecedor (Razão):</Form.Label>
                <Form.Control
                  title={`[${lancamento.Fornecedor?.id_contato_con}] ${lancamento.Fornecedor?.st_nome_con}`}
                  value={`[${lancamento.Fornecedor?.id_contato_con}] ${lancamento.Fornecedor?.st_nome_con}`}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>Fantasia:</Form.Label>
                <Form.Control
                  title={`${lancamento.Fornecedor?.st_fantasia_con}`}
                  value={`${lancamento.Fornecedor?.st_fantasia_con}`}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>CNPJ:</Form.Label>
                <Form.Control
                  value={new MaskFunctions().cnpjMask(
                    lancamento.Fornecedor && lancamento.Fornecedor.st_cpf_con
                      ? lancamento.Fornecedor.st_cpf_con
                      : ""
                  )}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <Form.Group>
                <Form.Label>Forma de Pagamento:</Form.Label>
                <Select
                  placeholder="Selecione uma forma..."
                  options={new ListFunctions().selectOption(
                    FORMA_PAGAMENTO,
                    "value",
                    ["label"]
                  )}
                  value={new ListFunctions().selectOption(
                    FORMA_PAGAMENTO.filter(
                      (item) => item.value === lancamento.id_forma_pag
                    ),
                    "value",
                    ["label"]
                  )}
                  onChange={(e) => {
                    updateFormaPagamento(e?.value);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          {["12"].includes(typePagamento) ? (
            <PagamentoPix
              lancamento={lancamento}
              updateNfe={updateNfe}
              pix={
                lancamento.LancamentoFavorecidoPixes
                  ? lancamento.LancamentoFavorecidoPixes[0]
                  : undefined
              }
            />
          ) : ["1", "2", "8", "9"].includes(typePagamento) ? (
            <PagamentoBancario lancamento={lancamento} updateNfe={updateNfe} />
          ) : null}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default FormFornecedor;
