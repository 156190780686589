import boletos from "@mrmgomes/boleto-utils";
import { cnpj } from "cpf-cnpj-validator";
import { useEffect, useState } from "react";
import {
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Row,
} from "react-bootstrap";
import {
  RiSortAlphabetAsc,
  RiSortAlphabetDesc,
  RiSortNumberAsc,
  RiSortNumberDesc,
} from "react-icons/ri";
import Select, { MultiValue } from "react-select";
import AsyncSelect from "react-select/async";
import {
  CondominioAttributes,
  DespesaAttributes,
  FornecedorAttributes,
  GruposCondominiosAttributes,
} from "../../../../../libs/types/models/sulo";
import { REPORT_INIT } from "../../../pages/private/reports/financeiro";
import ReportsAPI from "../../../services/api/reports";
import SuperlogicaAPI from "../../../services/api/sulo";
import ListFunctions from "../../../utils/functions/list";
import SortFunctions from "../../../utils/functions/sorts";
import StringFunctions from "../../../utils/functions/strings";
import {
  FormatFunctions,
  ValidateFunctions,
} from "../../../utils/functions/validations";
import moment from "../../../utils/moment";
import numeral from "../../../utils/numeral";
import { CHAVES_PIX } from "../../lancamentos/pre/components/main/base";
import ReportBaseFilters from "../baseFilter";
import ReportBaseResults from "../baseResults";
import ReportFinanceiroItem from "./subitem";

export const STATUS_DESPESAS = [
  { value: 1, label: "Liquidadas" },
  { value: 2, label: "Pendentes" },
  { value: 3, label: "Todas" },
];

export const FILTRAR_POR_DESPESAS = [
  { value: 1, label: "Criação", field: "criacao" },
  { value: 2, label: "Competência", field: "competencia" },
  { value: 3, label: "Liquidação", field: "liquidacao" },
  { value: 4, label: "Crédito", field: "credito" },
  { value: 5, label: "Cancelamento", field: "cancelamento" },
  { value: 6, label: "Vencimento", field: "vencimento" },
];

export const FORMA_PAGAMENTO_DESPESAS = [
  { value: 0, label: "Boleto" },
  { value: 1, label: "Cheque" },
  { value: 2, label: "Dinheiro" },
  { value: 3, label: "Cartão de crédito" },
  { value: 4, label: "Cartão de débito" },
  { value: 5, label: "Depósito" },
  { value: 6, label: "Cheque pré-datado" },
  { value: 7, label: "Débito automático" },
  { value: 8, label: "Trans. bancária" },
  { value: 9, label: "Doc/Ted" },
  { value: 10, label: "Outros" },
  { value: 11, label: "Tributo sem código de barras" },
  { value: 12, label: "Pix" },
  { value: 13, label: "DCTFWeb" },
];

export const REMESSA_DESPESAS = [
  { value: 0, label: "Todos", field: "0", codigos: ["9999"] },
  {
    value: 1,
    label: "Marcados para Remessa",
    field: "marcados",
    codigos: ["1"],
  },
  {
    value: 2,
    label: "Não marcados para Remessa",
    field: "naoMarcados",
    codigos: ["0", ""],
  },
  { value: 3, label: "Remessa enviadas", field: "enviadas", codigos: ["2"] },
  {
    value: 4,
    label: "Remessas confirmadas",
    field: "confirmadas",
    codigos: ["3"],
  },
  { value: 5, label: "Remessas com erro", field: "erro", codigos: ["-2"] },
];

interface IProps {
  gruposCondominios: GruposCondominiosAttributes[];
  dataReport: IReportFinanceiro;
  setDataReport: React.Dispatch<React.SetStateAction<IReportFinanceiro>>;
  dataDespesas: IFinanceiroDespesas[] | undefined;
  setDataDespesas: React.Dispatch<
    React.SetStateAction<IFinanceiroDespesas[] | undefined>
  >;
  onSetLoading: (loading: boolean) => void;
  submitRemessas: () => void;
}

export interface IReportFinanceiro {
  id_grupos: number[];
  id_condominios: number[];
  id_fornecedores: number[];
  id_formas: number[];
  status: number;
  filter: number;
  remessa: number;
  dateFrom: Date;
  dateTo: Date;
}

export interface IFinanceiroDespesasPayment {
  status: number; //0 NÃO APLICÁVEL , 1 OK, 2 ERRO
  method: string;
  data: string;
  message?: string;
}

export interface IFinanceiroDespesas {
  checked: boolean;
  despesa: DespesaAttributes;
  grupos: string;
  remessa: string;
  payment: IFinanceiroDespesasPayment;
}

interface Options {
  value: string;
  label: string;
  st_nome: string;
}

interface IColumn {
  id: number;
  class: string;
  name: string;
  canOrder: boolean;
  orderAsc?: boolean;
  colSpan: number;
  orderType: string;
  name_column?: string;
  funcAsc?: (a: IFinanceiroDespesas, b: IFinanceiroDespesas) => any;
  funcDesc?: (a: IFinanceiroDespesas, b: IFinanceiroDespesas) => any;
}

const columns: IColumn[] = [
  {
    id: 0,
    class: "text-center",
    name: "#",
    canOrder: false,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "",
    name_column: undefined,
  },
  {
    id: 1,
    class: "text-center",
    name: "Incluir",
    canOrder: false,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "",
    name_column: undefined,
  },
  {
    id: 2,
    class: "text-center order__button",
    name: "Despesa",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "number",
    name_column: "id_despesa_des",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.despesa.id_despesa_des,
        b.despesa.id_despesa_des
      ).sortNumberAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.despesa.id_despesa_des,
        b.despesa.id_despesa_des
      ).sortNumberDesc(),
  },
  {
    id: 3,
    class: "text-center order__button",
    name: "Parcela",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "number",
    name_column: "id_parcela_pdes",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.despesa.id_parcela_pdes,
        b.despesa.id_parcela_pdes
      ).sortNumberAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.despesa.id_parcela_pdes,
        b.despesa.id_parcela_pdes
      ).sortNumberDesc(),
  },
  {
    id: 15,
    class: "text-center",
    name: "Arq.",
    canOrder: false,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "",
    name_column: "",
  },
  {
    id: 16,
    class: "text-center order__button",
    name: "St.",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "number",
    name_column: "fl_liquidado_pdes",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.despesa.fl_liquidado_pdes,
        b.despesa.fl_liquidado_pdes
      ).sortNumberAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.despesa.fl_liquidado_pdes,
        b.despesa.fl_liquidado_pdes
      ).sortNumberDesc(),
  },
  {
    id: 4,
    class: "text-center order__button",
    name: "Remessa",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "string",
    name_column: "",
    funcAsc: (a, b) => new SortFunctions(a.remessa, b.remessa).sortStrAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.remessa,
        b.despesa.fl_remessastatus_pdes
      ).sortStrDesc(),
  },
  {
    id: 5,
    class: "text-center order__button",
    name: "Forma de Pagto.",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "number",
    name_column: "id_forma_pag",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.despesa.id_forma_pag,
        b.despesa.id_forma_pag
      ).sortNumberAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.despesa.id_forma_pag,
        b.despesa.id_forma_pag
      ).sortNumberDesc(),
  },
  {
    id: 6,
    class: "text-center order__button",
    name: "Dados Pagto.",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "number",
    name_column: "",
    funcAsc: (a, b) =>
      new SortFunctions(a.payment.status, b.payment.status).sortNumberAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(a.payment.status, b.payment.status).sortNumberDesc(),
  },
  {
    id: 7,
    class: "text-center order__button",
    name: "Vencimento",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "date",
    name_column: "dt_vencimento_pdes",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.despesa.dt_vencimento_pdes,
        b.despesa.dt_vencimento_pdes
      ).sortDateAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.despesa.dt_vencimento_pdes,
        b.despesa.dt_vencimento_pdes
      ).sortDateDesc(),
  },
  {
    id: 8,
    class: "text-center order__button",
    name: "Documento",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "string",
    name_column: "st_documento_des",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.despesa.st_documento_des,
        b.despesa.st_documento_des
      ).sortStrAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.despesa.st_documento_des,
        b.despesa.st_documento_des
      ).sortStrDesc(),
  },
  {
    id: 9,
    class: "text-center order__button",
    name: "Grupos",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "string",
    name_column: "",
    funcAsc: (a, b) => new SortFunctions(a.grupos, b.grupos).sortStrAsc(),
    funcDesc: (a, b) => new SortFunctions(a.grupos, b.grupos).sortStrDesc(),
  },
  {
    id: 10,
    class: "text-center order__button",
    name: "Condomínio",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "string",
    name_column: "st_fantasia_cond",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.despesa.st_fantasia_cond,
        b.despesa.st_fantasia_cond
      ).sortStrAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.despesa.st_fantasia_cond,
        b.despesa.st_fantasia_cond
      ).sortStrDesc(),
  },
  {
    id: 11,
    class: "text-center order__button",
    name: "Fornecedor",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "string",
    name_column: "st_fantasia_con",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.despesa.st_fantasia_con,
        b.despesa.st_fantasia_con
      ).sortStrAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.despesa.st_fantasia_con,
        b.despesa.st_fantasia_con
      ).sortStrDesc(),
  },
  {
    id: 12,
    class: "text-center order__button",
    name: "Favorecido",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "string",
    name_column: "st_fantasia_con",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.despesa.st_fantasia_con,
        b.despesa.st_fantasia_con
      ).sortStrAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.despesa.st_fantasia_con,
        b.despesa.st_fantasia_con
      ).sortStrDesc(),
  },
  {
    id: 13,
    class: "text-center order__button",
    name: "Valor Bruto",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "float",
    name_column: "vl_valorbruto_pdes",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.despesa.vl_valorbruto_pdes,
        b.despesa.vl_valorbruto_pdes
      ).sortNumberAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.despesa.vl_valorbruto_pdes,
        b.despesa.vl_valorbruto_pdes
      ).sortNumberDesc(),
  },
  {
    id: 14,
    class: "text-center order__button",
    name: "Valor Líquido",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "float",
    name_column: "vl_valor_pdes",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.despesa.vl_valor_pdes,
        b.despesa.vl_valor_pdes
      ).sortNumberAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.despesa.vl_valor_pdes,
        b.despesa.vl_valor_pdes
      ).sortNumberDesc(),
  },
];

const iconSortSize = 20;

const ReportFinanceiro: React.FC<IProps> = ({
  gruposCondominios,
  dataReport,
  setDataReport,
  dataDespesas,
  setDataDespesas,
  onSetLoading,
  submitRemessas,
}) => {
  const [condominios, setCondominios] = useState<Options[]>([]);
  const [fornecedores, setFornecedores] = useState<Options[]>([]);
  const [renderColumns, setRenderColumns] = useState<IColumn[]>(columns);
  const [checkedPayment, setCheckedPayment] = useState(true);

  useEffect(() => {
    if (dataDespesas) {
      const order = renderColumns.find((i) => i.orderAsc !== undefined);
      if (order) {
        let despesasSort: IFinanceiroDespesas[] = [...dataDespesas];
        despesasSort.sort((a, b) => {
          if (order.orderAsc) {
            return order.funcAsc ? order.funcAsc(a, b) : 0;
          } else {
            return order.funcDesc ? order.funcDesc(a, b) : 0;
          }
        });
        setDataDespesas(despesasSort);
      }
    }
  }, [renderColumns]);

  const orderColumns = (column: IColumn) => {
    const newOrder: IColumn[] = renderColumns.map((c) => {
      var order: IColumn = {
        ...c,
        orderAsc: undefined,
      };

      if (column.id === c.id) {
        if (c.orderAsc === undefined) {
          order.orderAsc = true;
        } else {
          order.orderAsc = !c.orderAsc;
        }
      }

      return order;
    });

    setRenderColumns(newOrder);
  };

  const getIconSort = (column: IColumn) => {
    if (column.canOrder) {
      if (column.orderType === "string") {
        if (column.orderAsc === true) {
          return (
            <div className="mx-1">
              <RiSortAlphabetAsc size={iconSortSize} />
            </div>
          );
        } else if (column.orderAsc === false) {
          return (
            <div className="mx-1">
              <RiSortAlphabetDesc size={iconSortSize} />
            </div>
          );
        }
      } else {
        if (column.orderAsc === true) {
          return (
            <div className="mx-1">
              <RiSortNumberAsc size={iconSortSize} />
            </div>
          );
        } else if (column.orderAsc === false) {
          return (
            <div className="mx-1">
              <RiSortNumberDesc size={iconSortSize} />
            </div>
          );
        }
      }
    }

    return null;
  };

  const searchCondominios = async (
    inputValue: string,
    skipVerified = false
  ) => {
    var verified = false;

    if (skipVerified) {
      verified = true;
    } else {
      verified = inputValue.length >= 3;
    }

    if (verified) {
      const result = await new SuperlogicaAPI().searchCondominios(inputValue);

      if (result.success) {
        const newList: CondominioAttributes[] = result.data;

        const newData: Options[] = newList.map((item) => {
          return {
            value: item.id_condominio_cond
              ? item.id_condominio_cond.toString()
              : "",
            label: `[${item.id_condominio_cond}] ${item.st_nome_cond} (${
              item.st_cpf_cond ? cnpj.format(item.st_cpf_cond) : ""
            })`,
            st_nome: item.st_nome_cond,
          };
        });
        return newData;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const promiseCondominiosOptions = (inputValue: string) => {
    return new Promise<Options[]>((resolve) => {
      resolve(searchCondominios(inputValue));
    });
  };

  const updateGruposCondominios = async (
    e: MultiValue<{
      value: string;
      label: string;
    }>
  ) => {
    if (e.length === 0) {
      setDataReport({ ...dataReport, id_grupos: [], id_condominios: [] });
      setCondominios([]);
    } else {
      const id_condominios: number[] = [];
      const id_grupos = e.map((item) => {
        return parseInt(item.value);
      });

      e.map((item) => {
        const grupo = gruposCondominios.find(
          (i) => i.id_grupo_cg === parseInt(item.value)
        );
        if (
          grupo &&
          grupo.lista_condominios &&
          grupo.lista_condominios.length > 0
        ) {
          grupo.lista_condominios.split(",").map((l) => {
            if (!id_condominios.includes(parseInt(l))) {
              id_condominios.push(parseInt(l));
            }
            return null;
          });
        }
        return null;
      });

      if (id_condominios.length > 0) {
        const conds = await searchCondominios(id_condominios.join(","), true);
        setCondominios(conds);
      }
      setDataReport({ ...dataReport, id_grupos, id_condominios });
    }
  };

  const searchFornecedores = async (inputValue: string) => {
    if (inputValue.length >= 3) {
      const result = await new SuperlogicaAPI().searchFornecedores(inputValue);
      if (result.success) {
        const newList: FornecedorAttributes[] = result.data;

        const newData: Options[] = newList.map((item) => {
          return {
            value: item.id_contato_con ? item.id_contato_con.toString() : "",
            label: `[${item.id_contato_con}] ${item.st_nome_con} / ${
              item.st_fantasia_con
            } (${item.st_cpf_con ? cnpj.format(item.st_cpf_con) : ""})`,
            st_nome: item.st_nome_con,
          };
        });
        return newData;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const promiseFornecedoresOptions = (inputValue: string) => {
    return new Promise<Options[]>((resolve) => {
      resolve(searchFornecedores(inputValue));
    });
  };

  const allCondominios = () => {
    if (dataReport.id_condominios.find((id) => id === -1)) {
      setDataReport({ ...dataReport, id_condominios: [] });
    } else {
      setDataReport({ ...dataReport, id_condominios: [-1] });
    }
    setCondominios([]);
  };

  const allFornecedores = () => {
    if (dataReport.id_fornecedores.find((id) => id === -1)) {
      setDataReport({ ...dataReport, id_fornecedores: [] });
    } else {
      setDataReport({ ...dataReport, id_fornecedores: [-1] });
    }
    setFornecedores([]);
  };

  const onSubmit = async () => {
    const data: any = {};
    const remessa = REMESSA_DESPESAS.find(
      (i) => i.value === dataReport.remessa
    );
    const status = STATUS_DESPESAS.find((i) => i.value === dataReport.status);
    const filter = FILTRAR_POR_DESPESAS.find(
      (i) => i.value === dataReport.filter
    );

    data.id_condominios = dataReport.id_condominios;
    data.id_fornecedores = dataReport.id_fornecedores;
    data.dateFrom = dataReport.dateFrom;
    data.dateTo = dataReport.dateTo;
    data.id_formas = dataReport.id_formas;
    data.id_grupos = dataReport.id_grupos;
    data.remessa = remessa ? remessa.field : "";
    data.status = status ? status.label.toLocaleLowerCase() : "";
    data.filter = filter ? filter.field : "";

    try {
      onSetLoading(true);
      const result = await new SuperlogicaAPI().listDespesas(data);
      if (result.success) {
        const despesas: IFinanceiroDespesas[] = result.data.map(
          (item: DespesaAttributes) => {
            const grupos: string[] = [];

            gruposCondominios.map((g) => {
              if (g.lista_condominios && g.lista_condominios.includes(",")) {
                if (
                  g.st_grupo_cg.trim().toLocaleLowerCase().includes("banco") &&
                  g.lista_condominios
                    .split(",")
                    .includes(item.id_condominio_cond.toString())
                ) {
                  grupos.push(g.st_grupo_cg);
                }
              }
            });

            const value = item.fl_remessastatus_pdes.toString();
            const remessa = REMESSA_DESPESAS.find((i) =>
              i.codigos.includes(value.trim())
            );

            const payment = checkPagamento(item, grupos.join(","));

            return {
              checked: true,
              despesa: item,
              remessa: remessa ? remessa.label : "",
              grupos: grupos
                .sort((a, b) => new SortFunctions(a, b).sortStrAsc())
                .join(","),
              payment,
            };
          }
        );
        setDataDespesas(despesas);
      }
    } finally {
      onSetLoading(false);
    }
  };

  const checkPagamento = (despesa: DespesaAttributes, grupos: string) => {
    const forma = FORMA_PAGAMENTO_DESPESAS.find(
      (forma) => forma.value == despesa.id_forma_pag
    )?.label;

    var payment: IFinanceiroDespesasPayment = {
      method: forma ? forma : "",
      status: 0,
      message: "",
      data: "",
    };

    switch (payment.method.toLowerCase()) {
      case "boleto":
        if (despesa.st_codigobarras_pdes.trim() === "") {
          payment.message = "Sem código de barras";
          payment.status = 2;
        } else {
          const result = boletos.validarBoleto(
            despesa.st_codigobarras_pdes.trim()
          );

          payment.data = despesa.st_codigobarras_pdes;

          if (result.sucesso) {
            const valBoleto = parseFloat(result.valor.toString());
            const valDespesa = parseFloat(despesa.vl_valor_pdes.toString());

            const dateBoleto = moment(result.vencimento).format("DD/MM/YYYY");
            const dateDespesa = moment(
              despesa.dt_vencimento_pdes,
              "MM/DD/YYYY"
            ).format("DD/MM/YYYY");

            if (valBoleto === valDespesa) {
              payment.message = `${payment.data} [${despesa.st_codigobarras_pdes.length}]`;
              payment.status = 1;
            } else {
              payment.message = `Valor do código de barras (${numeral(
                valBoleto.toString().replace(".", ",")
              ).format("#,##0.00")}) diferente do lançamento (${numeral(
                valDespesa.toString().replace(".", ",")
              ).format("#,##0.00")})`;
              payment.status = 2;
            }

            if (dateBoleto !== dateDespesa) {
              const dateError = `Vncto. do boleto (${dateBoleto}) diferente do vncto. do lançamento (${dateDespesa}).`;

              if (payment.status === 2) {
                payment.message = `${payment.message}. ${dateError}`;
              } else {
                payment.message = dateError;
                payment.status = 2;
              }
            }
          } else {
            payment.message = "Código de barras inválido";
            payment.status = 2;
          }
        }
        break;

      case "pix":
        if (despesa.st_pixchave_pdes.trim() === "") {
          payment.message = "Sem chave Pix informada";
          payment.status = 2;
        } else {
          payment.data = despesa.st_pixchave_pdes;

          if (despesa.fl_pixtipochave_pdes == 1) {
            if (new ValidateFunctions(despesa.st_pixchave_pdes).cpf()) {
              payment.status = 1;
            } else {
              payment.status = 2;
            }
          } else if (despesa.fl_pixtipochave_pdes == 2) {
            if (new ValidateFunctions(despesa.st_pixchave_pdes).cnpj()) {
              payment.status = 1;
            } else {
              payment.status = 2;
            }
          } else if (despesa.fl_pixtipochave_pdes == 3) {
            if (
              new ValidateFunctions(despesa.st_pixchave_pdes).celular(
                true,
                false
              )
            ) {
              payment.status = 1;
            } else {
              payment.status = 2;
            }
          } else if (despesa.fl_pixtipochave_pdes == 4) {
            if (new ValidateFunctions(despesa.st_pixchave_pdes).email()) {
              payment.status = 1;
            } else {
              payment.status = 2;
            }
          }

          const tipo_chave = CHAVES_PIX.find(
            (i) => i.value.toString() == despesa.fl_pixtipochave_pdes.toString()
          );

          payment.message =
            payment.status === 1
              ? `${tipo_chave?.label}: ${payment.data}`
              : `${tipo_chave?.label} inválido(a): ${payment.data}`;
        }

        break;

      case "doc/ted":
        if (
          !despesa.st_banco_fav ||
          !despesa.st_agencia_fav ||
          !despesa.st_contabancaria_fav ||
          !despesa.st_cpfcnpjrecebedor_fav
        ) {
          const messages: string[] = [];
          payment.status = 2;

          if (!despesa.st_banco_fav) {
            messages.push("Banco não informado");
          }

          if (!despesa.st_agencia_fav) {
            messages.push("Agência não informada");
          }

          if (!despesa.st_contabancaria_fav) {
            messages.push("Conta não informada");
          }

          if (!despesa.st_cpfcnpjrecebedor_fav) {
            messages.push("Documento não informado");
          }
          payment.message = messages.join(" | ");
        } else {
          payment.status = 1;
          payment.message = `Banco (${despesa.st_banco_fav}): ${
            despesa.st_nome_banc
          } | Agência: ${despesa.st_agencia_fav} | Conta (${
            despesa.fl_tipoconta_fav === 0 ? "Corrente" : "Poupança"
          }): ${despesa.st_contabancaria_fav} | Documento: ${
            !despesa.st_cpfcnpjrecebedor_fav
              ? ""
              : despesa.st_cpfcnpjrecebedor_fav.length >= 13
              ? new FormatFunctions(despesa.st_cpfcnpjrecebedor_fav).cnpj()
              : new FormatFunctions(despesa.st_cpfcnpjrecebedor_fav).cpf()
          }`;
        }

        break;

      case "trans. bancária":
        if (
          !despesa.st_banco_fav ||
          !despesa.st_agencia_fav ||
          !despesa.st_contabancaria_fav ||
          !despesa.st_cpfcnpjrecebedor_fav
        ) {
          const messages: string[] = [];
          payment.status = 2;

          if (!despesa.st_banco_fav) {
            messages.push("Banco não informado");
          }

          if (!despesa.st_agencia_fav) {
            messages.push("Agência não informada");
          }

          if (!despesa.st_contabancaria_fav) {
            messages.push("Conta não informada");
          }

          if (!despesa.st_cpfcnpjrecebedor_fav) {
            messages.push("Documento não informado");
          }
          payment.message = messages.join(" | ");
        } else {
          const bancos = ["itau", "bradesco", "santander"];
          const banco_condominio = bancos.find((i) =>
            new StringFunctions(grupos)
              .removeAccents()
              .toLocaleLowerCase()
              .includes(i)
          );
          const banco_favorecido = new StringFunctions(despesa.st_nome_banc)
            .removeAccents()
            .toLocaleLowerCase();

          if (
            !despesa.st_nome_banc ||
            (banco_condominio && !banco_favorecido.includes(banco_condominio))
          ) {
            payment.status = 2;
            payment.message =
              "Banco do condomínio diverge do banco do favorecido para ser TRANSFERÊNCIA BANCÁRIA";
          } else {
            payment.status = 1;
            payment.message = `Banco (${despesa.st_banco_fav}): ${
              despesa.st_nome_banc
            } | Agência: ${despesa.st_agencia_fav} | Conta (${
              despesa.fl_tipoconta_fav === 0 ? "Corrente" : "Poupança"
            }): ${despesa.st_contabancaria_fav} | Documento: ${
              !despesa.st_cpfcnpjrecebedor_fav
                ? ""
                : despesa.st_cpfcnpjrecebedor_fav.length >= 13
                ? new FormatFunctions(despesa.st_cpfcnpjrecebedor_fav).cnpj()
                : new FormatFunctions(despesa.st_cpfcnpjrecebedor_fav).cpf()
            }`;
          }
        }
        break;

      default:
        break;
    }

    return payment;
  };

  const checkedAll = () => {
    if (dataDespesas) {
      const despesas: IFinanceiroDespesas[] = dataDespesas.map(
        (item: IFinanceiroDespesas) => {
          return { ...item, checked: true };
        }
      );
      setDataDespesas(despesas);
    }
  };

  const uncheckedAll = () => {
    if (dataDespesas) {
      const despesas: IFinanceiroDespesas[] = dataDespesas.map(
        (item: IFinanceiroDespesas) => {
          return { ...item, checked: false };
        }
      );
      setDataDespesas(despesas);
    }
  };

  const checkedChange = (despesa: IFinanceiroDespesas) => {
    if (dataDespesas) {
      const newDespesas = [...dataDespesas];
      const index = newDespesas.findIndex(
        (item) => item.despesa.id_despesa_des === despesa.despesa.id_despesa_des
      );
      newDespesas[index] = {
        ...newDespesas[index],
        checked: !newDespesas[index].checked,
      };
      setDataDespesas(newDespesas);
    }
  };

  const generateReport = async () => {
    try {
      onSetLoading(true);

      if (dataDespesas) {
        var somaLiquido = 0;
        var somaBruto = 0;
        var contador = 0;

        const despesas = dataDespesas
          .filter((item) => item.checked)
          .map((item, index) => {
            const bruto = parseFloat(
              item.despesa.vl_valorbruto_pdes.toString()
            );
            const liquido = parseFloat(item.despesa.vl_valor_pdes.toString());

            contador += 1;
            somaLiquido += liquido;
            somaBruto += bruto;

            return {
              index: index + 1,
              status:
                item.despesa.fl_liquidado_pdes.toString() === "0" ? "P" : "L",
              id_despesa_des: item.despesa.id_despesa_des,
              id_parcela_pdes: item.despesa.id_parcela_pdes,
              remessa: item.remessa,
              forma: item.payment.method,
              dados_pagto: item.payment.message,
              dt_vencimento_pdes: moment(
                item.despesa.dt_vencimento_pdes,
                "MM/DD/YYYY"
              ).format("DD/MM/YYYY"),
              st_documento_des: item.despesa.st_documento_des,
              grupos: item.grupos.replace(/Banco/g, "").trim(),
              st_fantasia_cond: item.despesa.st_fantasia_cond,
              st_fantasia_con: item.despesa.st_fantasia_con,
              st_nomerecebedor_fav: item.despesa.st_nomerecebedor_fav,
              vl_valorbruto_pdes: numeral(
                item.despesa.vl_valorbruto_pdes.toString().replace(".", ",")
              ).format("#,##0.00"),
              vl_valor_pdes: numeral(
                item.despesa.vl_valor_pdes.toString().replace(".", ",")
              ).format("#,##0.00"),
            };
          });

        const data = {
          despesas,
          somaLiquido: numeral(somaLiquido.toString().replace(".", ",")).format(
            "$ #,##.00"
          ),
          somaBruto: numeral(somaBruto.toString().replace(".", ",")).format(
            "$ #,##.00"
          ),
          contador,
        };

        await new ReportsAPI().financeiro(data);
      }
    } finally {
      onSetLoading(false);
    }
  };

  const getTotalizadores = () => {
    if (dataDespesas) {
      var somaTelaLiquido = 0;
      var somaTelaBruto = 0;
      var contadorTela = 0;
      var somaSelecionadosLiquido = 0;
      var somaSelecionadosBruto = 0;
      var contadorSelecionados = 0;

      dataDespesas.map((item) => {
        const bruto = parseFloat(item.despesa.vl_valorbruto_pdes.toString());
        const liquido = parseFloat(item.despesa.vl_valor_pdes.toString());

        contadorTela += 1;
        somaTelaLiquido += liquido;
        somaTelaBruto += bruto;
        contadorSelecionados += item.checked ? 1 : 0;
        somaSelecionadosLiquido += item.checked ? liquido : 0;
        somaSelecionadosBruto += item.checked ? bruto : 0;
      });

      return (
        <Container fluid className="my-3">
          <Row className="on__foccus">
            <Col>
              <small>Totalizador (Em tela)</small>
            </Col>
            <Col className="text-center">
              <small>{contadorTela}</small>
            </Col>
            <Col className="text-end">
              <small>
                Bruto:{" "}
                {numeral(somaTelaBruto.toString().replace(".", ",")).format(
                  "$ #,##.00"
                )}
              </small>
            </Col>
            <Col className="text-end">
              <small>
                Líquido:{" "}
                {numeral(somaTelaLiquido.toString().replace(".", ",")).format(
                  "$ #,##.00"
                )}
              </small>
            </Col>
          </Row>

          <Row className="on__foccus">
            <Col>
              <small>Totalizador (Selecionados)</small>
            </Col>
            <Col className="text-center">
              <small>{contadorSelecionados}</small>
            </Col>
            <Col className="text-end">
              <small>
                Bruto:{" "}
                {numeral(
                  somaSelecionadosBruto.toString().replace(".", ",")
                ).format("$ #,##.00")}
              </small>
            </Col>
            <Col className="text-end">
              <small>
                Líquido:{" "}
                {numeral(
                  somaSelecionadosLiquido.toString().replace(".", ",")
                ).format("$ #,##.00")}
              </small>
            </Col>
          </Row>
        </Container>
      );
    }
  };

  const checkNoErrors = () => {
    if (dataDespesas) {
      const despesas: IFinanceiroDespesas[] = dataDespesas.map(
        (item: IFinanceiroDespesas) => {
          if (item.payment.status === 1) {
            return { ...item, checked: checkedPayment };
          } else {
            return { ...item };
          }
        }
      );
      setDataDespesas(despesas);
    }
  };

  const checkErrors = () => {
    if (dataDespesas) {
      const despesas: IFinanceiroDespesas[] = dataDespesas.map(
        (item: IFinanceiroDespesas) => {
          if (item.payment.status === 2) {
            return { ...item, checked: checkedPayment };
          } else {
            return { ...item };
          }
        }
      );
      setDataDespesas(despesas);
    }
  };

  const checkNulls = () => {
    if (dataDespesas) {
      const despesas: IFinanceiroDespesas[] = dataDespesas.map(
        (item: IFinanceiroDespesas) => {
          if (item.payment.status === 0) {
            return { ...item, checked: checkedPayment };
          } else {
            return { ...item };
          }
        }
      );
      setDataDespesas(despesas);
    }
  };

  const checkForma = (label: string) => {
    if (dataDespesas) {
      const despesas: IFinanceiroDespesas[] = dataDespesas.map(
        (item: IFinanceiroDespesas) => {
          if (item.payment.method === label) {
            return { ...item, checked: checkedPayment };
          } else {
            return { ...item };
          }
        }
      );
      setDataDespesas(despesas);
    }
  };

  const CheckButtons = () => {
    return (
      <div className="p-2 d-flex justify-content-between align-items-center">
        <Form.Check
          type="checkbox"
          checked={checkedPayment}
          onChange={() => setCheckedPayment(!checkedPayment)}
          label="Marcar Despesas"
          id="selectDespesas"
        />
        <DropdownButton
          data-bs-theme="dark"
          as={ButtonGroup}
          variant="outline-secondary"
          title="Ações"
          size="sm"
        >
          <Dropdown.Item key="checkNoErrors" onClick={checkNoErrors}>
            {checkedPayment ? "Marcar" : "Desmarcar"} Sem Erros
          </Dropdown.Item>
          <Dropdown.Item key="checkErrors" onClick={checkErrors}>
            {checkedPayment ? "Marcar" : "Desmarcar"} Com Erros
          </Dropdown.Item>
          <Dropdown.Item key="checkNulls" onClick={checkNulls}>
            {checkedPayment ? "Marcar" : "Desmarcar"} Nulos
          </Dropdown.Item>
          <Dropdown.Divider />
          {FORMA_PAGAMENTO_DESPESAS.map((i) => {
            return (
              <Dropdown.Item key={i.label} onClick={() => checkForma(i.label)}>
                {checkedPayment ? "Marcar" : "Desmarcar"} {i.label}
              </Dropdown.Item>
            );
          })}
          <Dropdown.Divider />
          <Dropdown.Item key="marcar_remessa" onClick={submitRemessas}>
            Marcar Selecionados para Remessa
          </Dropdown.Item>
        </DropdownButton>
      </div>
    );
  };
  //console.log(dataDespesas);

  return (
    <div className="mt-5">
      <ReportBaseFilters
        onSubmit={onSubmit}
        onClean={() => {
          setDataReport(REPORT_INIT);
          setDataDespesas(undefined);
          setCondominios([]);
          setFornecedores([]);
        }}
        title="Filtros"
        closeAccordion={dataDespesas ? "1" : "0"}
      >
        <Row className="mt-2">
          <Col>
            <Form.Group>
              <Form.Label>Data Início:</Form.Label>
              <Form.Control
                className="value__date"
                type="date"
                value={
                  dataReport.dateFrom
                    ? moment(dataReport.dateFrom, "YYYY-MM-DD").format(
                        "YYYY-MM-DD"
                      )
                    : moment().format("YYYY-MM-DD")
                }
                onChange={(e) => {
                  setDataReport({
                    ...dataReport,
                    dateFrom: moment(
                      moment(e.target.value).format("YYYY-MM-DD")
                    ).toDate(),
                  });
                }}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>Data Fim:</Form.Label>
              <Form.Control
                className="value__date"
                type="date"
                value={
                  dataReport?.dateTo
                    ? moment(dataReport.dateTo, "YYYY-MM-DD").format(
                        "YYYY-MM-DD"
                      )
                    : moment().format("YYYY-MM-DD")
                }
                onChange={(e) => {
                  setDataReport({
                    ...dataReport,
                    dateTo: moment(
                      moment(e.target.value).format("YYYY-MM-DD")
                    ).toDate(),
                  });
                }}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Label>Status:</Form.Label>
            <Select
              placeholder="Status das Despesas"
              options={new ListFunctions().selectOption(
                STATUS_DESPESAS,
                "value",
                ["label"]
              )}
              onChange={(e) => {
                const id = e ? parseInt(e.value) : 2;
                setDataReport({ ...dataReport, status: id });
              }}
              value={new ListFunctions().selectOption(
                STATUS_DESPESAS.filter(
                  (item) => item.value === dataReport.status
                ),
                "value",
                ["label"]
              )}
            />
          </Col>

          <Col>
            <Form.Label>Filtrar Por:</Form.Label>
            <Select
              placeholder="Filtrar Despesas"
              options={new ListFunctions().selectOption(
                FILTRAR_POR_DESPESAS,
                "value",
                ["label"]
              )}
              onChange={(e) => {
                const id = e ? parseInt(e.value) : 2;
                setDataReport({ ...dataReport, filter: id });
              }}
              value={new ListFunctions().selectOption(
                FILTRAR_POR_DESPESAS.filter(
                  (item) => item.value === dataReport.filter
                ),
                "value",
                ["label"]
              )}
            />
          </Col>
        </Row>

        <Row className="mt-2">
          <Col>
            <Form.Label>Formas de Pagamento:</Form.Label>
            <Select
              placeholder="Selecione a(s) formas de pagamento..."
              isMulti
              options={new ListFunctions().selectOption(
                FORMA_PAGAMENTO_DESPESAS,
                "value",
                ["label"]
              )}
              onChange={(e) => {
                const id_formas = e.map((item) => {
                  return parseInt(item.value);
                });
                setDataReport({ ...dataReport, id_formas });
              }}
              value={new ListFunctions().selectOption(
                FORMA_PAGAMENTO_DESPESAS.filter((item) =>
                  dataReport.id_formas.includes(item.value)
                ),
                "value",
                ["label"]
              )}
            />
          </Col>

          <Col>
            <Form.Label>Remessas:</Form.Label>
            <Select
              placeholder="Selecione o tipo de remessa"
              options={new ListFunctions().selectOption(
                REMESSA_DESPESAS,
                "value",
                ["label"]
              )}
              onChange={(e) => {
                const id = e ? parseInt(e.value) : 2;
                setDataReport({ ...dataReport, remessa: id });
              }}
              value={new ListFunctions().selectOption(
                REMESSA_DESPESAS.filter(
                  (item) => item.value === dataReport.remessa
                ),
                "value",
                ["label"]
              )}
            />
          </Col>
        </Row>

        <Row className="mt-2">
          <Col>
            <Form.Label>
              Grupo de Condomínios:{" "}
              <span className="link__button" onClick={allCondominios}>
                {dataReport.id_condominios.find((id) => id === -1)
                  ? "Selecionar Condomínios"
                  : "Todos Condomínios"}
              </span>
            </Form.Label>
            <Select
              isDisabled={
                dataReport.id_condominios.find((id) => id === -1) ? true : false
              }
              isMulti
              placeholder={
                dataReport.id_condominios.find((id) => id === -1)
                  ? "Todos Condomínios Selecionados"
                  : "Selecionar Grupos de Condomínios"
              }
              options={new ListFunctions().selectOption(
                gruposCondominios,
                "id_grupo_cg",
                ["st_grupo_cg"]
              )}
              onChange={(e) => {
                updateGruposCondominios(e);
              }}
              value={new ListFunctions().selectOption(
                gruposCondominios.filter((item) =>
                  dataReport.id_grupos.includes(item.id_grupo_cg)
                ),
                "id_grupo_cg",
                ["st_grupo_cg"]
              )}
            />
          </Col>

          {!dataReport.id_condominios.find((id) => id === -1) && (
            <Col>
              <Form.Group>
                <Form.Label>Condomínios:</Form.Label>
                <AsyncSelect
                  placeholder="Digite pelo menos 3 caracteres..."
                  cacheOptions
                  loadOptions={promiseCondominiosOptions}
                  onChange={(e) => {
                    setDataReport({
                      ...dataReport,
                      id_condominios: e.map((i) => {
                        return parseInt(i.value);
                      }),
                    });
                    setCondominios([...e]);
                  }}
                  value={condominios}
                  noOptionsMessage={() => "Nenhum resultado encontrado"}
                  isClearable
                  isMulti
                />
              </Form.Group>
            </Col>
          )}
        </Row>

        <Row className="mt-2">
          <Col>
            <Form.Group>
              <Form.Label>
                Fornecedores:{" "}
                <span className="link__button" onClick={allFornecedores}>
                  {dataReport.id_fornecedores.find((id) => id === -1)
                    ? "Selecionar Fornecedores"
                    : "Todos Fornecedores"}
                </span>
              </Form.Label>
              <AsyncSelect
                isDisabled={
                  dataReport.id_fornecedores.find((id) => id === -1)
                    ? true
                    : false
                }
                placeholder={
                  dataReport.id_fornecedores.find((id) => id === -1)
                    ? "Todos Fornecedores Selecionados"
                    : "Digite pelo menos 3 caracteres..."
                }
                cacheOptions
                loadOptions={promiseFornecedoresOptions}
                onChange={(e) => {
                  setDataReport({
                    ...dataReport,
                    id_fornecedores: e.map((i) => {
                      return parseInt(i.value);
                    }),
                  });
                  setFornecedores([...e]);
                }}
                value={fornecedores}
                noOptionsMessage={() => "Nenhum resultado encontrado"}
                isClearable
                isMulti
              />
            </Form.Group>
          </Col>
        </Row>
      </ReportBaseFilters>

      {dataDespesas && (
        <ReportBaseResults
          data={dataDespesas}
          checkedAll={checkedAll}
          uncheckedAll={uncheckedAll}
          generateReport={generateReport}
        >
          <CheckButtons />

          {getTotalizadores()}

          <table className="despesas__table">
            <thead>
              <tr>
                {renderColumns.map((column) => {
                  return (
                    <th
                      key={`column__lancs__${column.id.toString()}`}
                      className={column.class}
                      colSpan={column.colSpan}
                      onClick={() => {
                        if (column.canOrder) orderColumns(column);
                      }}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        {column.name}

                        {getIconSort(column)}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {dataDespesas.map((item, index) => {
                return (
                  <ReportFinanceiroItem
                    key={`despesa__item__${index}`}
                    index__item={index}
                    item={item}
                    checkedChange={checkedChange}
                  />
                );
              })}
            </tbody>
          </table>

          {getTotalizadores()}

          <CheckButtons />
        </ReportBaseResults>
      )}
    </div>
  );
};

export default ReportFinanceiro;
