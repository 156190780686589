import { FC, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import {
  LancamentoAttributes,
  LancamentoFavorecidoPixAttributes,
} from "../../../../../../../../../../libs/types/models/sufl";
import { FormatFunctions } from "../../../../../../../../utils/functions/validations";
import { CHAVES_PIX } from "../../../../main/base";

interface IProps {
  pix?: LancamentoFavorecidoPixAttributes;
  lancamento: LancamentoAttributes;
  updateNfe: (item: LancamentoAttributes) => void;
}

const PagamentoPix: FC<IProps> = ({ pix, lancamento, updateNfe }) => {
  const [chavePix, setChavePix] = useState("");

  useEffect(() => {
    if (pix && pix.st_chave_pix) setChavePix(pix.st_chave_pix);
  }, [pix]);

  const validatePix = () => {
    var result: string | boolean = "";

    if (pix) {
      switch (pix.fl_tipochave_pix) {
        case 1: //CPF
          result = new FormatFunctions(chavePix).cpf();
          break;

        case 2: //CNPJ
          result = new FormatFunctions(chavePix).cnpj();
          break;

        case 3: //Celular
          result = new FormatFunctions(chavePix).celular();
          result = result !== false ? "+55 " + result : false;
          break;

        case 4: //Email
          result = new FormatFunctions(chavePix).email();
          break;

        default:
          break;
      }

      if (result !== false && typeof result === "string") {
        const newPix: LancamentoFavorecidoPixAttributes = {
          ...pix,
          id_fl_lancamento: lancamento.id_fl_lancamento,
          st_chave_pix: result.trim(),
        };

        updateNfe({
          ...lancamento,
          LancamentoFavorecidoPixes: [newPix],
        });
      } else {
        //TODO validação PIX
      }
    }
  };

  const getPlaceholder = () => {
    if (pix) {
      switch (pix.fl_tipochave_pix) {
        case 1: //CPF
          return "000.000.000-00";

        case 2: //CNPJ
          return "00.000.000/0000-00";

        case 3: //Celular
          return "+55 (99) 99999-9999";

        case 4: //E-mail
          return "email@email.com";

        default:
          return "";
      }
    }

    return "";
  };

  return (
    <Row className="mt-2">
      <Col>
        <Form.Group>
          <Form.Label>Tipo de Chave:</Form.Label>
          <Select
            placeholder="Selecione uma forma..."
            options={CHAVES_PIX}
            value={CHAVES_PIX.filter(
              (item) => item.value === pix?.fl_tipochave_pix
            )}
            onChange={(e) => {
              if (e && e.value) {
                const tipoChave = e.value;
                const newPix: LancamentoFavorecidoPixAttributes = {
                  ...pix,
                  id_fl_lancamento: lancamento.id_fl_lancamento,
                  fl_tipochave_pix: tipoChave,
                  st_chave_pix: undefined,
                };
                updateNfe({
                  ...lancamento,
                  LancamentoFavorecidoPixes: [newPix],
                });
                setChavePix("");
              }
            }}
          />
        </Form.Group>
      </Col>

      <Col>
        <Form.Group>
          <Form.Label>Chave:</Form.Label>
          <Form.Control
            placeholder={getPlaceholder()}
            value={chavePix}
            onChange={(e) => setChavePix(e.target.value)}
            onBlur={validatePix}
            disabled={pix?.fl_tipochave_pix === 7}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default PagamentoPix;
