import React, { useEffect, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import Loading from "../loading";

interface IProps {
  children: React.ReactNode;
  title?: string;
  actionButton?: string;
  callButton?: string;
  onInit?: () => void;
  onSubmitAction: () => void;
  onClose?: () => void;
  statusLoading: boolean;
}

const BaseReports: React.FC<IProps> = ({
  children,
  title = "Gerar Relatório",
  actionButton = "Gerar",
  callButton = "Relatório",
  onInit,
  onSubmitAction,
  onClose,
  statusLoading,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(statusLoading);
  }, [statusLoading]);

  return (
    <div>
      <div className="d-flex justify-content-end my-3">
        <Button
          onClick={() => {
            setShowModal(true);
            if (onInit) onInit();
          }}
        >
          {callButton}
        </Button>
      </div>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={() => !loading && setShowModal(false)}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{loading ? <Loading /> : children}</Modal.Body>
        <Modal.Footer>
          <Container>
            <div className="d-flex justify-content-between">
              <div>
                <Button
                  disabled={loading}
                  variant="danger"
                  onClick={() => {
                    setShowModal(false);
                    if (onClose) onClose();
                  }}
                >
                  Cancelar
                </Button>
              </div>
              <div>
                <Button
                  disabled={loading}
                  variant="success"
                  onClick={onSubmitAction}
                >
                  {actionButton}
                </Button>
              </div>
            </div>
          </Container>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BaseReports;
