import React from "react";
import { Form } from "react-bootstrap";

interface IProps {
  index__item: number;
  item: {
    file: File;
    type_file: string;
    new_file_name: string;
  };
  onUpdateItem: (
    item: {
      file: File;
      type_file: string;
      new_file_name: string;
    },
    index: number
  ) => void;
}

const TIPO_ARQUIVOS = [
  { key: 1, label: "BOLETOS", name: "boletos" },
  { key: 2, label: "DEMONSTRATIVOS", name: "demonstrativos" },
  { key: 3, label: "NFe", name: "nfs" },
];

const CondomedFilesItem: React.FC<IProps> = ({
  index__item,
  item,
  onUpdateItem,
}) => {
  return (
    <tr key={`row__forn__${index__item}`}>
      <td className="align-middle text-center">{index__item + 1}</td>

      <td className="align-middle text-center">{item.file.name}</td>

      <td className="align-middle text-center">
        <Form.Select
          size="sm"
          defaultValue={0}
          value={item.type_file === "" ? undefined : item.type_file}
          onChange={(e) =>
            onUpdateItem(
              { ...item, type_file: e.currentTarget.value },
              index__item
            )
          }
        >
          <option value={0} disabled>
            Selecione...
          </option>
          {TIPO_ARQUIVOS.map((item) => {
            return (
              <option key={`file_${item.key}`} value={item.name}>
                {item.label}
              </option>
            );
          })}
        </Form.Select>
      </td>
    </tr>
  );
};

export default CondomedFilesItem;
