import { FC } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { LancamentoAttributes } from "../../../../../../../../../libs/types/models/sufl";
import {
  ClassificacaoTributariaAttributes,
  NaturezaRendimentoAttributes,
  TributacaoServicoAttributes,
} from "../../../../../../../../../libs/types/models/sulo";

import Select, { SingleValue } from "react-select";
import ListFunctions from "../../../../../../../utils/functions/list";

interface IProps {
  lancamento: LancamentoAttributes;
  inssID: number;
  pisID: number;
  classificaoes: ClassificacaoTributariaAttributes[];
  natureza: NaturezaRendimentoAttributes[];
  servico: TributacaoServicoAttributes[];
  updateLancamentoRetencao: (item: LancamentoAttributes) => void;
}

type tRetencao = "Classificacao" | "Servico" | "Natureza";

const FormLancamentoRetencoes: FC<IProps> = ({
  lancamento,
  inssID,
  pisID,
  classificaoes,
  natureza,
  servico,
  updateLancamentoRetencao,
}) => {
  //console.log("lancamento", lancamento);

  const onChange = (
    item: SingleValue<{
      value: string;
      label: string;
    }>,
    type: tRetencao
  ) => {
    if (item) {
      var newLancamento: LancamentoAttributes = lancamento;

      switch (type) {
        case "Classificacao":
          newLancamento.id_classificacaotributaria_des = parseInt(item.value);
          break;
        case "Natureza":
          newLancamento.id_natrend_des = parseInt(item.value);
          break;
        case "Servico":
          newLancamento.id_classservicoprestado_des = parseInt(item.value);
          break;
      }
      updateLancamentoRetencao(newLancamento);
    }
  };

  const checkClassificacao = (): boolean => {
    if (
      lancamento.LancamentoRetencaos &&
      lancamento.LancamentoRetencaos.length > 0
    ) {
      const inss = lancamento.LancamentoRetencaos.find(
        (item) => item.id_rv2_imposto_des === inssID
      );

      if (inss) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  };

  const checkNatureza = (): boolean => {
    if (
      lancamento.LancamentoRetencaos &&
      lancamento.LancamentoRetencaos.length > 0
    ) {
      const pis = lancamento.LancamentoRetencaos.find(
        (item) => item.id_rv2_imposto_des === pisID
      );

      if (pis) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  };

  // const testando = () => {
  //   //console.log("teste");

  //   var x = new ListFunctions().selectOption(
  //     classificaoes.filter(
  //       (item) =>
  //         item.id_classificacaotributaria_ctri ===
  //         lancamento.id_classificacaotributaria_des
  //     ),
  //     "id_classificacaotributaria_ctri",
  //     ["st_codigo_ctri", "st_descricao_ctri"]
  //   );

  //   //console.log("x", x);
  //   console.log(
  //     "lancamento.id_classificacaotributaria_des",
  //     lancamento.id_classificacaotributaria_des
  //   );

  //   const retorno = lancamento.id_classificacaotributaria_des
  //     ?
  //     : undefined;

  //   console.log("retorno", retorno);

  //   return new ListFunctions().selectOption(
  //     classificaoes.filter(
  //       (item) =>
  //         item.id_classificacaotributaria_ctri ===
  //         lancamento.id_classificacaotributaria_des
  //     ),
  //     "id_classificacaotributaria_ctri",
  //     ["st_codigo_ctri", "st_descricao_ctri"]
  //   );
  // };

  // console.log("checkClassificacao", checkClassificacao());
  // console.log("checkNatureza", checkNatureza());

  return (
    <div>
      <Container className="my-4">
        <h5>Atribuições</h5>

        <Row className="mt-2">
          <Col>
            <Form.Group>
              <Form.Label>Classificação Tributária:</Form.Label>
              <Select
                isDisabled={!checkClassificacao()}
                placeholder={
                  checkClassificacao() ? "Selecione..." : "Não aplicável"
                }
                options={new ListFunctions().selectOption(
                  classificaoes,
                  "id_classificacaotributaria_ctri",
                  ["st_codigo_ctri", "st_descricao_ctri"]
                )}
                value={new ListFunctions().selectOption(
                  classificaoes.filter(
                    (item) =>
                      item.id_classificacaotributaria_ctri ===
                      lancamento.id_classificacaotributaria_des
                  ),
                  "id_classificacaotributaria_ctri",
                  ["st_codigo_ctri", "st_descricao_ctri"]
                )}
                onChange={(e) => {
                  onChange(e, "Classificacao");
                }}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col>
            <Form.Group>
              <Form.Label>Classificação do Serviço Prestado:</Form.Label>
              <Select
                isDisabled={!checkClassificacao()}
                placeholder={
                  checkClassificacao() ? "Selecione..." : "Não aplicável"
                }
                options={new ListFunctions().selectOption(
                  servico,
                  "id_classservicoprestado_csp",
                  ["st_codigo_csp", "st_descricao_csp"]
                )}
                value={new ListFunctions().selectOption(
                  servico.filter(
                    (item) =>
                      item.id_classservicoprestado_csp ===
                      lancamento.id_classservicoprestado_des
                  ),
                  "id_classservicoprestado_csp",
                  ["st_codigo_csp", "st_descricao_csp"]
                )}
                onChange={(e) => {
                  onChange(e, "Servico");
                }}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col>
            <Form.Group>
              <Form.Label>Natureza de Rendimento:</Form.Label>
              <Select
                isDisabled={!checkNatureza()}
                placeholder={checkNatureza() ? "Selecione..." : "Não aplicável"}
                options={new ListFunctions().selectOption(
                  natureza,
                  "id_natureza_rendimento",
                  ["st_natureza_rendimento"]
                )}
                value={new ListFunctions().selectOption(
                  natureza.filter(
                    (item) =>
                      item.id_natureza_rendimento === lancamento.id_natrend_des
                  ),
                  "id_natureza_rendimento",
                  ["st_natureza_rendimento"]
                )}
                onChange={(e) => {
                  onChange(e, "Natureza");
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FormLancamentoRetencoes;
