import * as EmailValidator from "email-validator";

import { cnpj, cpf } from "cpf-cnpj-validator";
const { parse, format } = require("telefone");

class FormatFunctions {
  value: string;
  constructor(value: string) {
    this.value = value;
  }

  cpf = () => {
    return cpf.isValid(this.value) ? cpf.format(this.value) : false;
  };

  cnpj = () => {
    return cnpj.isValid(this.value) ? cnpj.format(this.value) : false;
  };

  celular = (remove_inter_code?: boolean, only_numbers?: boolean) => {
    var phone = this.value;

    if (remove_inter_code) phone = phone.replace("+ 55", "").trim();
    if (only_numbers)
      phone = phone.replace("+ 55", "").trim().replace(/\D/g, "");

    return parse(phone) ? format(phone) : false;
  };

  email = () => {
    return EmailValidator.validate(this.value);
  };
}

class ValidateFunctions {
  value: string;
  constructor(value: string) {
    this.value = value;
  }

  cpf = () => {
    return cpf.isValid(this.value);
  };

  cnpj = () => {
    return cnpj.isValid(this.value);
  };

  celular = (remove_inter_code?: boolean, only_numbers?: boolean) => {
    var phone = this.value;

    if (remove_inter_code) phone = phone.replace("+ 55", "").trim();
    if (only_numbers)
      phone = phone.replace("+ 55", "").trim().replace(/\D/g, "");

    return parse(phone);
  };

  email = () => {
    return EmailValidator.validate(this.value);
  };
}

export { FormatFunctions, ValidateFunctions };
