import { FC, useState } from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { LancamentoAttributes } from "../../../../../../../../libs/types/models/sufl";
import MaskFunctions from "../../../../../../utils/functions/masks";
import moment from "../../../../../../utils/moment";
import numeral from "../../../../../../utils/numeral";
import ModalFormTools from "../tools";
import Errors, { ErrorsLancs } from "../validations/errors";

interface IProps {
  lancamento: LancamentoAttributes;
  updateNfe: (item: LancamentoAttributes) => void;
  errors: ErrorsLancs[];
  setErrors: React.Dispatch<React.SetStateAction<ErrorsLancs[]>>;
}

const FormNotaFiscal: FC<IProps> = ({
  lancamento,
  updateNfe,
  errors,
  setErrors,
}) => {
  const [valorParcelas, setValorParcelas] = useState("");

  const getValueParcelas = () => {
    var value = 0;

    if (lancamento.nm_parcelas && lancamento.nm_parcelas > 1) {
      value = lancamento.vl_valor_pdes
        ? lancamento.vl_valor_pdes
        : lancamento.NFe && lancamento.NFe.nfe
        ? lancamento.NFe.nfe / lancamento.nm_parcelas
        : 0;
    }

    return new ModalFormTools().getValue(value);
  };

  //console.log("valorParcelas", valorParcelas);

  return (
    <Accordion defaultActiveKey="0" className="my-3">
      <Accordion.Item eventKey="1">
        <Accordion.Header as="h3">
          Nota Fiscal
          {errors.filter((item) => item.session === "notafiscal").length > 0
            ? ` [${
                errors.filter((item) => item.session === "notafiscal").length
              }]`
            : ""}
        </Accordion.Header>
        <Accordion.Body>
          <Errors errors={errors} session="notafiscal" setErrors={setErrors} />

          <Row className="mt-2">
            <Col>
              <Form.Group>
                <Form.Label>Data de Vencimento:</Form.Label>
                <Form.Control
                  className="value__date"
                  type="date"
                  value={
                    lancamento.dt_vencimentoprimeiraparcela
                      ? moment(
                          lancamento.dt_vencimentoprimeiraparcela,
                          "YYYY-MM-DD"
                        ).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    updateNfe({
                      ...lancamento,
                      dt_vencimentoprimeiraparcela: moment(
                        e.target.value
                      ).toDate(),
                    });
                  }}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>Parcela Inicial:</Form.Label>
                <Form.Control
                  className="value__date"
                  type="number"
                  min={1}
                  value={
                    lancamento.nm_primeiraparcela
                      ? lancamento.nm_primeiraparcela
                      : 1
                  }
                  onChange={(e) => {
                    updateNfe({
                      ...lancamento,
                      nm_primeiraparcela: parseInt(e.target.value),
                    });
                  }}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>Número de Parcelas:</Form.Label>
                <Form.Control
                  className="value__date"
                  type="number"
                  min={1}
                  value={lancamento.nm_parcelas ? lancamento.nm_parcelas : 1}
                  onChange={(e) => {
                    updateNfe({
                      ...lancamento,
                      nm_parcelas: parseInt(e.target.value),
                    });
                  }}
                />
              </Form.Group>
            </Col>

            {lancamento.nm_parcelas && lancamento.nm_parcelas > 1 && (
              <Col>
                <Form.Group>
                  <Form.Label>Valor das Parcelas:</Form.Label>
                  <Form.Control
                    className="value__number"
                    disabled={
                      lancamento.nm_parcelas
                        ? lancamento.nm_parcelas === 1
                          ? true
                          : false
                        : true
                    }
                    value={getValueParcelas()}
                    onFocus={() => {
                      var value = 0;

                      if (
                        lancamento.nm_parcelas &&
                        lancamento.nm_parcelas > 1
                      ) {
                        value = lancamento.vl_valor_pdes
                          ? lancamento.vl_valor_pdes
                          : lancamento.NFe && lancamento.NFe.nfe
                          ? lancamento.NFe.nfe / lancamento.nm_parcelas
                          : 0;
                      }

                      setValorParcelas(numeral(value).format("#,##0.00"));
                    }}
                    onBlur={() => {
                      let val: any = valorParcelas;
                      val = val.toString().replace(".", "");
                      val = val.toString().replace(",", ".");
                      val = parseFloat(val);

                      if (isNaN(val)) {
                        val = "0";
                      }
                      updateNfe({
                        ...lancamento,
                        vl_valor_pdes: parseFloat(val),
                      });
                      setValorParcelas(numeral(val).format("#,##0.00"));
                    }}
                    onChange={(e) => {
                      setValorParcelas(e.target.value.replace(/[^0-9,.]/g, ""));
                    }}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>

          <Row className="mt-2">
            <Col>
              <Form.Group>
                <Form.Label>Condomínio:</Form.Label>
                <Form.Control
                  value={lancamento.Condominio?.st_nome_cond}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>CNPJ:</Form.Label>
                <Form.Control
                  value={new MaskFunctions().cnpjMask(
                    lancamento.Condominio
                      ? lancamento.Condominio.st_cpf_cond
                      : ""
                  )}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <Form.Group>
                <Form.Label>NFe:</Form.Label>
                <Form.Control value={lancamento.NFe?.nfe} disabled />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>Data da NF:</Form.Label>
                <Form.Control
                  className="value__date"
                  value={new ModalFormTools().getDate(lancamento.NFe?.data_nfe)}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>Data de Geração:</Form.Label>
                <Form.Control
                  className="value__date"
                  value={new ModalFormTools().getDate(
                    lancamento.NFe?.data_fato_gerador
                  )}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>Valor:</Form.Label>
                <Form.Control
                  className="value__number"
                  value={new ModalFormTools().getValue(
                    lancamento.NFe?.valor_nfe
                  )}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <Form.Group>
                <Form.Label>PIS:</Form.Label>
                <Form.Control
                  className="value__number"
                  value={new ModalFormTools().getValue(
                    lancamento.NFe?.pis_pasep
                  )}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>Cofins:</Form.Label>
                <Form.Control
                  className="value__number"
                  value={new ModalFormTools().getValue(lancamento.NFe?.cofins)}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>CSLL:</Form.Label>
                <Form.Control
                  className="value__number"
                  value={new ModalFormTools().getValue(lancamento.NFe?.csll)}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>PCC:</Form.Label>
                <Form.Control
                  className="value__number"
                  value={new ModalFormTools().getSumValue([
                    lancamento.NFe?.pis_pasep,
                    lancamento.NFe?.cofins,
                    lancamento.NFe?.csll,
                  ])}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <Form.Group>
                <Form.Label>INSS:</Form.Label>
                <Form.Control
                  className="value__number"
                  value={new ModalFormTools().getValue(lancamento.NFe?.inss)}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>ISS:</Form.Label>
                <Form.Control
                  className="value__number"
                  value={new ModalFormTools().getValue(
                    lancamento.NFe?.iss_devido
                  )}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>IR:</Form.Label>
                <Form.Control
                  className="value__number"
                  value={new ModalFormTools().getValue(lancamento.NFe?.ir)}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <Form.Group>
                <Form.Label>Descrição:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  value={lancamento.NFe?.discriminacao}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default FormNotaFiscal;
