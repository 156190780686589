import { FC, useState } from "react";
import {
  MdDelete,
  MdDeleteOutline,
  MdEdit,
  MdOutlineEdit,
} from "react-icons/md";
import { LancamentoRetencaoAttributes } from "../../../../../../../../../libs/types/models/sufl";
import { ImpostosAttributes } from "../../../../../../../../../libs/types/models/sulo";
import moment from "../../../../../../../utils/moment";
import numeral from "../../../../../../../utils/numeral";

interface IProps {
  item: LancamentoRetencaoAttributes;
  impostos: ImpostosAttributes[];
  id_lancamento_fl: number;
  nfValue: number;
  index: number;
  onEditRetencao: (item: LancamentoRetencaoAttributes, index: number) => void;
  onDeleteRetencao: (idnex: number) => void;
}

const TableApropriacoesItem: FC<IProps> = ({
  item,
  impostos,
  onEditRetencao,
  onDeleteRetencao,
  nfValue,
  index,
}) => {
  const [editRetencao, setEditRetencao] = useState(false);
  const [deleteRetencao, setDeleteRetencao] = useState(false);

  const imposto = impostos.find(
    (i) => i.id_rv2_cod_imp === item.id_rv2_imposto_des
  );

  const name =
    imposto && imposto.st_rv2_nome_imp ? imposto.st_rv2_nome_imp : "-";

  const aliquota =
    imposto && imposto.nm_rv2_aliquota_imp ? imposto.nm_rv2_aliquota_imp : 0;

  const value = item.vl_rv2_valorretido_des
    ? numeral(item.vl_rv2_valorretido_des.toString().replace(".", ",")).format(
        "#,##0.00"
      )
    : 0;

  const percent = numeral(aliquota / 100).format("0.00 %");

  const dateIssue = item.dt_vencimento_pdes
    ? moment(item.dt_vencimento_pdes, "YYYY-MM-DD").format("DD/MM/YYYY")
    : "";

  const vl_rv2_maodeobra_des = item.vl_rv2_maodeobra_des
    ? numeral(item.vl_rv2_maodeobra_des.toString().replace(".", ",")).format(
        "#,##0.00"
      )
    : "-";

  const vl_rv2_material_des = item.vl_rv2_material_des
    ? numeral(item.vl_rv2_material_des.toString().replace(".", ",")).format(
        "#,##0.00"
      )
    : "-";

  const vl_rv2_subempreitada_des = item.vl_rv2_subempreitada_des
    ? numeral(
        item.vl_rv2_subempreitada_des.toString().replace(".", ",")
      ).format("#,##0.00")
    : "-";

  return (
    <tr>
      <td className="align-middle text-center">{index + 1}</td>

      <td className="align-middle text-center">
        <div
          className="button__icon"
          onMouseEnter={() => setEditRetencao(true)}
          onMouseLeave={() => setEditRetencao(false)}
        >
          {editRetencao ? (
            <MdEdit
              size={18}
              title="Editar Apropriação"
              onClick={() => {
                onEditRetencao(item, index);
              }}
            />
          ) : (
            <MdOutlineEdit size={18} />
          )}
        </div>
      </td>

      <td className="align-middle text-center">
        <div
          className="button__icon"
          onMouseEnter={() => setDeleteRetencao(true)}
          onMouseLeave={() => setDeleteRetencao(false)}
        >
          {deleteRetencao ? (
            <MdDelete
              size={18}
              title="Excluir Apropriação"
              onClick={() => onDeleteRetencao(index)}
            />
          ) : (
            <MdDeleteOutline size={18} />
          )}
        </div>
      </td>

      <td className="align-middle text-center">{name}</td>

      <td className="align-middle text-center">{percent}</td>

      <td className="align-middle text-center">{value}</td>

      <td className="align-middle text-center">{dateIssue}</td>

      <td className="align-middle text-center">{vl_rv2_maodeobra_des}</td>

      <td className="align-middle text-center">{vl_rv2_material_des}</td>

      <td className="align-middle text-center">{vl_rv2_subempreitada_des}</td>
    </tr>
  );
};

export default TableApropriacoesItem;
