import { FC } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { LancamentoAttributes } from "../../../../../../../../libs/types/models/sufl";
import { STATUS_LANCAMENTOS } from "../base";
import ModalChangeStatusItem from "./item";

interface IProps {
  changeStatusLancamento:
    | {
        item: LancamentoAttributes[];
        new_status: number;
        obs: string;
      }
    | undefined;
  setChangeStatusLancamento: React.Dispatch<
    React.SetStateAction<
      | {
          item: LancamentoAttributes[];
          new_status: number;
          obs: string;
        }
      | undefined
    >
  >;
  onSubmitChangeStatus: (item: LancamentoAttributes[]) => void;
}

const ModalChangeStatus: FC<IProps> = ({
  changeStatusLancamento,
  setChangeStatusLancamento,
  onSubmitChangeStatus,
}) => {
  const onRemoveItem = (item: LancamentoAttributes) => {
    if (changeStatusLancamento) {
      const newItems = changeStatusLancamento.item.filter(
        (i) => i.id_fl_lancamento !== item.id_fl_lancamento
      );
      setChangeStatusLancamento({ ...changeStatusLancamento, item: newItems });
    }
  };

  return (
    <div>
      {changeStatusLancamento && (
        <Modal
          show={changeStatusLancamento !== undefined}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>Alterar Situação de Lançamento</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Container>
              <Row className="mt-3">
                <Col>
                  <p>
                    Serão alterados {changeStatusLancamento.item.length}{" "}
                    lançamentos:
                  </p>
                  Lançamentos:
                  <small>
                    <ol>
                      {changeStatusLancamento.item.map((item, index) => {
                        return (
                          <ModalChangeStatusItem
                            key={`change__lancs__${index.toString()}`}
                            item={item}
                            onClick={onRemoveItem}
                          />
                        );
                      })}
                    </ol>
                  </small>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col>
                  <Form.Label>Situação:</Form.Label>
                  <Form.Select
                    onChange={(e) => {
                      setChangeStatusLancamento({
                        ...changeStatusLancamento,
                        new_status: parseInt(e.target.value),
                      });
                    }}
                    value={changeStatusLancamento.new_status.toString()}
                  >
                    {STATUS_LANCAMENTOS.map((i) => {
                      return (
                        <option
                          key={i.value.toString()}
                          value={i.value.toString()}
                        >
                          {i.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col>
                  <Form.Label>Observação:</Form.Label>
                  <Form.Control
                    placeholder="Deseja incluir alguma observação?"
                    value={changeStatusLancamento.obs}
                    onChange={(e) =>
                      setChangeStatusLancamento({
                        ...changeStatusLancamento,
                        obs: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <Container>
              <div className="d-flex justify-content-between">
                <Button
                  variant="danger"
                  onClick={() => setChangeStatusLancamento(undefined)}
                >
                  Cancelar
                </Button>
                <Button
                  variant="primary"
                  disabled={
                    0 === changeStatusLancamento.new_status ||
                    changeStatusLancamento.item.length === 0
                  }
                  onClick={() =>
                    onSubmitChangeStatus(changeStatusLancamento.item)
                  }
                >
                  Alterar
                </Button>
              </div>
            </Container>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default ModalChangeStatus;
