import { FC } from "react";
import { Form } from "react-bootstrap";
import { NFesAttributes } from "../../../../../../../../libs/types/models/nfe";
import MaskFunctions from "../../../../../../utils/functions/masks";
import numeral from "../../../../../../utils/numeral";
import BasePreLancs from "../base";

export interface IFornecedores {
  nfe: NFesAttributes;
  id_fl_lancamento: number;
  conds: { nome?: string; nota?: string; valor?: number; id?: number }[];
  checked: boolean;
}

interface IProps {
  fornecedores: IFornecedores[] | null;
  checkedFornecedorChange: (index: number) => void;
  checkedAllFornecedores: () => void;
  uncheckedAllFornecedores: () => void;
  onSubmit: () => void;
}

const Fornecedores: FC<IProps> = ({
  fornecedores,
  checkedFornecedorChange,
  checkedAllFornecedores,
  uncheckedAllFornecedores,
  onSubmit,
}) => {
  return (
    <>
      <BasePreLancs
        checkedAll={checkedAllFornecedores}
        uncheckedAll={uncheckedAllFornecedores}
        data={fornecedores}
        onSubmit={onSubmit}
        title="Fornecedores - Pendentes de Cadastro"
      >
        <table className="despesas__table">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th className="text-center">Incluir</th>
              <th className="text-center">Fornecedor</th>
              <th className="text-center">CNPJ / CPF</th>
              <th className="text-center">NFes Relacionadas</th>
            </tr>
          </thead>
          <tbody>
            {fornecedores &&
              fornecedores.map((item, index) => {
                return (
                  <tr key={`row__forn__${index}`}>
                    <td className="align-middle text-center">{index + 1}</td>
                    <td className="align-middle text-center">
                      <Form.Check
                        type="checkbox"
                        checked={item.checked}
                        onChange={() => checkedFornecedorChange(index)}
                      />
                    </td>
                    <td className="align-middle">{item.nfe.razao_prestador}</td>
                    <td className="align-middle text-center">
                      {new MaskFunctions().cnpjMask(
                        item.nfe.documento_prestador
                      )}
                    </td>
                    <td className="align-middle">
                      {item.conds.map((cond, index__2) => {
                        const value = cond.valor
                          ? numeral(
                              cond.valor.toString().replace(".", ",")
                            ).format("$ 0,0.00")
                          : 0;

                        return index__2 === 0 ? (
                          <div key={`row__forn__details__${index__2}`}>
                            <small>{`NFe: ${cond.nota} - ${cond.nome} (${value})`}</small>
                            <br />
                          </div>
                        ) : (
                          <div key={`row__forn__details__${index__2}`}>
                            <small>{`NFe: ${cond.nota} - ${cond.nome} (${value})`}</small>
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </BasePreLancs>
    </>
  );
};

export default Fornecedores;
