import React from "react";
import { Form } from "react-bootstrap";
import { ICondomedDespesas } from "../../../../../pages/private/lancamentos/condomed";
import moment from "../../../../../utils/moment";
import numeral from "../../../../../utils/numeral";

interface IProps {
  index__item: number;
  item: ICondomedDespesas;
  checkedChange: (lancamento: ICondomedDespesas) => void;
}

const CondomedLancsItem: React.FC<IProps> = ({
  index__item,
  item,
  checkedChange,
}) => {
  return (
    <tr key={`row__forn__${index__item}`}>
      <td className="align-middle text-center">{index__item + 1}</td>

      <td className="align-middle text-center">
        <Form.Check
          type="checkbox"
          checked={item.checked}
          onChange={() => checkedChange(item)}
        />
      </td>

      <td className="align-middle text-center">{item.errors}</td>

      <td className="align-middle text-center">
        {item.type} [{item.ST_DESCRICAO_CONT}]
      </td>

      <td className="align-middle text-center">
        {numeral(item.VL_VALOR_PDES.toString().replace(".", ",")).format(
          "#,##0.00"
        )}
      </td>

      <td className="align-middle text-center">
        {moment(item.DT_DESPESA_DES, "MM/DD/YYYY").format("DD/MM/YYYY")}
      </td>

      <td className="align-middle text-center">
        {moment(item.DT_VENCIMENTOPRIMEIRAPARCELA, "MM/DD/YYYY").format(
          "DD/MM/YYYY"
        )}
      </td>

      <td className="align-middle text-center">{item.ST_DOCUMENTO_DES}</td>

      <td className="align-middle text-center">{item.ST_NOME_COND}</td>

      <td className="align-middle text-center">{item.ST_NOME_CON}</td>

      <td className="align-middle text-center">
        {item.ST_CODIGOBARRAS_PDES} [{item.ST_CODIGOBARRAS_PDES.length}]
      </td>
    </tr>
  );
};

export default CondomedLancsItem;
