import { cnpj } from "cpf-cnpj-validator";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import {
  CondominioAttributes,
  FornecedorAttributes,
} from "../../../../../libs/types/models/sulo";
import ReportsAPI from "../../../services/api/reports";
import SuperlogicaAPI from "../../../services/api/sulo";
import moment from "../../../utils/moment";
import {
  ORDER_LANCAMENTOS,
  STATUS_LANCAMENTOS,
} from "../../lancamentos/pre/components/main/base";
import BaseReports from "../base";

interface IReport {
  dateFrom: Date;
  dateTo: Date;
  status: number;
  condominios?: number[];
  fornecedores?: number[];
  orderBy: number;
  asc: boolean;
}

const INIT: IReport = {
  dateTo: moment().toDate(),
  dateFrom: moment().toDate(),
  status: 3,
  orderBy: 6,
  asc: true,
  condominios: [],
  fornecedores: [],
};

interface Options {
  value: string;
  label: string;
  st_nome: string;
}

const LancsRealizadosModal: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<IReport>(INIT);
  const [fornecedores, setFornecedores] = useState<Options[]>([]);
  const [condominios, setCondominios] = useState<Options[]>([]);

  const getReport = async () => {
    if (filters) {
      setLoading(true);

      try {
        await new ReportsAPI().lancamentosRealizados(filters);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const searchFornecedores = async (inputValue: string) => {
    if (inputValue.length >= 3) {
      const result = await new SuperlogicaAPI().searchFornecedores(inputValue);
      if (result.success) {
        const newList: FornecedorAttributes[] = result.data;

        const newData: Options[] = newList.map((item) => {
          return {
            value: item.id_contato_con ? item.id_contato_con.toString() : "",
            label: `[${item.id_contato_con}] ${item.st_nome_con} / ${
              item.st_fantasia_con
            } (${item.st_cpf_con ? cnpj.format(item.st_cpf_con) : ""})`,
            st_nome: item.st_nome_con,
          };
        });
        return newData;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const promiseFornecedoresOptions = (inputValue: string) => {
    return new Promise<Options[]>((resolve) => {
      resolve(searchFornecedores(inputValue));
    });
  };

  const searchCondominios = async (inputValue: string) => {
    if (inputValue.length >= 3) {
      const result = await new SuperlogicaAPI().searchCondominios(inputValue);

      if (result.success) {
        const newList: CondominioAttributes[] = result.data;

        const newData: Options[] = newList.map((item) => {
          return {
            value: item.id_condominio_cond
              ? item.id_condominio_cond.toString()
              : "",
            label: `[${item.id_condominio_cond}] ${item.st_nome_cond} (${
              item.st_cpf_cond ? cnpj.format(item.st_cpf_cond) : ""
            })`,
            st_nome: item.st_nome_cond,
          };
        });
        return newData;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const promiseCondominiosOptions = (inputValue: string) => {
    return new Promise<Options[]>((resolve) => {
      resolve(searchCondominios(inputValue));
    });
  };

  const onInit = () => {
    setFilters(INIT);
    setCondominios([]);
    setFornecedores([]);
  };

  return (
    <BaseReports
      onSubmitAction={getReport}
      statusLoading={loading}
      onClose={() => setFilters(INIT)}
      onInit={onInit}
      title="Relatório - Lançamentos Realizados"
    >
      <Row className="mt-2">
        <Col>
          <Form.Group>
            <Form.Label>Data Início:</Form.Label>
            <Form.Control
              className="value__date"
              type="date"
              value={
                filters?.dateFrom
                  ? moment(filters.dateFrom, "YYYY-MM-DD").format("YYYY-MM-DD")
                  : moment().format("YYYY-MM-DD")
              }
              onChange={(e) => {
                setFilters({
                  ...filters,
                  dateFrom: moment(
                    moment(e.target.value).format("YYYY-MM-DD")
                  ).toDate(),
                });
              }}
            />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group>
            <Form.Label>Data Fim:</Form.Label>
            <Form.Control
              className="value__date"
              type="date"
              value={
                filters?.dateTo
                  ? moment(filters.dateTo, "YYYY-MM-DD").format("YYYY-MM-DD")
                  : moment().format("YYYY-MM-DD")
              }
              onChange={(e) => {
                setFilters({
                  ...filters,
                  dateTo: moment(
                    moment(e.target.value).format("YYYY-MM-DD")
                  ).toDate(),
                });
              }}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          <Form.Label>Situação:</Form.Label>
          <Form.Select
            onChange={(e) =>
              setFilters({
                ...filters,
                status: parseInt(e.target.value),
              })
            }
          >
            {STATUS_LANCAMENTOS.map((i) => {
              return (
                <option
                  key={i.value.toString()}
                  value={i.value.toString()}
                  selected={filters.status === i.value}
                >
                  {i.name}
                </option>
              );
            })}
          </Form.Select>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          <Form.Group>
            <Form.Label>Condomínios:</Form.Label>
            <AsyncSelect
              placeholder="Digite pelo menos 3 caracteres..."
              cacheOptions
              loadOptions={promiseCondominiosOptions}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  condominios: e.map((i) => {
                    return parseInt(i.value);
                  }),
                });
                setCondominios([...e]);
              }}
              value={condominios}
              noOptionsMessage={() => "Nenhum resultado encontrado"}
              isClearable
              isMulti
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          <Form.Group>
            <Form.Label>Fornecedores:</Form.Label>
            <AsyncSelect
              placeholder="Digite pelo menos 3 caracteres..."
              cacheOptions
              loadOptions={promiseFornecedoresOptions}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  fornecedores: e.map((i) => {
                    return parseInt(i.value);
                  }),
                });
                setFornecedores([...e]);
              }}
              value={fornecedores}
              noOptionsMessage={() => "Nenhum resultado encontrado"}
              isClearable
              isMulti
            />
          </Form.Group>
        </Col>
      </Row>

      <hr />

      <Row className="mt-2">
        <Col>
          <Form.Label>Ordernar Por:</Form.Label>
          <Form.Select
            onChange={(e) =>
              setFilters({
                ...filters,
                orderBy: parseInt(e.target.value),
              })
            }
          >
            {ORDER_LANCAMENTOS.map((i) => {
              return (
                <option
                  value={i.value.toString()}
                  selected={filters.orderBy === i.value}
                >
                  {i.name}
                </option>
              );
            })}
          </Form.Select>
        </Col>

        <Col>
          <Form.Label>Ordenação:</Form.Label>
          <div className="mt-2">
            <Form.Check
              inline
              label="Crescente"
              name="order"
              type="radio"
              id="asc"
              checked={filters.asc}
              onChange={() => setFilters({ ...filters, asc: true })}
            />
            <Form.Check
              inline
              label="Decrescente"
              name="order"
              type="radio"
              id="desc"
              checked={!filters.asc}
              onChange={() => setFilters({ ...filters, asc: false })}
            />
          </div>
        </Col>
      </Row>
    </BaseReports>
  );
};

export default LancsRealizadosModal;
