import { cnpj } from "cpf-cnpj-validator";
import { FC, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { LancamentoAttributes } from "../../../../../../../../../../libs/types/models/sufl";
import {
  FavorecidoAttributes,
  FornecedorAttributes,
} from "../../../../../../../../../../libs/types/models/sulo";
import SuperlogicaAPI from "../../../../../../../../services/api/sulo";
import ListFunctions from "../../../../../../../../utils/functions/list";
import MaskFunctions from "../../../../../../../../utils/functions/masks";

interface IProps {
  lancamento: LancamentoAttributes;
  updateNfe: (item: LancamentoAttributes) => void;
}

interface Options {
  value: string;
  label: string;
  st_nome_con: string;
}

const PagamentoBancario: FC<IProps> = ({ lancamento, updateNfe }) => {
  const [defaulValue, setDefaulValue] = useState<Options[]>([]);
  const [listDadosPagamento, setListDadosPagamento] = useState<
    FavorecidoAttributes[]
  >([]);
  const [dadosPagamento, setDadosPagamento] =
    useState<FavorecidoAttributes | null>(null);

  useEffect(() => {
    var value: Options[] = [];
    if (lancamento.id_favorecido_con && lancamento.st_nomerecebedor_fav) {
      value.push({
        value: lancamento.id_favorecido_con.toString(),
        label: lancamento.st_nomerecebedor_fav,
        st_nome_con: lancamento.st_nomerecebedor_fav,
      });
    }

    setDefaulValue(value);

    loadDadosPagamento();
  }, []);

  useEffect(() => {
    loadDadosPagamento();
  }, [lancamento.id_favorecido_con]);

  const searchFornecedores = async (inputValue: string) => {
    if (inputValue.length >= 3) {
      const result = await new SuperlogicaAPI().searchFornecedores(inputValue);
      if (result.success) {
        const newList: FornecedorAttributes[] = result.data;

        const newData: Options[] = newList.map((item) => {
          return {
            value: item.id_contato_con ? item.id_contato_con.toString() : "",
            label: `[${item.id_contato_con}] ${item.st_nome_con} / ${
              item.st_fantasia_con
            } (${item.st_cpf_con ? cnpj.format(item.st_cpf_con) : ""})`,
            st_nome_con: item.st_nome_con,
          };
        });
        return newData;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const promiseOptions = (inputValue: string) => {
    return new Promise<Options[]>((resolve) => {
      resolve(searchFornecedores(inputValue));
    });
  };

  const updateFavorevido = (e: SingleValue<Options>) => {
    if (e) {
      updateNfe({
        ...lancamento,
        id_favorecido_fav: undefined,
        id_favorecido_con: parseInt(e.value),
        st_nomerecebedor_fav: e.st_nome_con,
      });
      setDefaulValue([e]);
    } else {
      updateNfe({
        ...lancamento,
        id_favorecido_fav: undefined,
        id_favorecido_con: undefined,
        st_nomerecebedor_fav: undefined,
      });
      setDefaulValue([]);
    }
    setDadosPagamento(null);
  };

  const loadDadosPagamento = async () => {
    if (lancamento.id_favorecido_con) {
      const result = await new SuperlogicaAPI().listFavorecidos(
        lancamento.id_favorecido_con
      );
      if (result.success) {
        const list: FavorecidoAttributes[] = result.data;
        setListDadosPagamento(list);
        if (lancamento.id_favorecido_fav) {
          const dados = list.find(
            (item) => item.id_favorecido_fav === lancamento.id_favorecido_fav
          );
          if (dados) setDadosPagamento(dados);
        }
      }
    }
  };

  const updateDadosPagamento = (
    e: SingleValue<{ value: string; label: string }>
  ) => {
    if (e) {
      updateNfe({
        ...lancamento,
        id_favorecido_fav: parseInt(e.value),
      });

      const dados = listDadosPagamento.find(
        (item) => item.id_favorecido_fav.toString() === e.value
      );
      if (dados) setDadosPagamento(dados);
    }
  };

  return (
    <div>
      <Row className="mt-2">
        <Col>
          <Form.Group>
            <Form.Label>Favorecido:</Form.Label>
            <AsyncSelect
              placeholder="Digite pelo menos 3 caracteres..."
              cacheOptions
              loadOptions={promiseOptions}
              onChange={updateFavorevido}
              value={defaulValue}
              defaultOptions={defaulValue}
              noOptionsMessage={() => "Nenhum resultado encontrado"}
              isClearable
            />
          </Form.Group>
        </Col>
      </Row>

      {lancamento.id_favorecido_con && (
        <Row className="mt-2">
          <Col>
            <Form.Group>
              <Form.Label>Dados de Pagamento:</Form.Label>
              <Select
                placeholder="Selecione uma forma..."
                value={new ListFunctions().selectOption(
                  listDadosPagamento.filter(
                    (item) =>
                      item.id_favorecido_fav === lancamento.id_favorecido_fav
                  ),
                  "id_favorecido_fav",
                  ["id_favorecido_fav", "st_nome_con"]
                )}
                onChange={updateDadosPagamento}
                options={new ListFunctions().selectOption(
                  listDadosPagamento,
                  "id_favorecido_fav",
                  ["id_favorecido_fav", "st_nome_con"]
                )}
              />
            </Form.Group>
          </Col>
        </Row>
      )}

      {dadosPagamento && (
        <div>
          <Row className="mt-2">
            <Col>
              <Form.Group>
                <Form.Label>Cód. Banco:</Form.Label>
                <Form.Control
                  className="value__number"
                  value={dadosPagamento.st_banco_fav}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>Banco:</Form.Label>
                <Form.Control
                  className="text-center"
                  value={dadosPagamento.st_nome_banc}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>Agência:</Form.Label>
                <Form.Control
                  className="value__number"
                  value={dadosPagamento.st_agencia_fav}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>Conta:</Form.Label>
                <Form.Control
                  className="value__number"
                  value={dadosPagamento.st_contabancaria_fav}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <Form.Group>
                <Form.Label>Favorecido:</Form.Label>
                <Form.Control
                  className="text-center"
                  value={dadosPagamento.st_nomerecebedor}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>Documento:</Form.Label>
                <Form.Control
                  className="value__number"
                  value={
                    dadosPagamento.st_cpfcnpjrecebedor_fav.length === 11
                      ? new MaskFunctions().cpfMask(
                          dadosPagamento.st_cpfcnpjrecebedor_fav
                        )
                      : new MaskFunctions().cnpjMask(
                          dadosPagamento.st_cpfcnpjrecebedor_fav
                        )
                  }
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default PagamentoBancario;
