import { FC, useEffect, useState } from "react";
import { ButtonGroup, Dropdown, DropdownButton, Form } from "react-bootstrap";
import {
  RiSortAlphabetAsc,
  RiSortAlphabetDesc,
  RiSortNumberAsc,
  RiSortNumberDesc,
} from "react-icons/ri";
import { LancamentoAttributes } from "../../../../../../../../libs/types/models/sufl";
import { ImpostosAttributes } from "../../../../../../../../libs/types/models/sulo";
import SortFunctions from "../../../../../../utils/functions/sorts";
import BasePreLancs from "../base";
import { ResumedLancsErrors } from "../validations";
import LancamentoItem from "./subitem";

export interface ILancamento {
  lancamento: LancamentoAttributes;
  checked: boolean;
  errors: ResumedLancsErrors[];
}

interface IProps {
  lancamentos: ILancamento[];
  impostos: ImpostosAttributes[] | null;
  checkedChange: (lancamento: ILancamento) => void;
  checkedAllLancamentos: () => void;
  uncheckedAllLancamentos: () => void;
  hideUndefinedFornecedores: () => void;
  hideFornecedores: boolean;
  onSubmit: () => void;
  onAttachClick: (id?: number, name?: string) => void;
  onEditClick: (item: ILancamento) => void;
  restoreLancmento: (item: ILancamento) => void;
  onChangeStatus: (item: ILancamento[]) => void;
  onSortLancamento: (lancamentos: ILancamento[]) => void;
}

interface IColumn {
  id: number;
  class: string;
  name: string;
  canOrder: boolean;
  orderAsc?: boolean;
  colSpan: number;
  orderType: string;
  name_column?: string;
  funcAsc?: (a: ILancamento, b: ILancamento) => any;
  funcDesc?: (a: ILancamento, b: ILancamento) => any;
}

/*
if (item.errors.filter((i) => i.type === "danger").length > 0) {
      setStatusLancamento(0);
    } else if (item.errors.filter((i) => i.type === "warning").length > 0) {
      setStatusLancamento(1);
    } else {
      setStatusLancamento(2);
    }
*/

const columns: IColumn[] = [
  {
    id: 0,
    class: "text-center",
    name: "#",
    canOrder: false,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "",
    name_column: undefined,
  },
  {
    id: 1,
    class: "text-center order__button",
    name: "ID",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "number",
    name_column: "id",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.lancamento.id_fl_lancamento,
        b.lancamento.id_fl_lancamento
      ).sortNumberAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.lancamento.id_fl_lancamento,
        b.lancamento.id_fl_lancamento
      ).sortNumberDesc(),
  },
  {
    id: 2,
    class: "text-center",
    name: "Incluir",
    canOrder: false,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "",
    name_column: undefined,
  },
  {
    id: 3,
    class: "text-center order__button",
    name: "Info",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "number",
    name_column: "status",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.errors.filter((i) => i.type === "danger").length > 0
          ? 2
          : a.errors.filter((i) => i.type === "warning").length > 0
          ? 1
          : 0,
        b.errors.filter((i) => i.type === "danger").length > 0
          ? 2
          : b.errors.filter((i) => i.type === "warning").length > 0
          ? 1
          : 0
      ).sortNumberAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.errors.filter((i) => i.type === "danger").length > 0
          ? 2
          : a.errors.filter((i) => i.type === "warning").length > 0
          ? 1
          : 0,
        b.errors.filter((i) => i.type === "danger").length > 0
          ? 2
          : b.errors.filter((i) => i.type === "warning").length > 0
          ? 1
          : 0
      ).sortNumberDesc(),
  },
  {
    id: 5,
    class: "text-center order__button",
    name: "NFe",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 5,
    orderType: "number",
    name_column: "st_documento_des",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.lancamento.st_documento_des
          ? parseInt(a.lancamento.st_documento_des)
          : 0,
        b.lancamento.st_documento_des
          ? parseInt(b.lancamento.st_documento_des)
          : 0
      ).sortNumberAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.lancamento.st_documento_des
          ? parseInt(a.lancamento.st_documento_des)
          : 0,
        b.lancamento.st_documento_des
          ? parseInt(b.lancamento.st_documento_des)
          : 0
      ).sortNumberDesc(),
  },
  {
    id: 6,
    class: "text-center order__button",
    name: "Data NFe",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "date",
    name_column: "dt_despesa_des",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.lancamento.dt_despesa_des,
        b.lancamento.dt_despesa_des
      ).sortDateAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.lancamento.dt_despesa_des,
        b.lancamento.dt_despesa_des
      ).sortDateDesc(),
  },
  {
    id: 7,
    class: "text-center order__button",
    name: "Vencimento",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "date",
    name_column: "dt_vencimentoprimeiraparcela",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.lancamento.dt_vencimentoprimeiraparcela,
        b.lancamento.dt_vencimentoprimeiraparcela
      ).sortDateAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.lancamento.dt_vencimentoprimeiraparcela,
        b.lancamento.dt_vencimentoprimeiraparcela
      ).sortDateDesc(),
  },
  {
    id: 8,
    class: "text-center order__button",
    name: "Valor",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "float",
    name_column: "vl_fl_nfe",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.lancamento.vl_fl_nfe,
        b.lancamento.vl_fl_nfe
      ).sortNumberAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.lancamento.vl_fl_nfe,
        b.lancamento.vl_fl_nfe
      ).sortNumberDesc(),
  },
  {
    id: 9,
    class: "text-center order__button",
    name: "Fornecedor",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "string",
    name_column: "st_nome_con",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.lancamento.st_nome_con,
        b.lancamento.st_nome_con
      ).sortStrAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.lancamento.st_nome_con,
        b.lancamento.st_nome_con
      ).sortStrDesc(),
  },
  {
    id: 10,
    class: "text-center order__button",
    name: "Condomínio",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "string",
    name_column: "condominio",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.lancamento.Condominio?.st_nome_cond,
        b.lancamento.Condominio?.st_nome_cond
      ).sortStrAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.lancamento.Condominio?.st_nome_cond,
        b.lancamento.Condominio?.st_nome_cond
      ).sortStrDesc(),
  },
  {
    id: 11,
    class: "text-center order__button",
    name: "Forma Pagto.",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "string",
    name_column: "id_forma_pag",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.lancamento.id_forma_pag,
        b.lancamento.id_forma_pag
      ).sortNumberAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.lancamento.id_forma_pag,
        b.lancamento.id_forma_pag
      ).sortNumberDesc(),
  },
  {
    id: 12,
    class: "text-center order__button",
    name: "Apropriações",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "string",
    name_column: "apropriacao",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.lancamento.LancamentoApropriacaos?.length,
        b.lancamento.LancamentoApropriacaos?.length
      ).sortNumberAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.lancamento.LancamentoApropriacaos?.length,
        b.lancamento.LancamentoApropriacaos?.length
      ).sortNumberDesc(),
  },
  {
    id: 13,
    class: "text-center order__button",
    name: "Retenções",
    canOrder: true,
    orderAsc: undefined,
    colSpan: 1,
    orderType: "string",
    name_column: "retencao",
    funcAsc: (a, b) =>
      new SortFunctions(
        a.lancamento.LancamentoRetencaos?.length,
        b.lancamento.LancamentoRetencaos?.length
      ).sortNumberAsc(),
    funcDesc: (a, b) =>
      new SortFunctions(
        a.lancamento.LancamentoRetencaos?.length,
        b.lancamento.LancamentoRetencaos?.length
      ).sortNumberDesc(),
  },
];

const iconSortSize = 20;

const Lancamentos: FC<IProps> = ({
  lancamentos,
  impostos,
  checkedChange,
  checkedAllLancamentos,
  uncheckedAllLancamentos,
  hideUndefinedFornecedores,
  hideFornecedores,
  onSubmit,
  onAttachClick,
  onEditClick,
  onChangeStatus,
  restoreLancmento,
  onSortLancamento,
}) => {
  const [renderColumns, setRenderColumns] = useState<IColumn[]>(columns);

  useEffect(() => {
    const order = renderColumns.find((i) => i.orderAsc !== undefined);
    if (order) {
      let lancsSort: ILancamento[] = [...lancamentos];
      lancsSort.sort((a, b) => {
        if (order.orderAsc) {
          return order.funcAsc ? order.funcAsc(a, b) : 0;
        } else {
          return order.funcDesc ? order.funcDesc(a, b) : 0;
        }
      });
      onSortLancamento(lancsSort);
    }
  }, [renderColumns]);

  const orderColumns = (column: IColumn) => {
    const newOrder: IColumn[] = renderColumns.map((c) => {
      var order: IColumn = {
        ...c,
        orderAsc: undefined,
      };

      if (column.id === c.id) {
        if (c.orderAsc === undefined) {
          order.orderAsc = true;
        } else {
          order.orderAsc = !c.orderAsc;
        }
      }

      return order;
    });

    setRenderColumns(newOrder);
  };

  const getIconSort = (column: IColumn) => {
    if (column.canOrder) {
      if (column.orderType === "string") {
        if (column.orderAsc === true) {
          return (
            <div className="mx-1">
              <RiSortAlphabetAsc size={iconSortSize} />
            </div>
          );
        } else if (column.orderAsc === false) {
          return (
            <div className="mx-1">
              <RiSortAlphabetDesc size={iconSortSize} />
            </div>
          );
        }
      } else {
        if (column.orderAsc === true) {
          return (
            <div className="mx-1">
              <RiSortNumberAsc size={iconSortSize} />
            </div>
          );
        } else if (column.orderAsc === false) {
          return (
            <div className="mx-1">
              <RiSortNumberDesc size={iconSortSize} />
            </div>
          );
        }
      }
    }

    return null;
  };

  const changeStatusSelected = () => {
    const newLancamentos = lancamentos
      .filter((item) => item.checked)
      .map((item) => {
        return item;
      });
    onChangeStatus(newLancamentos);
  };

  const changeStatusWithoutSupplier = () => {
    const newLancamentos = lancamentos
      .filter(
        (item) =>
          item.lancamento.id_contato_con === undefined ||
          item.lancamento.id_contato_con === null
      )
      .map((item) => {
        return item;
      });
    onChangeStatus(newLancamentos);
  };

  const changeStatusWithErrors = () => {
    var newLancamentos = lancamentos
      .filter(
        (item) => item.errors.filter((e) => e.type === "danger").length > 0
      )
      .map((item) => {
        return item;
      });

    onChangeStatus(newLancamentos);
  };

  const changeStatusAll = () => {
    onChangeStatus(lancamentos);
  };
  return (
    <>
      <BasePreLancs
        checkedAll={checkedAllLancamentos}
        uncheckedAll={uncheckedAllLancamentos}
        data={lancamentos}
        onSubmit={onSubmit}
        title="Lançamentos"
      >
        <div className="d-flex justify-content-between my-2">
          <Form.Check
            type="switch"
            checked={hideFornecedores}
            onChange={hideUndefinedFornecedores}
            label={
              !hideFornecedores
                ? "Mostrar Lançamentos sem Fornecedores"
                : "Ocultar Lançamentos sem Fornecedores"
            }
            id="hideForn"
          />
          <DropdownButton
            data-bs-theme="dark"
            as={ButtonGroup}
            variant="outline-secondary"
            title="Ações"
            size="sm"
          >
            <Dropdown.Item onClick={changeStatusSelected}>
              Alterar Situação (Selecionados)
            </Dropdown.Item>
            <Dropdown.Item onClick={changeStatusWithoutSupplier}>
              Alterar Situação (Sem Fornecedor)
            </Dropdown.Item>
            <Dropdown.Item onClick={changeStatusWithErrors}>
              Alterar Situação (Com Erros)
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={changeStatusAll}>
              Alterar Situação de TODOS (Em Tela)
            </Dropdown.Item>
          </DropdownButton>
        </div>

        <table className="despesas__table">
          <thead>
            <tr>
              {renderColumns.map((column) => {
                return (
                  <th
                    key={`column__lancs__${column.id.toString()}`}
                    className={column.class}
                    colSpan={column.colSpan}
                    onClick={() => {
                      if (column.canOrder) orderColumns(column);
                    }}
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      {column.name}

                      {getIconSort(column)}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {lancamentos &&
              lancamentos.map((item, index) => {
                const disabled =
                  item.lancamento.id_contato_con === null ||
                  item.lancamento.Condominio === null;

                if (hideFornecedores && disabled) return null;

                return (
                  <LancamentoItem
                    key={`lancamento__item__${index}`}
                    impostos={impostos}
                    checkedChange={checkedChange}
                    index__item={index}
                    item={item}
                    disabled={disabled}
                    onAttachClick={(id, name) => onAttachClick(id, name)}
                    onEditClick={onEditClick}
                    restoreLancmento={restoreLancmento}
                    onChangeStatus={onChangeStatus}
                  />
                );
              })}
          </tbody>
        </table>
      </BasePreLancs>
    </>
  );
};

export default Lancamentos;
