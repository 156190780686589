import React, { useEffect } from "react";
import { Container, Modal, Offcanvas } from "react-bootstrap";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../components/loading";
import Notifications from "../../../components/notifications";
import { useAlertContext } from "../../../context/alerts";

interface DefaultContentPrivateProps {
  children: (navigate: NavigateFunction) => React.ReactNode;
  loading?: boolean;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const DefaultContentPrivate: React.FC<DefaultContentPrivateProps> = ({
  children,
  loading,
  setLoading,
}) => {
  const navigate = useNavigate();
  const params = useLocation().search;
  const { showAlert, alerts, addAlert, removeAlert, setShowAlert } =
    useAlertContext();

  useEffect(() => {}, [setLoading]);

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Offcanvas
        data-bs-theme="dark"
        backdropClassName="text-bg-dark"
        show={showAlert}
        onHide={setShowAlert}
        scroll
      >
        <Offcanvas.Header closeButton style={{ backgroundColor: "#767aef" }}>
          <Offcanvas.Title>Notificações e Alertas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ backgroundColor: "#f2f2f2" }}>
          <Notifications
            hide={() => {}}
            messages={alerts}
            remove={(e) => removeAlert(e)}
          />
        </Offcanvas.Body>
      </Offcanvas>

      {loading ? (
        <Modal show={loading} fullscreen style={{ opacity: "0.8" }}>
          {/* <div style={{ marginTop: 200 }}> */}
          <Loading title="Por favor, aguarde..." />
          {/* </div> */}
        </Modal>
      ) : (
        <Container fluid>
          <div style={{ padding: "10px" }}>{children(navigate)}</div>
        </Container>
      )}
    </div>
  );
};

export default DefaultContentPrivate;
