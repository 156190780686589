import React, { createContext, useContext, useState } from "react";
import { ILogin } from "../../../libs/types/users";
import StorageItems from "../storage";

interface AuthContextType {
  authToken: ILogin | null;
  setAuthToken: (token: ILogin | null) => void;
}

const AuthContext = createContext<AuthContextType>({
  authToken: null,
  setAuthToken: (token: ILogin | null) => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [authToken, setAuthToken] = useState<ILogin | null>(
    new StorageItems().get("authToken")
  );

  const saveAuthToken = (token: ILogin | null) => {
    if (token) {
      new StorageItems().set("authToken", token);
    } else {
      new StorageItems().remove("authToken");
    }
    setAuthToken(token);
  };

  return (
    <AuthContext.Provider value={{ authToken, setAuthToken: saveAuthToken }}>
      {children}
    </AuthContext.Provider>
  );
};
