export default class MaskFunctions {
  cpfMask = (value: string) => {
    return value
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  };

  cnpjMask = (value: string) => {
    value = value.replace(/\D/g, "");
    value = value.padStart(14, "0");
    return value.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      "$1.$2.$3/$4-$5"
    );
  };

  mobileMask = (value: string) => {
    return value
      .replace(/\D/g, "") //Remove tudo o que não é dígito
      .replace(/^(\d{2})(\d)/g, "($1) $2") //Coloca parênteses em volta dos dois primeiros dígitos
      .replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
  };

  zipcodeMask = (value: string) => {
    return value
      .replace(/\D/g, "") // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada; //Remove tudo o que não é dígito
      .replace(/^([\d]{5})\.?([\d]{3})/, "$1-$2");
  };
}
