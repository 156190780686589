import { createContext, useContext, useState } from "react";
import { IAlertMessage } from "../../../libs/types/messages";

interface IAlertContext {
  showAlert: boolean;
  alerts: IAlertMessage[];
  setShowAlert: (showAlert: boolean) => void;
  addAlert: (alert: IAlertMessage) => void;
  removeAlert: (key: string) => void;
}

const AlertContext = createContext<IAlertContext>({
  showAlert: false,
  alerts: [],
  setShowAlert: () => {},
  addAlert: () => {},
  removeAlert: () => {},
});

export const useAlertContext = () => useContext(AlertContext);

export const AlertProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alerts, setAlerts] = useState<IAlertMessage[]>([]);

  const addAlert = (alert: IAlertMessage) => {
    setAlerts((prevAlerts) => [...prevAlerts, alert]);
    //setShowAlert(true);
  };

  const removeAlert = (key: string) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.key !== key));
    //if (alerts.length === 1) setShowAlert(false);
  };

  return (
    <AlertContext.Provider
      value={{ showAlert, alerts, setShowAlert, addAlert, removeAlert }}
    >
      {children}
    </AlertContext.Provider>
  );
};
