import DefaultContentPrivate from "../defaultContent";

const HomePage = () => {
  return (
    <DefaultContentPrivate>
      {(navigate) => {
        return (
          <div>
            <h1>Private Home</h1>
          </div>
        );
      }}
    </DefaultContentPrivate>
  );
};
export default HomePage;
