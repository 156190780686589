import axios, { AxiosRequestConfig } from "axios";
import API from "..";

class ReportsAPI extends API {
  lancamentosRealizados = async (data: any): Promise<any> => {
    var config: AxiosRequestConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${this.base_url}/reports/lancamentos-realizados`,
      responseType: "blob",
      headers: {
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
      data: { ...data },
    };

    await axios(config)
      .then((response) => {
        const file = new Blob([response.data], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => {
        console.error("Erro ao gerar PDF:", error);
      });

    return this.result;
  };

  financeiro = async (data: any): Promise<any> => {
    var config: AxiosRequestConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${this.base_url}/reports/financeiro`,
      responseType: "blob",
      headers: {
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
      data,
    };

    await axios(config)
      .then((response) => {
        const file = new Blob([response.data], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => {
        console.error("Erro ao gerar PDF:", error);
      });

    return this.result;
  };
}

export default ReportsAPI;
