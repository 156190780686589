import axios, { AxiosRequestConfig } from "axios";
import API from "..";
import { IReturnHTTP } from "../../../../../libs/types/returns";

class SuperlogicaAPI extends API {
  create = async (data: any): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${this.base_url}/user/create`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  listClassificacoesTributarias = async (): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${this.base_url}/sulo/list/classificacoes-tributarias`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  listTributacaoServico = async (): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${this.base_url}/sulo/list/tributacao-servico`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  listNaturezaRendimento = async (): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${this.base_url}/sulo/list/natureza-rendimento`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  listImpostos = async (): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${this.base_url}/sulo/list/impostos`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  listFavorecidoPix = async (id?: any): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${this.base_url}/sulo/list/favorecido/pix/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  listPlanosCondominios = async (): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${this.base_url}/sulo/list/planos`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  listPlanos = async (id?: any): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${this.base_url}/sulo/list/planos/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  listFavorecidos = async (id?: any): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${this.base_url}/sulo/list/favorecidos/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  searchFornecedores = async (data?: any): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${this.base_url}/sulo/search/fornecedores`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
      data: { searchTerm: data },
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  searchCondominios = async (data?: any): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${this.base_url}/sulo/search/condominios`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
      data: { searchTerm: data },
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  listGrupoSaldos = async (id?: any): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${this.base_url}/sulo/list/gruposaldos/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  listGruposCondominios = async (): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${this.base_url}/sulo/list/gruposcondominios`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  listDespesas = async (data?: any): Promise<IReturnHTTP> => {
    var config: AxiosRequestConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${this.base_url}/sulo/list/despesas`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
      data,
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };

  updateRemessas = async (data?: any) => {
    var config: AxiosRequestConfig = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${this.base_url}/sulo/despesas/remessas`,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.token ? `Bearer ${this.token}` : "",
      },
      data,
    };

    this.result = await axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        try {
          return error.response.data;
        } catch (errorCatch) {
          this.result.message = error.message;
          return this.result;
        }
      });

    return this.result;
  };
}

export default SuperlogicaAPI;
