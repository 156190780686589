import { FC, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import Select from "react-select";
import { LancamentoApropriacaoAttributes } from "../../../../../../../../../libs/types/models/sufl";
import {
  GrupoSaldosAttributes,
  PlanoAttributes,
  PlanoCondominioAttributes,
} from "../../../../../../../../../libs/types/models/sulo";
import SuperlogicaAPI from "../../../../../../../services/api/sulo";
import ListFunctions from "../../../../../../../utils/functions/list";
import numeral from "../../../../../../../utils/numeral";

interface IProps {
  showForm: {
    show: boolean;
    edit: boolean;
    value: string;
    index?: number;
  };
  setShowForm: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      edit: boolean;
      value: string;
      index?: number;
    }>
  >;
  newApropriacao:
    | {
        lancamento?: LancamentoApropriacaoAttributes | undefined;
        planoCondominio?: PlanoCondominioAttributes | undefined;
        plano?: PlanoAttributes | undefined;
      }
    | null
    | undefined;
  setNewApropriacao: React.Dispatch<
    React.SetStateAction<
      | {
          lancamento?: LancamentoApropriacaoAttributes | undefined;
          planoCondominio?: PlanoCondominioAttributes | undefined;
          plano?: PlanoAttributes | undefined;
        }
      | null
      | undefined
    >
  >;
  id_lancamento_fl: number;
  nfValue: number;
  grupoSaldos: GrupoSaldosAttributes[];
  submitForm: (item?: LancamentoApropriacaoAttributes, index?: number) => void;
}

const FormNewApropriacoes: FC<IProps> = ({
  showForm,
  setShowForm,
  newApropriacao,
  setNewApropriacao,
  id_lancamento_fl,
  nfValue,
  grupoSaldos,
  submitForm,
}) => {
  const [planosCondominio, setPlanosCondominio] = useState<
    PlanoCondominioAttributes[]
  >([]);
  const [planos, setPlanos] = useState<PlanoAttributes[]>([]);

  useEffect(() => {
    const loadPlanosCondominios = async () => {
      const result = await new SuperlogicaAPI().listPlanosCondominios();
      if (result.success) setPlanosCondominio(result.data);
    };

    loadPlanosCondominios();
  }, []);

  const loadPlanos = async (id?: string) => {
    const result = await new SuperlogicaAPI().listPlanos(id);
    if (result.success) {
      const planoCon = planosCondominio.find(
        (item) => item.id_planoconta_plc.toString() === id
      );
      setPlanos(result.data);
      setNewApropriacao({
        ...newApropriacao,
        planoCondominio: planoCon,
        plano: undefined,
      });
    }
  };

  const updatePlano = (id?: string) => {
    const plano = planos.find((item) => item.id_sl_plano.toString() === id);
    setNewApropriacao({
      ...newApropriacao,
      plano: plano,
    });
  };

  const cancelForm = () => {
    setNewApropriacao({
      lancamento: undefined,
      plano: undefined,
      planoCondominio: newApropriacao?.planoCondominio,
    });
    setShowForm({ edit: false, show: false, value: "" });
  };

  const getValues = () => {
    if (newApropriacao) {
      if (showForm.edit) {
        return (
          <Row className="mt-2">
            <Col>
              <Form.Label>Complemento:</Form.Label>
              <Form.Control
                value={newApropriacao.lancamento?.st_complemento_apro}
                onChange={(e) => {
                  setNewApropriacao({
                    ...newApropriacao,
                    lancamento: {
                      ...newApropriacao.lancamento,
                      id_fl_lancamento: id_lancamento_fl,
                      st_complemento_apro: e.target.value,
                    },
                  });
                }}
              />
            </Col>

            <Col>
              <Form.Label>Valor:</Form.Label>
              <Form.Control
                className="value__number"
                type="text"
                value={showForm.value}
                onBlur={() => {
                  let val: any = showForm.value;
                  val = val.replace(".", "");
                  val = val.replace(",", ".");
                  val = parseFloat(val);

                  if (isNaN(val)) {
                    val = "0";
                  }

                  setNewApropriacao({
                    ...newApropriacao,
                    lancamento: {
                      ...newApropriacao.lancamento,
                      id_fl_lancamento: id_lancamento_fl,
                      vl_valor_pdes: parseFloat(val),
                    },
                  });
                  setShowForm({
                    ...showForm,
                    value: numeral(val).format("#,##0.00"),
                  });
                }}
                onChange={(e) => {
                  setShowForm({
                    ...showForm,
                    value: e.target.value.replace(/[^0-9,.]/g, ""),
                  });
                }}
              />
            </Col>
          </Row>
        );
      } else {
        if (newApropriacao.plano) {
          const value = numeral(nfValue.toString().replace(".", ",")).format(
            "#,##0.00"
          );
          return (
            <Row className="mt-2">
              <Col>
                <Form.Label>Complemento:</Form.Label>
                <Form.Control
                  value={newApropriacao.lancamento?.st_complemento_apro}
                  onChange={(e) => {
                    setNewApropriacao({
                      ...newApropriacao,
                      lancamento: {
                        id_fl_lancamento: id_lancamento_fl,
                        ...newApropriacao.lancamento,
                        st_complemento_apro: e.target.value,
                      },
                    });
                  }}
                />
              </Col>

              <Col>
                <Form.Label>Valor:</Form.Label>
                <Form.Control
                  className="value__number"
                  type="text"
                  value={showForm.value}
                  onFocus={() => {
                    if (showForm.value === "") {
                      setShowForm({
                        ...showForm,
                        value,
                      });
                    }
                  }}
                  onBlur={() => {
                    let val: any = showForm.value;
                    val = val.replace(".", "");
                    val = val.replace(",", ".");
                    val = parseFloat(val);

                    if (isNaN(val)) {
                      val = "0";
                    }

                    setNewApropriacao({
                      ...newApropriacao,
                      lancamento: {
                        ...newApropriacao.lancamento,
                        id_fl_lancamento: id_lancamento_fl,
                        vl_valor_pdes: parseFloat(val),
                      },
                    });
                    setShowForm({
                      ...showForm,
                      value: numeral(val).format("#,##0.00"),
                    });
                  }}
                  onChange={(e) => {
                    setShowForm({
                      ...showForm,
                      value: e.target.value.replace(/[^0-9,.]/g, ""),
                    });
                  }}
                />
              </Col>
            </Row>
          );
        }
      }
    } else {
      return null;
    }
  };

  const submit = () => {
    if (showForm.edit) {
      submitForm(newApropriacao?.lancamento, showForm.index);
    } else {
      submitForm();
    }
  };

  return (
    <>
      {showForm.show && (
        <Container className="mt-4">
          <h5>
            {showForm.edit ? "Alteração de Apropriação" : "Nova Apropriação"}
          </h5>

          {!showForm.edit && (
            <Row className="mt-2">
              <Col>
                <Form.Label>Plano Padrão:</Form.Label>
                <Select
                  placeholder="Selecione o Plano Modelo"
                  options={new ListFunctions().selectOption(
                    planosCondominio,
                    "id_planoconta_plc",
                    ["st_nome_plc"]
                  )}
                  onChange={(e) => {
                    loadPlanos(e?.value);
                  }}
                  value={new ListFunctions().selectOption(
                    planosCondominio.filter(
                      (item) =>
                        item.id_planoconta_plc ===
                        newApropriacao?.planoCondominio?.id_planoconta_plc
                    ),
                    "id_planoconta_plc",
                    ["st_nome_plc"]
                  )}
                />
              </Col>

              <Col>
                <Form.Label>Apropriação:</Form.Label>
                <Select
                  isDisabled={planos.length === 0}
                  placeholder="Selecione a Apropriação..."
                  options={new ListFunctions().selectOption(
                    planos,
                    "id_sl_plano",
                    ["st_conta_cont", "st_descricao_cont"]
                  )}
                  onChange={(e) => updatePlano(e?.value)}
                />
              </Col>
            </Row>
          )}

          {newApropriacao && (
            <Row className="mt-2">
              <Col>
                <Form.Label>Grupos de Saldo:</Form.Label>
                <Select
                  placeholder="Selecione o Grupo de Saldo"
                  options={new ListFunctions().selectOption(
                    grupoSaldos.sort((a, b) => {
                      return (
                        (a.nm_ordem_gs ? a.nm_ordem_gs : 1) -
                        (b.nm_ordem_gs ? b.nm_ordem_gs : 0)
                      );
                    }),
                    "id",
                    ["label"]
                  )}
                  onChange={(e) => {
                    setNewApropriacao({
                      ...newApropriacao,
                      lancamento: {
                        ...newApropriacao.lancamento,
                        id_fl_lancamento: id_lancamento_fl,
                        st_nomegruposaldo_gs: grupoSaldos.find(
                          (i) => i.label?.toUpperCase().trim() === e?.label
                        )?.st_nomegruposaldo_gs,
                      },
                    });
                  }}
                  value={new ListFunctions().selectOption(
                    grupoSaldos
                      .sort((a, b) => {
                        return (
                          (a.nm_ordem_gs ? a.nm_ordem_gs : 1) -
                          (b.nm_ordem_gs ? b.nm_ordem_gs : 0)
                        );
                      })
                      .filter(
                        (item) =>
                          item.st_nomegruposaldo_gs ===
                          newApropriacao.lancamento?.st_nomegruposaldo_gs
                      ),
                    "id",
                    ["label"]
                  )}
                />
              </Col>
            </Row>
          )}

          {getValues()}

          <Row className="mt-2">
            <div className=" d-flex justify-content-end">
              <Stack direction="horizontal" gap={3} className="btn-group-sm">
                <Button variant="outline-secondary" onClick={cancelForm}>
                  Cancelar
                </Button>
                <Button variant="outline-primary" onClick={submit}>
                  {showForm.edit ? "Editar" : "Adicionar"}
                </Button>
              </Stack>
            </div>
          </Row>
        </Container>
      )}
    </>
  );
};

export default FormNewApropriacoes;
