import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { tVariants } from "../../../../libs/types/messages";
import Input from "./items";

type tTypes =
  | "text"
  | "email"
  | "phone"
  | "number"
  | "password"
  | "date"
  | "datetime";

export interface FieldForm {
  name: string;
  value?: any;
  disabled?: boolean;
  select?: {
    options: { value: string; text: string }[];
  };
  label: string;
  type: tTypes;
  lg_col?: number;
  md_col?: number;
  sm_col?: number;
  validations?: {
    required?: string;
    max?: number;
    min?: number;
    minLength?: number;
    maxLength?: number;
    pattern?: { value: RegExp; message: string };
    validate?: any;
  };
}

export interface Links {
  name: string;
  href: string;
}

export interface Props {
  fields: FieldForm[];
  links?: Links[];
  submitName?: string;
  variant?: tVariants;
  showButtons?: boolean;
  resetButton?: boolean;
  gobackButton?: boolean;
  buttonsEnd?: boolean;
  onSubmit?: (data: Record<string, any>) => void;
  onChange?: (data: any) => void;
}

const FormComponent: React.FC<Props> = ({
  fields,
  links,
  onSubmit,
  onChange,
  submitName,
  variant = "primary",
  showButtons = true,
  resetButton = false,
  gobackButton = false,
  buttonsEnd = false,
}) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const formRef = React.useRef<HTMLFormElement | null>(null);

  const onSubmitHandler = (data: Record<string, any>) => {
    if (onSubmit) onSubmit(data);
  };

  const onChangeHandler = (data: any) => {
    if (onChange) onChange(data);
  };

  const onClear = () => {
    reset();
  };

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(onSubmitHandler)}
      onChange={() => onChangeHandler(getValues())}
    >
      <Row>
        {fields.map((field) => (
          <Col
            key={field.name}
            lg={field.lg_col ? field.lg_col : 12}
            md={field.md_col ? field.md_col : 12}
            sm={field.sm_col ? field.sm_col : 12}
            className="mt-2"
          >
            <Input
              field={field}
              control={control}
              register={register}
              error={errors[field.name]?.message}
            />
          </Col>
        ))}
      </Row>

      {links && (
        <div className="mt-2">
          {links.map((item) => (
            <p key={item.name} className="link__form">
              <Link to={item.href}>{item.name}</Link>
            </p>
          ))}
        </div>
      )}

      {showButtons ? (
        buttonsEnd ? (
          <div className="mt-3 d-flex justify-content-end ">
            {gobackButton && (
              <Button
                variant="secondary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Voltar
              </Button>
            )}

            {resetButton && (
              <Button variant="danger" onClick={onClear}>
                Limpar
              </Button>
            )}

            <Button variant={variant} type="submit">
              {submitName}
            </Button>
          </div>
        ) : (
          <div className="mt-3 d-flex justify-content-between ">
            {gobackButton && (
              <Button
                variant="secondary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Voltar
              </Button>
            )}

            {resetButton && (
              <Button variant="danger" onClick={onClear}>
                Limpar
              </Button>
            )}

            <Button variant={variant} type="submit">
              {submitName}
            </Button>
          </div>
        )
      ) : null}
    </form>
  );
};

export default FormComponent;
